export interface IShippingRequestResponseForm {
  pricingInformation: string;
  otherConditions: string;
}

export interface IShippingRequestResponses
  extends IShippingRequestResponseForm {
  messageId: string;
  serviceProviderId: string;
}

export interface IShippingRequestResponsesPayload
  extends IShippingRequestResponses {
  createDate: string;
  creditCheckFileId?: string;
}

export enum IShippingRequestResponseUpdateTypes {
  FollowUp = 'FollowUp',
  CreditCheck = 'CreditCheck',
}

export interface IFollowUp {
  contacted: boolean;
  shipped: boolean;
}

export interface ICreditCheck {
  loadingCompany: boolean;
  destinationCompany: boolean;
}
export interface IShippingRequestResponsesUpdatePayload {
  data: IFollowUp | ICreditCheck;
}
