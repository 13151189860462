﻿import { IItem } from '@@types';
import { testMethods } from './test-methods-helper';
// import { addDisposer } from 'mobx-state-tree';
// PP: https://www.iso.org/obp/ui/#iso:std:66828:en
export enum Attributes {
  Density = 'density',
  MfiRange = 'mfi_range',
  MFR = 'mfr',
  TensileModulus = 'tensile_modulus',
  TensileStrength = 'tensile_strength',
  FlexuralModulus = 'flexural_modulus',
  FlexuralStrength = 'flexural_strength',
  ElongationAtBreak = 'elongation_at_break',
  ElongationAtYield = 'elongation_at_yeild',
}

interface IAttribute {
  title: string;
  testMethod?: string;
  testMethodData?: {
    unit?: string;
    condition?: string;
  };
  overrides?: {
    unit?: (unit: string | []) => string;
    condition?: (condition: string | []) => string;
  };
}

interface IAttributes {
  [index: string]: IAttribute;
}

const iso527ModulusConditionOverride = (u) => {
  return u[0];
};

const iso527StrengthAndElongationConditionOverride = (u) => {
  return u.slice(1);
};

export const attributes: IAttributes = {
  [Attributes.Density]: {
    title: 'Density',
    testMethod: 'ISO 1183',
  },
  // [Attributes.MFI]: {
  //     title: 'Melt index (MFI/MFR)',
  //     testMethod: 'ISO 1133',
  //     // overrides: {
  //     //     unit: () => ''
  //     // }
  // },
  [Attributes.MfiRange]: {
    title: 'Melt index (MFI/MFR)',
    testMethod: 'ISO 1133',
    // overrides: {
    //     unit: () => ''
    // }
  },
  [Attributes.MFR]: {
    title: 'Melt Flow Rate',
    testMethod: 'ISO 1133',
  },
  [Attributes.TensileModulus]: {
    title: 'Tensile Modulus',
    testMethod: 'ISO 527',
    overrides: {
      unit: (u) => u[0],
      condition: iso527ModulusConditionOverride,
    },
  },
  [Attributes.TensileStrength]: {
    title: 'Tensile Strength',
    testMethod: 'ISO 527',
    overrides: {
      unit: (u) => u[0],
      condition: iso527StrengthAndElongationConditionOverride,
    },
  },
  [Attributes.FlexuralModulus]: {
    title: 'Flexural Modulus',
    testMethod: 'ISO 178',
  },
  [Attributes.FlexuralStrength]: {
    title: 'Flexural Strength',
    testMethod: 'ISO 178',
  },
  [Attributes.ElongationAtBreak]: {
    title: 'Elongation at break',
    testMethod: 'ISO 527',
    overrides: {
      unit: (u) => u[0],
      condition: iso527StrengthAndElongationConditionOverride,
    },
  },
  [Attributes.ElongationAtYield]: {
    title: 'Elongation at yeild',
    testMethod: 'ISO 527',
    overrides: {
      unit: (u) => u[0],
      condition: iso527StrengthAndElongationConditionOverride,
    },
  },
};

export const getPlasticAttribute = (attribute) => {
  const a = attributes[attribute]
    ? {
        ...attributes[attribute],
        name: attribute,
      }
    : undefined;

  if (!a) {
    return null;
  }

  if (a.testMethod) {
    const method = testMethods[a.testMethod];
    a.testMethodData = { ...method };
  }

  if (a.overrides) {
    if (a.overrides.unit) {
      a.testMethodData.unit = a.overrides.unit(a.testMethodData.unit);
    }
    if (a.overrides.condition) {
      a.testMethodData.condition = a.overrides.condition(
        a.testMethodData.condition
      );
    }
  }

  return a;
};

export const getLightAttributes = () => {
  const lightAttributeKeys = [Attributes.Density, Attributes.MfiRange];
  const lightAttributes = lightAttributeKeys.map((attr) =>
    getPlasticAttribute(attr)
  );
  return lightAttributes;
};

export const getPPAttributes = () => {
  const ppAttributeKeys = [
    'density',
    'mfi',
    'tensile_modulus',
    'tensile_strength',
    'flexural_modulus',
    'flexural_strength',
  ];
  const ppAttributes = ppAttributeKeys.map((attr) => getPlasticAttribute(attr));
  return ppAttributes;
};

export const getAttribByName = (name: Attributes) => {
  const attribute = getPlasticAttribute(name);
  return attribute ? attribute : undefined;
};

export const getAttribTitleByName = (name: string) => {
  const attribute = attributes[name];
  if (attribute) {
    return attribute.title;
  }
  return undefined;
};

export const getMfiValue = (item: IItem, fallbackValue = null) => {
  const mfi = item?.attr?.dataSheetAttributes?.mfi_range;
  if (mfi?.value) {
    const { value, condition } = mfi;
    return (
      `${value} g/10min` +
      (condition?.temperature && condition?.weight
        ? ` @ ${condition.temperature}C /${condition.weight}kg`
        : '')
    );
  }
  return fallbackValue;
};

export const getDensityValue = (item: IItem, fallbackValue = null) => {
  const densityValue = item?.attr?.dataSheetAttributes?.density?.value;
  if (densityValue) {
    return `${densityValue} g/cm3`;
  }
  return fallbackValue;
};
