﻿import { h, FunctionComponent } from 'preact';
import {
  Grid,
  CircularProgress,
  Typography,
  Box,
} from '@@helpers/material-ui/core';

interface IProps {
  title?: string;
}

export const Loading: FunctionComponent<IProps> = ({ title }) => {
  return (
    <Grid container justify="center" direction="row">
      <Grid container item direction="column" alignItems="center">
        <CircularProgress />
        <Box mt={2}>
          <Typography variant="body1" color="textSecondary">
            {title || 'Loading'}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Loading;
