﻿import { ICompanyLight } from './company';
import {
  PaymentOptions,
  ItemPaymentOptionsValues,
} from '@@helpers/item/paymentOptions';
import { ItemColorValues } from '@@helpers/item/colorOptions';
import { PurityValues } from '@@helpers/item/purityOptions';
import { SamplesAvailableValues } from '@@helpers/item/samplesAvailable';
// import { FreeOnPickupValues } from '@@helpers/item/freeOnPickup';
import { ItemFrequencyValues } from '@@helpers/item/frequencyOptions';
import { ImageRequiredValues } from '@@helpers/item/imageRequired';
import { ItemSourceValues } from '@@helpers/item/sourceOptions';
// import { IUser } from './user';
import { CommercialTermsValues } from '@@helpers/item/commercialTerms';
import { ItemPackagingValues } from '@@helpers/item/packagingOptions';
import { ItemPlasticTypeValues } from '@@helpers/item/plasticTypeOptions';
import { ItemFormValues } from '@@helpers/item/formOptions';
import { ItemGradeValues } from '@@helpers/item/gradeOptions';
import { YesNoUknown } from './';
import { Attributes as QcAttributes } from '@@helpers/attributes-helper';
import { PackagingDimensionsValues } from '@@helpers/item';
import { ItemTypes, ItemStatus } from '@@helpers/item';
import { countries } from '@@helpers/country-helper';
import { IMessage } from './message';
import { IDBMaterialQualitySample } from './materialQualitySample';
// tslint:disable:no-empty-interface
interface IOption {
  label: string;
  value: string;
}

export interface IStoredPaymentOption {
  price: number;
  type: PaymentOptions;
}
export type IStoredPaymentOptions = IStoredPaymentOption[];
export interface IPaymentOption {
  type: ItemPaymentOptionsValues;
}

export interface IShippingOption extends IOption {}
export type IShippingOptions = IShippingOption[];

export enum FileVariants {
  DataSheet = 'data_sheet',
  Image = 'image',
  MessageFile = 'message_file',
  CreditCheck = 'credit_check',
}

export enum FileTypes {
  Pdf = 'application/pdf',
  Jpeg = 'image/jpeg',
  Png = 'image/png',
}

export interface IMfiCondition {
  weight?: string;
  temperature?: string;
}

export interface IFile {
  fileId: string;
  name: string;
  size: number;
  type: string;
  variant: FileVariants;
  link_to_photo?: string; // external images
}

export interface IFileUploadServerResponse {
  data: {
    _key: string;
  };
}

export enum ItemAttributes {
  Color = 'color',
  ColorCustom = 'colorCustom',
  Description = 'description',
  Grade = 'grade',
  KgPrice = 'kgPrice',
  MaterialIsPure = 'materialIsPure',
  Packaging = 'packaging',
  PaymentOptions = 'paymentOptions',
  PaymentPreferences = 'paymentPreferences',
  PlasticType = 'plasticType',
  PreviousUsage = 'previousUsage',
  Purity = 'purity',
  QualityControlled = 'qualityControlled',
  SamplesAvailable = 'samplesAvailable',
  ShippingOptions = 'shippingOptions',
  Supply = 'supply',
  Source = 'source',
  Files = 'files',
  CommercialTerms = 'commercialTerms',
  DataSheetAttributes = 'dataSheetAttributes',
  Frequency = 'frequency',
  ImageRequired = 'imageRequired',
  Form = 'form',
  ShippingPackageWeight = 'shippingPackageWeight',
  ShippingPackageDimensions = 'shippingPackageDimensions',
  IntentionalUse = 'intentionalUse',
  TimeToDelivery = 'timeToDelivery',
  HidePrice = 'hidePrice',
  AgreePaymentPreferences = 'agreePaymentPreferences',
  PriceMotivation = 'priceMotivation',
  IsFreeOnPickup = 'isFreeOnPickup',
}

export const OfferRequiredAttributes = [
  ItemAttributes.KgPrice,
  ItemAttributes.CommercialTerms,
  ItemAttributes.TimeToDelivery,
  ItemAttributes.Supply,
  ItemAttributes.Frequency,
];

export const OfferOptionalAttributes = [
  ItemAttributes.Color,
  ItemAttributes.Packaging,
  ItemAttributes.Form,
  ItemAttributes.Source,
  ItemAttributes.Grade,
];

// "density": {
//     "name": "density",
//     "value": "",
//     "testMethod": "ISO 1183",
//     "unit": "g/cm3",
//     "condition": null
//   },
export interface IDataSheetAttribute {
  name: ItemAttributes;
  value: number;
  testMethod: string;
  unit: string;
  condition?: IMfiCondition;
}

interface IDataSheetAttributes {
  [QcAttributes.Density]?: IDataSheetAttribute;
  [QcAttributes.MfiRange]?: IDataSheetAttribute;
}

export interface IOfferAttributes {
  [ItemAttributes.KgPrice]?: number;
  [ItemAttributes.PaymentPreferences]?: string;
  [ItemAttributes.CommercialTerms]?: CommercialTermsValues;
  [ItemAttributes.TimeToDelivery]?: string;
  [ItemAttributes.Supply]?: number;
  [ItemAttributes.Frequency]?: ItemFrequencyValues;
  [ItemAttributes.AgreePaymentPreferences]?: boolean;
}

export interface IPackagingDimensions {
  [PackagingDimensionsValues.Length]?: number;
  [PackagingDimensionsValues.Width]?: number;
  [PackagingDimensionsValues.Height]?: number;
}

export interface IItemAttributes {
  [ItemAttributes.Color]?: ItemColorValues;
  [ItemAttributes.ColorCustom]?: string;
  [ItemAttributes.Description]?: string;
  [ItemAttributes.Grade]?: ItemGradeValues;
  [ItemAttributes.KgPrice]?: number;
  [ItemAttributes.MaterialIsPure]?: YesNoUknown;
  [ItemAttributes.Packaging]?: ItemPackagingValues | ItemPackagingValues[];
  [ItemAttributes.PaymentOptions]?: IPaymentOption[];
  [ItemAttributes.PaymentPreferences]?: string;
  [ItemAttributes.PlasticType]?: ItemPlasticTypeValues;
  [ItemAttributes.PreviousUsage]?: string;
  [ItemAttributes.Purity]?: PurityValues;
  [ItemAttributes.QualityControlled]?: string;
  [ItemAttributes.SamplesAvailable]?: SamplesAvailableValues;
  [ItemAttributes.ShippingOptions]?: IShippingOption[];
  [ItemAttributes.Supply]?: number;
  [ItemAttributes.Source]?: ItemSourceValues;
  [ItemAttributes.Files]?: IFile[];
  [ItemAttributes.CommercialTerms]?: CommercialTermsValues;
  [ItemAttributes.DataSheetAttributes]?: IDataSheetAttributes;
  [ItemAttributes.Frequency]?: ItemFrequencyValues;
  [ItemAttributes.ImageRequired]?: ImageRequiredValues;
  [ItemAttributes.Form]?: ItemFormValues;
  [ItemAttributes.ShippingPackageWeight]?: string;
  [ItemAttributes.ShippingPackageDimensions]?: IPackagingDimensions;
  [ItemAttributes.IntentionalUse]?: string;
  [ItemAttributes.TimeToDelivery]?: string;
  [ItemAttributes.HidePrice]?: 'yes';
  [ItemAttributes.PriceMotivation]?: string;
  [ItemAttributes.IsFreeOnPickup]?: boolean;
}

export interface IPriceEstimateInput {
  [ItemAttributes.PlasticType]?: ItemPlasticTypeValues;
  [ItemAttributes.Source]?: ItemSourceValues;
  [ItemAttributes.Grade]?: ItemGradeValues;
  [ItemAttributes.Form]?: ItemFormValues;
}

export interface IItemCurrentStatus {
  date: string;
  status: ItemStatus;
}

export interface IItemStatusData extends IItemCurrentStatus {
  userId: string;
  remoteAddress: string;
}

export interface ILocation {
  city: string;
  country: string;
}

export interface IItem {
  title: string;
  attr: IItemAttributes;
  showPrice?: boolean;
  type: ItemTypes;
  isFreeOnPickup?: boolean;
  isPrivate?: boolean;
  hasExtraVisibility?: boolean;
  coupon?: string;
  companyId?: string;
  userId?: string;
  originalItemId?: string;
}

export interface IUserItem extends IItem {
  userId: string;
}

export interface IItemPostData extends IItem {}

export interface IDBItem extends IItem {
  _key: string;
  slug?: string;
  createDate?: string;
  type: ItemTypes;
  user?: any;
  userId: string;
  companyId: string;
  location: ILocation;
  deleted: boolean;
  company: ICompanyLight;
  external?: boolean;
  status: ItemStatus;
  statusData?: IItemStatusData[];
  currentStatus?: IItemCurrentStatus;
  sampleRequest?: boolean;
  companyGotCustomOffer?: IMessage;
  materialQualityDataSheet?: any;
  materialQualitySampleId?: Array<string>;
  materialQualitySampleData?: Array<IDBMaterialQualitySample>;
}

export interface IInterestForm {
  frequency: ItemFrequencyValues;
  quantity: string;
  preferedPrice: string;
  preferedCommercialTerms: CommercialTermsValues;
  isPaymentTermsAccepted: boolean;
  preferedPaymentTerms: string;
}

export interface IGetItemsFilter {
  type: string;
  companyId?: string;
  userId?: string;
  source?: string;
  plasticType?: string;
  orderBy?: string;
  orderDirection?: string;
  isDeleted?: boolean;
  status?: ItemStatus;
}

export interface IGetItemsFilterMap {
  userId?: string;
  type: string;
  source?: string;
  plasticType?: string;
  orderBy?: string;
  orderDirection?: string;
  isDeleted?: boolean;
  isExternal?: boolean;
  grade?: string;
  form?: string;
  color?: string;
  country?: typeof countries;
}
