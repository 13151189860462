﻿import { ItemAttributes } from '@@types';
import { IModel } from './index';
import { Attributes as DataSheetAttributes } from '@@helpers/attributes-helper';
import { mfiRangeValidator } from '@@helpers/validator-helper';
import { object, string, number, ValidationError } from 'yup';

interface ISellValidationError {
  msg: string;
  prop: string;
}

export const editItemValidationProps = object().shape({
  title: string()
    .max(100, 'Title must be at most 100 characters')
    .required('Title is required'),
  attr: object().shape({
    // [ItemAttributes.Color]: string()
    //     .required('Color is required'),
    // [ItemAttributes.Packaging]: string()
    //     .required('Packaging is required'),
    // [ItemAttributes.Description]: string()
    //     .max(10000, 'Description can not be more than 10 000 chars'),
    // [ItemAttributes.KgPrice]: number()
    //     .typeError('Preferred price must be a number')
    //     .min(0)
    //     .positive('Price must be a positive number'),
    [ItemAttributes.KgPrice]: string().required('Price is required'),
    [ItemAttributes.Supply]: number()
      .typeError('Preferred quantity must be a number')
      .integer('Preferred quantity must be a number')
      .required('Preferred quantity is required')
      .min(0)
      .positive('Preferred quantity must be a positive number'),
    [ItemAttributes.Frequency]: string().required('Frequency is required'),
  }),
});

export const addItemValidationProps = object().shape({
  attr: object().shape({
    [ItemAttributes.PlasticType]: string().required('Plastic type is required'),
    // [ItemAttributes.Color]: string()
    //     .required('Color is required'),
    [ItemAttributes.Form]: string().required('Form is required'),
    // [ItemAttributes.Packaging]: string()
    //     .required('Packaging is required'),
    // [ItemAttributes.Description]: string()
    //     .max(10000, 'Description can not be more than 10 000 chars'),
    // [ItemAttributes.KgPrice]: number()
    //     .typeError('Preferred price must be a number')
    //     .min(0)
    //     .positive('Price must be a positive number'),
    [ItemAttributes.Source]: string().required('Source is required'),
    [ItemAttributes.Grade]: string().required('Grade is required'),
    [ItemAttributes.DataSheetAttributes]: object().shape({
      [DataSheetAttributes.Density]: object().shape({
        value: number()
          .typeError('Density must be a number')
          .min(0, 'Density must be greater than or equal to 0'),
      }),
      [DataSheetAttributes.MfiRange]: mfiRangeValidator,
    }),
  }),
});

// export const itemValidationSchema = object().shape({
//     title: string()
//         .max(100, 'Title must be at most 100 characters')
//         .required('Title is required'),
//     attr: object().shape({
//         [ItemAttributes.PlasticType]: string()
//             .required('Plastic type is required'),
//         // [ItemAttributes.Color]: string()
//         //     .required('Color is required'),
//         [ItemAttributes.Form]: string()
//             .required('Form is required'),
//         // [ItemAttributes.Packaging]: string()
//         //     .required('Packaging is required'),
//         // [ItemAttributes.Description]: string()
//         //     .max(10000, 'Description can not be more than 10 000 chars'),
//         // [ItemAttributes.KgPrice]: number()
//         //     .typeError('Preferred price must be a number')
//         //     .min(0)
//         //     .positive('Price must be a positive number'),
//         [ItemAttributes.KgPrice]: string()
//             .required('Price is required'),
//         [ItemAttributes.Supply]: number()
//             .typeError('Preferred quantity must be a number')
//             .integer('Preferred quantity must be a number')
//             .required('Preferred quantity is required')
//             .min(0)
//             .positive('Preferred quantity must be a positive number'),
//         [ItemAttributes.Frequency]: string()
//             .required('Frequency is required'),
//         [ItemAttributes.Source]: string()
//             .required('Source is required'),
//         [ItemAttributes.Grade]: string()
//             .required('Grade is required'),
//         [ItemAttributes.DataSheetAttributes]: object().shape({
//             [DataSheetAttributes.Density]: object().shape({
//                 value: number()
//                     .typeError('Density must be a number')
//                     .min(0, 'Density must be greater than or equal to 0')
//             }),
//             [DataSheetAttributes.MfiRange]: mfiRangeValidator
//         })
//     })
// });

export const editItemValidationSchema = editItemValidationProps;
export const itemValidationSchema = editItemValidationProps.concat(
  addItemValidationProps
);

export const validateBuy = (item, isEdit = false) => {
  let errors: ValidationError;

  const schema = isEdit ? editItemValidationSchema : itemValidationSchema;
  try {
    schema.validateSync(item, { abortEarly: false });
  } catch (err) {
    errors = err;
  }

  return errors
    ? errors.inner.map((e) => ({
        msg: e.message,
        prop: e.path,
      }))
    : [];
};

export const validate = (sell: IModel): ISellValidationError[] => {
  console.log('itemValidationSchema', itemValidationSchema);
  let errors: ValidationError;
  try {
    itemValidationSchema.validateSync(sell.newItem, { abortEarly: false });
  } catch (err) {
    errors = err;
  }

  return errors
    ? errors.inner.map((e) => ({
        msg: e.message,
        prop: e.path,
      }))
    : [];
};
