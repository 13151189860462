﻿import { getOptionsLabelFromValue, IReadonlyMap } from './index';
import { mapValueWithLabel } from './mappers';

export enum ItemPackagingValues {
  Any = 'any',
  Bags = 'bags',
  Bales = 'bales',
  BigBag = 'big_bag',
  Container = 'container',
  Octabin = 'octabin',
  PalletCollar = 'pallet_collar',
  Other = 'other',
}

const itemPackagingLabels: IReadonlyMap<ItemPackagingValues, string> = new Map([
  [ItemPackagingValues.Any, 'Any'],
  [ItemPackagingValues.Bags, 'Bags'],
  [ItemPackagingValues.Bales, 'Bales'],
  [ItemPackagingValues.BigBag, 'Big Bag'],
  [ItemPackagingValues.Container, 'Container'],
  [ItemPackagingValues.Octabin, 'Octabin'],
  [ItemPackagingValues.PalletCollar, 'Pallet Collar'],
  [ItemPackagingValues.Other, 'Other/unknown'],
]);

export const packagingOptions = mapValueWithLabel(
  ItemPackagingValues,
  itemPackagingLabels
);

export const getPackagingOptions = (newItem) => {
  return packagingOptions.map((item) => {
    return {
      ...item,
      checked: newItem.packaging === item.value,
    };
  });
};

export const getPackagingOptionsLabel = (
  value: ItemPackagingValues | ItemPackagingValues[]
) => {
  if (Array.isArray(value)) {
    return value
      .map((v) => {
        return getOptionsLabelFromValue(packagingOptions, v);
      })
      .join(', ');
  }
  return getOptionsLabelFromValue(packagingOptions, value);
};
