import { ICompany } from './company';
import { IFile } from './item';
import { ItemPlasticTypeValues } from './../helpers/item';

interface IAcceptTermsDate {
  createDate: string;
  remoteAddress: string;
}

export interface IUser {
  _key: string;
  id: string;
  email?: string;
  companies?: ICompany[];
  name?: string;
  // New props for selling tool
  phone?: string;
  company?: ICompany; // item route use this for some reason
  acceptTermsDates?: IAcceptTermsDate[];
}

export interface IDBUser {
  company: string;
  id: string;
  name: string;
  role: string;
}

export interface ILinkLoginData {
  userId: number;
  linkId: number;
  token: string;
}

export interface IOTPData {
  email: string;
  OTP?: string;
}

export interface IInventoryRowData {
  id: string;
  title: string;
  slug: string;
  plasticType: string;
  form: string;
  price: number;
  supply: number;
  createDate: string;
  typeOfOffer: string;
  link?: string;
  files: IFile[];
  materialQualitySampleId?: string;
}

export interface IInventoryPageParams {
  plasticType?: ItemPlasticTypeValues;
  order?: 'asc' | 'desc';
  orderBy?: keyof IInventoryRowData;
  page?: string;
  rowsPerPage?: string;
}

// { id: 'shippingId', numeric: false, disablePadding: true, label: 'Title' },
// { id: 'departure', numeric: false, disablePadding: false, label: 'Material type' },
// { id: 'destination', numeric: false, disablePadding: false, label: 'Form' },
// { id: 'quantity', numeric: true, disablePadding: false, label: 'Price' },
// { id: 'orderDate', numeric:false, disablePadding:false,label: ''},
// { id: 'preferredDeliveryDate', numeric:false, disablePadding:false,label: ''},

export interface IShippingTerminalRowData {
  shippingId: string;
  departure: string;
  destination: string;
  quantity: string;
  orderDate: string;
  preferredDeliveryDate: string;
  shippingRequestResponseUrl: string;
  printUrl: string;
}

export interface ISignUpData {
  company: string;
  zipcode: string;
  city: string;
  country: string;
  vatno: string;
  name: string;
  email: string;
  password: string;
  password_verify: string;
  helpWith?: string[];
  interestedMaterials?: string[];
  capabilities?: string[];
  preferredForm?: string[];
  verified?: boolean;
  phone?: string;
}

export enum UserRoleTypes {
  Admin = 'admin',
  Sales = 'sales',
}
