﻿import { ItemAttributes, IOfferAttributes, IValidationError } from '@@types';
// import { Attributes as DataSheetAttributes } from '@@helpers/attributes-helper';
import { object, string, boolean, ValidationError } from 'yup';

export const offerValidationSchema = object().shape({
  [ItemAttributes.KgPrice]: string()
    .max(100, 'Price must be at most 100 characters')
    .required('Price is required'),
  [ItemAttributes.CommercialTerms]: string().required(
    'Commercial terms are required'
  ),
  [ItemAttributes.TimeToDelivery]: string().required(
    'Preferred time to delivery is required'
  ),
  [ItemAttributes.Supply]: string().required('Preferred quantity is required'),
  [ItemAttributes.Frequency]: string().required('Frequency is required'),
  [ItemAttributes.AgreePaymentPreferences]: boolean(),
  [ItemAttributes.PaymentPreferences]: string().when(
    ItemAttributes.AgreePaymentPreferences,
    {
      is: (val) => !val,
      then: (schema) => schema.required('Fill in your preferred payment terms'),
    }
  ),

  // [ItemAttributes.PaymentPreferences]: string()
  //     .when(ItemAttributes.AgreePaymentPreferences, {
  //         is: (val: boolean) => !val,
  //         then: yup.string().required('Fill in your preferred payment terms')
  //     }),

  // [ItemAttributes.PaymentPreferences]: boolean()
  //     .when(ItemAttributes.AgreePaymentPreferences, {
  //         is: (val: boolean) => {
  //             return !val;
  //         },
  //         then: schema => {
  //             debugger;
  //             return schema.required('Fill in your preferred payment terms');
  //         }
  //     }),
  // [ItemAttributes.AgreePaymentPreferences]: boolean()
  //     .when(ItemAttributes.PaymentPreferences, {
  //         is: (val: string) => {
  //             return val && val.length > 0;
  //         },
  //         otherwise: schema => {
  //             return schema.oneOf([true], 'Must Accept Terms and Conditions');
  //         }
  //     })

  //   .test('has-image', 'Image is required', (value) => {
  //     debugger;
  //     // const image = getFileVariant(files, FileVariants.Image);
  //     return !!image;
  //   }),

  //   [ItemAttributes.PaymentPreferences]: array()
  //   .of(itemImageSchema)
  //   .test('has-image', 'Image is required', (files: IFile[]) => {
  //     const image = getFileVariant(files, FileVariants.Image);
  //     return !!image;
  //   }),
});

export const validate = (offer: IOfferAttributes): IValidationError[] => {
  let errors: ValidationError;
  try {
    offerValidationSchema.validateSync(offer, { abortEarly: false });
  } catch (err) {
    errors = err;
  }

  return errors
    ? errors.inner.map((e) => ({
        msg: e.message,
        prop: e.path,
      }))
    : [];
};
