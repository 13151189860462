﻿import { Store, BoundAction } from 'unistore';
import { IStore } from 'shared/unistore';
import { IModel, defaultState } from './';
// import { getItem, deleteItem } from 'api/item';
import { getMatch } from 'api/match';
import { createOffer } from 'api/offer';
import { validate } from './views';
// import { IDBItem } from '@@types';

export interface IActions {
  reset: BoundAction;
  fetchMatch: BoundAction;
  updateOffer: BoundAction;
  confirmOffer: BoundAction;
}

export const reset = () => {
  return {
    match: defaultState,
  };
};

const actions = (store: Store<IStore>) => {
  const validateOffer = (offer) => {
    return validate(offer);
  };

  return {
    reset,
    // getMessage: async ({ messages }: { messages: IModel }, activityId: string) => {
    confirmOffer: async ({ match }: IStore, evt) => {
      evt.preventDefault();
      const errors = validateOffer(match.offer);
      console.log('errorz', errors);
      if (errors.length > 0) {
        return {
          match: {
            ...match,
            errors,
          },
        };
      }

      try {
        const result = await createOffer(
          match.matchData.match._key,
          match.offer
        );
        console.log('result', result);
      } catch (error) {
        console.log('error', error);
        throw new Error('Error creating offer');
      }
    },
    updateOffer: ({ match }, name: string, value: string) => {
      return {
        match: {
          ...match,
          offer: {
            ...match.offer,
            [name]: value,
          },
        },
      };
    },
    fetchMatch: async ({ match }: { match: IModel }, matchId: string) => {
      if (!matchId) {
        return;
      }
      //
      store.setState({
        match: {
          ...match,
          fetching: true,
        },
      });
      console.log('store.getState()', store.getState());
      let result: any;
      let matchData;
      try {
        result = await getMatch(matchId);
        matchData =
          result && result.data && result.data.matchData
            ? result.data.matchData
            : null;
        store.setState({
          match: {
            ...store.getState().match,
            matchData,
            fetching: false,
          },
        });
      } catch (error) {
        console.log('error fetching match', matchId, error);
      }

      store.setState({
        match: {
          ...store.getState().match,
          fetching: false,
        },
      });
      console.log('store.getState()', store.getState());
    },
  };
};

export default actions;
