﻿import {
  IItem,
  ItemAttributes,
  IValidationError,
  IPriceRange,
  IMaterialOfferServiceFee,
  IDBItem,
  materialQualitySample,
  IAddress,
} from '@@types';
import {
  CommercialTermsValues,
  ItemTypes,
  SamplesAvailableValues,
} from '@@helpers/item';
export { default as actions } from './actions';

// createdItemIds: types.optional(types.array(types.number), []),
// creating: types.optional(types.boolean, false),
// uploading: types.optional(types.boolean, false),
// errors: types.optional(types.array(ValidationError), []),
// // newItem: types.optional(PlasticsItem, PlasticsItem.create()),
// newItem: types.optional(PlasticsItem, PlasticsItem.create().toJSON()),
// statusMessage: types.maybeNull(types.string),
// step: types.optional(types.enumeration('State', [steps.FORM, steps.CONFIRM, steps.DONE]), steps.FORM),

export enum Steps {
  Confirm = 'confirm',
  Done = 'done',
  Form = 'form',
  MaterialSummary = 'materialSummary',
}

// tslint:disable-next-line:no-empty-interface
export interface IUser {
  id: string;
  name: string;
  role: string;
  company: string;
}
export interface IModel {
  createdItem?: IDBItem;
  createdItemIds?: string[];
  creating: boolean;
  uploading: boolean;
  fetching: boolean;
  errors: IValidationError[];
  newItem?: IItem;
  statusMessage?: string;
  step: Steps;
  users?: IUser[];
  userId?: string;
  defaultPaymentPreferences?: string;
  priceEstimate?: IPriceRange;
  fetchingPriceEstimate: boolean;
  isDetailed: boolean;
  serviceFee?: IMaterialOfferServiceFee;
  materialQualitySampleInfo?: materialQualitySample;
  materialQualitySubmit?: boolean;
  trackingDetails?: any;
  billingAddress?: IAddress;
}

export const defaultItem: IItem = {
  title: '',
  type: ItemTypes.Offer,
  attr: {
    [ItemAttributes.CommercialTerms]: CommercialTermsValues.EXW,
    [ItemAttributes.SamplesAvailable]: SamplesAvailableValues.Yes,
  },
  isPrivate: false,
  hasExtraVisibility: false,
};

// export const defaultItem: IItem = {
//     title: 'Johannes',
//     type: ItemTypes.Offer,
//     attr: {
//         [ItemAttributes.CommercialTerms]: CommercialTermsValues.EXW,
//         [ItemAttributes.PlasticType]: 'pp',
//         [ItemAttributes.Form]: 'regrind',
//         [ItemAttributes.Color]: 'mixed',
//         [ItemAttributes.Grade]: 'blow_molding',
//         [ItemAttributes.KgPrice]: '1',
//         [ItemAttributes.Supply]: '2000',
//         [ItemAttributes.Frequency]: 'spot',
//         [ItemAttributes.Packaging]: 'bags',
//         [ItemAttributes.ShippingPackageWeight]: '1000',
//     },
//     hasExtraVisibility: false
// };

// const dummyServiceFee: IMaterialOfferServiceFee = {
//     type: 'fixed',
//     data: {
//         sum: 12.49,
//         sumNoVat: 9.99,
//         vat: 2.5,
//         vatPercent: 25
//     }
// } as IMaterialOfferServiceFee;

export const defaultState: IModel = {
  creating: false,
  uploading: false,
  fetching: false,
  errors: [],
  step: Steps.Form,
  newItem: defaultItem,
  priceEstimate: null,
  fetchingPriceEstimate: false,
  isDetailed: false,
  serviceFee: null,
  materialQualitySampleInfo: null,
  materialQualitySubmit: false,
  trackingDetails: null,
  billingAddress: null,
};

export const state = () => {
  return {
    ...defaultState,
  };
};

export default state;
