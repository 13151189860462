﻿import { IMaterialQualityPackages } from '@@types';

export enum ServiceFeeTypes {
  Free = 'free',
  FreeOnPickup = 'free_on_pickup',
  Fixed = 'fixed',
  Partner = 'partner',
}

export interface IMaterialCertificate {
  testType:
    | IMaterialQualityPackages.PHYSICAL
    | IMaterialQualityPackages.MECHANICAL
    | IMaterialQualityPackages.MATERIAL
    | IMaterialQualityPackages.APPEARANCE;
  cost: number;
}
export interface IServiceFeeDataFixed {
  vatPercent: number;
  vat: number;
  sum: number;
  sumNoVat: number;
  materialRegistration: number;
  extraVisibility: number;
  materialCertificate?: Array<IMaterialCertificate>;
  couponData?: {
    couponId: string;
    discount: number;
  };
}

export interface IServiceFeeDataPartner extends IServiceFeeDataFixed {
  kgPrice: number;
  yearMaxPrice: number;
  kgLeft: number;
  kgCharge: number;
  yearEnds: string;
}

export interface IMaterialOfferServiceFee {
  type: ServiceFeeTypes;
  data: IServiceFeeDataFixed | IServiceFeeDataPartner;
}
