﻿import { getOptionsLabelFromValue, IReadonlyMap } from './index';
import { mapValueWithLabel } from './mappers';

export enum ItemFrequencyValues {
  Spot = 'spot',
  Ongoing = 'ongoing',
  // Monthly = 'monthly',
  // Quarterly = 'quarterly',
  // Yearly = 'yearly'
}

const itemFrequencyLabels: IReadonlyMap<ItemFrequencyValues, string> = new Map([
  [ItemFrequencyValues.Spot, 'Spot'],
  [ItemFrequencyValues.Ongoing, 'Ongoing'],
  // [ItemFrequencyValues.Monthly, 'Monthly'],
  // [ItemFrequencyValues.Quarterly, 'Quarterly'],
  // [ItemFrequencyValues.Yearly, 'Yearly']
]);

export const itemFrequencySuffix: IReadonlyMap<ItemFrequencyValues, string> =
  new Map([
    [ItemFrequencyValues.Spot, 'spot'],
    [ItemFrequencyValues.Ongoing, 'ongoing'],
    // [ItemFrequencyValues.Monthly, 'per month'],
    // [ItemFrequencyValues.Quarterly, 'quarterly'],
    // [ItemFrequencyValues.Yearly, 'yearly']
  ]);

export const frequencyOptions = mapValueWithLabel(
  ItemFrequencyValues,
  itemFrequencyLabels
);

const frequencyLabelsOptions = mapValueWithLabel(
  ItemFrequencyValues,
  itemFrequencySuffix
);

export const frequencyOptionsValueArray = frequencyOptions.map(
  (item: any) => item.value
);

export const getFrequencyOptions = (newItem) => {
  return frequencyOptions.map((item) => {
    return {
      ...item,
      checked: newItem.frequency === item.value,
    };
  });
};

export const getFrequencyOptionsLabel = (value: ItemFrequencyValues) =>
  getOptionsLabelFromValue(frequencyOptions, value);

export const getFrequencyOptionsSuffix = (value: ItemFrequencyValues) =>
  getOptionsLabelFromValue(frequencyLabelsOptions, value);

export const allowedFrequencyOptions = (
  sellersPreferedFrequency: ItemFrequencyValues
) => {
  const rankFrequency = (frequencyOption: ItemFrequencyValues) => {
    let ranking;

    switch (frequencyOption) {
      // case ItemFrequencyValues.Yearly:
      //   ranking = 4;
      //   break;

      // case ItemFrequencyValues.Quarterly:
      //   ranking = 3;
      //   break;

      // case ItemFrequencyValues.Monthly:
      //   ranking = 2;
      //   break;

      case ItemFrequencyValues.Ongoing:
        ranking = 2;
        break;

      case ItemFrequencyValues.Spot:
        ranking = 1;
        break;
      default:
        break;
    }
    return ranking;
  };

  const sellersFrequencyRanking = rankFrequency(sellersPreferedFrequency);

  return frequencyOptionsValueArray.filter(
    (option) => rankFrequency(option) <= sellersFrequencyRanking
  );
};
