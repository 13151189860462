﻿export const isArrayValuesNotEmpty = (arr: any[]) => {
  if (Array.isArray(arr)) {
    const filteredArrLength = arr.filter((val) => val !== '').length;
    return arr.length === filteredArrLength;
  }
  throw new Error('isArrayValuesNotEmpty: argument needs to be an array');
};

export const hasArrayNullOrEmpty = (values: any[]) => {
  return values.filter((value) => !value).length > 0;
};

// Takes an array of items and creates groups of the same size
// Example:
//
// input
// arr: [1,2,4,5,6,7,8,9,10]
// elementsPerGroup: 4
//
// output:
// [
//   [
//     1,
//     2,
//     3,
//     4
//   ],
//   [
//     5,
//     6,
//     7,
//     8
//   ],
//   [
//     9,
//     10
//   ],
// ]
export const groupArrayElements = (arr: any[], elementsPerGroup = 4) => {
  if (!arr) {
    return [];
  }
  const firstVal = (val: any) => {
    return [val];
  };
  const pushVal = (existing: any[], val) => {
    return existing.concat(val);
  };

  return arr.reduce((prev, curr) => {
    const lengthy = prev.length;
    if (lengthy === 0) {
      const returnVal = [firstVal(curr)];
      return returnVal;
    }

    const lastGroup = prev[lengthy - 1];
    const countLastGroup = lastGroup.length;

    if (countLastGroup < elementsPerGroup) {
      const prevGroup = prev.slice(0, -1);
      const newGroup = pushVal(lastGroup, curr);
      const returnVal = prevGroup.concat([newGroup]);
      return returnVal;
    }

    const returnVal = prev.concat([firstVal(curr)]);
    return returnVal;
  }, []);
};
