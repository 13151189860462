import { h, FunctionComponent, Fragment } from 'preact';
import { useState } from 'preact/hooks';
import RouterLink, { Link } from 'components/RouterLink';
import Login from './Login';
import LoginLink from './LoginLink';
import { Modal, ModalBody, ModalHeader } from 'components/modal/Modal';
import IFrameModal from 'components/modal/IFrameModal';
import { textLink } from 'styles/links';
import { isTouchDevice } from '@@helpers/client-helper';
import { IMergedAuthProps } from '../unistore/Auth/withAuth';
import {
  Box,
  Button,
  ListItemIcon,
  Typography,
  MenuList,
  MenuItem,
  Divider,
} from '@@helpers/material-ui/core';
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from '@@helpers/material-ui/core/styles';
import { getCompanyFromCredentials } from '@@helpers/user-helper';
import { getCompanyUrl } from '@@helpers/url-helper';
import Listmat from 'components/icons/user-interface/Listmat';
import Heartheart from 'components/icons/user-interface/Heartheart';

import {
  ExitToAppOutlined,
  PersonOutlined,
  ReceiptOutlined,
  StorefrontOutlined,
  DashboardOutlined,
  ExtensionOutlined,
  ForumOutlined,
} from '@@helpers/material-ui/icons';
import Hamburger from './Hamburger';
import { ServiceProvidersType } from '@@types/serviceProvider';

const MenuButton = withStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
      fontWeight: 800,
    },
    '&:before': {
      display: 'block',
      content: 'attr(data-title)',
      fontWeight: 800,
      height: 0,
      overflow: 'hidden',
      visibility: 'hidden',
      position: 'absolute',
    },
    textTransform: 'none',
    fontSize: '20px',
    paddingLeft: theme.spacing(1) / 2,
    paddingRight: theme.spacing(1) / 2,
    marginRight: theme.spacing(1) / 2,
    minWidth: '1px',
    fontFamily: 'Manrope',
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      paddingLeft: theme.spacing(1) / 2,
      paddingRight: theme.spacing(1) / 2,
      marginRight: theme.spacing(2),
    },
  },
  label: {
    color: theme.palette.grey[800],
    '&:before': {
      display: 'block',
      content: 'attr(data-title)',
      fontWeight: 800,
      height: 0,
      overflow: 'hidden',
      visibility: 'hidden',
      position: 'absolute',
    },
  },
}))(Button);

const MenuButtonDesktop = withStyles((theme: Theme) => ({
  root: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
}))(MenuButton);

const aboutUsLink = (
  <MenuButtonDesktop
    disableRipple
    component={RouterLink}
    href="https://www.atomler.com/about"
    color="default"
    title="About us"
    data-title="About us"
  >
    About us
  </MenuButtonDesktop>
);

const newsletterLink = (onClick) => (
  <MenuButtonDesktop
    onClick={onClick}
    disableRipple
    component={RouterLink}
    href="https://www-atomler-com.filesusr.com/html/dd5fb8_9b75edaa3605c50657038ef0b6848c70.html"
    color="default"
    title="Newsletter"
    data-title="Newsletter"
    target="_blank"
  >
    Newsletter
  </MenuButtonDesktop>
);

const freeDemoLink = (
  <MenuButtonDesktop
    disableRipple
    component={RouterLink}
    href="https://calendly.com/atomler-productdemo/30min"
    color="default"
    title="Free demo"
    data-title="Free demo"
    target="_blank"
  >
    Free demo
  </MenuButtonDesktop>
);

const findLink = (
  <MenuButtonDesktop
    disableRipple
    component={RouterLink}
    href="/find"
    color="default"
    title="Find material"
    data-title="Find"
  >
    Find
  </MenuButtonDesktop>
);

const sellLink = (
  <MenuButtonDesktop
    disableRipple
    component={RouterLink}
    href="/sell"
    color="default"
    title="Sell material"
    data-title="Sell"
  >
    Sell
  </MenuButtonDesktop>
);

const isTouch = isTouchDevice();

const MenuCTAButton = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 25,
    border: '2px',
    borderColor: theme.palette.black.main,
    borderStyle: 'solid',
    textTransform: 'none',
    fontSize: '20px',
    fontWeight: 600,
    fontFamily:
      'Manrope, "Roboto", "Franklin Gothic Medium", Tahoma, sans-serif',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.black.light,
      borderColor: theme.palette.black.light,
    },
  },
  label: {
    textTransform: 'none',
  },
}))(Button);

const MenuCTAButtonAuthenticated = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 25,
    border: '2px',
    borderColor: theme.palette.black.main,
    borderStyle: 'solid',
    textTransform: 'none',
    fontSize: '.9rem',
    fontFamily:
      'Manrope, "Roboto", "Franklin Gothic Medium", Tahoma, sans-serif',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.black.light,
      borderColor: theme.palette.black.light,
    },
  },
  label: {
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '4rem',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '16rem',
    },
  },
}))(Button);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      padding: theme.spacing(1),
    },
    listIcon: {
      minWidth: 0,
      paddingRight: theme.spacing(1),
    },
    hamburger: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  })
);

interface IProps {
  auth: IMergedAuthProps;
  serviceProviderLogin?: boolean;
}

const Menu: FunctionComponent<IProps> = ({ auth, serviceProviderLogin }) => {
  const myCompany = getCompanyFromCredentials(auth.credentials);
  const [showNewsletterForm, setShowNewsletterForm] = useState(false);
  const showNewsletter = (evt: Event) => {
    evt.preventDefault();
    setShowNewsletterForm(true);
  };

  const hideNewsletter = () => {
    setShowNewsletterForm(false);
  };

  const classes = useStyles();
  const userButton = () => {
    if (isTouch) {
      return (
        <MenuCTAButtonAuthenticated disableElevation>
          {auth.credentials.name}
        </MenuCTAButtonAuthenticated>
      );
    }
    return (
      <MenuCTAButtonAuthenticated
        disableElevation
        component={RouterLink}
        href="/"
      >
        {auth.credentials.name}
      </MenuCTAButtonAuthenticated>
    );
  };
  const showLogin = auth && auth.showLogin ? auth.showLogin : null;
  const isLoginShown = auth && auth.isLoginShown ? auth.isLoginShown : null;
  return (
    <div class="flex-grow-0 flex items-center justify-end order-2">
      {!auth || !auth.credentials
        ? serviceProviderLogin
          ? [
              <MenuCTAButton disableElevation onClick={showLogin}>
                Sign in
              </MenuCTAButton>,
            ]
          : [
              aboutUsLink,
              freeDemoLink,
              newsletterLink(showNewsletter),
              findLink,
              sellLink,
              <MenuCTAButton disableElevation onClick={showLogin}>
                Sign in
              </MenuCTAButton>,
            ]
        : auth.credentials.type === ServiceProvidersType.Shipping ||
          auth.credentials.type === ServiceProvidersType.Lab
        ? [
            <MenuCTAButton disableElevation onClick={auth.logout}>
              Sign Out
            </MenuCTAButton>,
          ]
        : [
            aboutUsLink,
            freeDemoLink,
            newsletterLink(showNewsletter),
            findLink,
            sellLink,
            <div class="button__user-wrapper relative">
              {userButton()}
              <div class="button__user-menu absolute bg-white shadow-1 br2">
                <MenuList dense={true} component="nav">
                  <MenuItem component="a" href="/" className={classes.listItem}>
                    <ListItemIcon className={classes.listIcon}>
                      <DashboardOutlined fontSize="small" />
                    </ListItemIcon>
                    My dashboard
                  </MenuItem>
                  <Divider light />
                  <MenuItem
                    component="a"
                    href="/messages"
                    className={classes.listItem}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <ForumOutlined fontSize="small" />
                    </ListItemIcon>
                    My messages
                  </MenuItem>
                  <Divider light />
                  <MenuItem
                    component="a"
                    href="/matches"
                    className={classes.listItem}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <ExtensionOutlined fontSize="small" />
                    </ListItemIcon>
                    My matches
                  </MenuItem>
                  <Divider light />
                  <MenuItem
                    component="a"
                    href="/active-sales"
                    className={classes.listItem}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <Heartheart fontSize="small" />
                    </ListItemIcon>
                    My potential buyers
                  </MenuItem>
                  <Divider light={true} />
                  <MenuItem
                    component="a"
                    href="/profile"
                    className={classes.listItem}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <PersonOutlined fontSize="small" />
                    </ListItemIcon>
                    My profile
                  </MenuItem>
                  <Divider light />
                  {myCompany?.id && (
                    <Fragment>
                      <MenuItem
                        component="a"
                        href={getCompanyUrl(myCompany.id, myCompany.slug)}
                        className={classes.listItem}
                      >
                        <ListItemIcon className={classes.listIcon}>
                          <StorefrontOutlined fontSize="small" />
                        </ListItemIcon>
                        My company store
                      </MenuItem>
                      <Divider light />
                    </Fragment>
                  )}
                  <MenuItem
                    component="a"
                    href="/inventory/offers"
                    className={classes.listItem}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <Listmat fontSize="small" />
                    </ListItemIcon>
                    Material management
                  </MenuItem>
                  <Divider light={true} />
                  <Divider />
                  <MenuItem
                    component="a"
                    href="/invoices"
                    className={classes.listItem}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <ReceiptOutlined fontSize="small" />
                    </ListItemIcon>
                    My invoices
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    className={classes.listItem}
                    onClick={auth.logout}
                    fullWidth
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <ExitToAppOutlined fontSize="small" />
                    </ListItemIcon>
                    Sign out
                  </MenuItem>
                </MenuList>
              </div>
            </div>,
          ]}
      {showNewsletterForm && (
        <IFrameModal
          width="100%"
          height="450"
          src="https://www-atomler-com.filesusr.com/html/dd5fb8_9b75edaa3605c50657038ef0b6848c70.html"
          handleCloseModal={hideNewsletter}
        />
      )}
      {isLoginShown ? (
        <Modal narrow={true} onClose={auth.hideLogin}>
          <ModalHeader>Sign in</ModalHeader>
          <ModalBody>
            <Box mb={2}>
              <Login auth={auth} serviceProviderLogin={serviceProviderLogin} />
            </Box>
            {serviceProviderLogin ? null : (
              <Fragment>
                <Divider variant="middle" />
                <Box mb={2} mt={2}>
                  <LoginLink auth={auth} />
                </Box>
                <Box>
                  <Typography align="center">
                    <span class="f5 mr2">No account?</span>
                    <Link
                      class={`${textLink} f5`}
                      onClick={() => {
                        auth.hideLogin();
                      }}
                      href="/sign-up"
                    >
                      Sign up
                    </Link>
                  </Typography>
                </Box>
              </Fragment>
            )}
          </ModalBody>
        </Modal>
      ) : null}
      <Box className={classes.hamburger}>
        <Hamburger handleShowNewsletter={showNewsletter} auth={auth} />
      </Box>
    </div>
  );
};

export default Menu;
