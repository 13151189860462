import { h, Fragment, FunctionComponent } from 'preact';
import { Snackbar } from '@@helpers/material-ui/core';
import { Alert as MuiAlert, AlertProps } from '@@helpers/material-ui/lab';
import { connect as unistoreConnect } from 'unistore/preact';
import { actions, IModel as INotification } from '../../unistore/Notification';

interface IProps {
  notifications: INotification;
  open: () => void;
  close: () => void;
}

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export const SimpleSnackbar: FunctionComponent<IProps> = ({
  close,
  notifications,
}) => {
  const handleClose = (_event: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    close();
  };

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notifications.open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Fragment>
          <Alert onClose={handleClose} severity={notifications.severity}>
            {notifications.message}
          </Alert>
        </Fragment>
      </Snackbar>
    </Fragment>
  );
};

export default unistoreConnect('notifications', actions)(SimpleSnackbar);
