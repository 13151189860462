﻿import { h } from 'preact';
import { Link } from 'preact-router';
import { forwardRef } from 'preact/compat';

const RouterLink = forwardRef((props: any, ref: any) => (
  <Link {...props} ref={ref} />
));

export { Link };
export default RouterLink;
