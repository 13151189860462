﻿import {
  IConfirmAddress,
  ISignUpData,
  IAddress,
  IBillingAddress,
  IValidatorResult,
  IInterestForm,
  IExternalNegotiationFormData,
  ItemAttributes,
  IItemAttributes,
} from '@@types';
import {
  object,
  string,
  number,
  ValidationError,
  addMethod,
  ref,
  boolean,
} from 'yup';
import { ItemFrequencyValues } from './item/frequencyOptions';
import { isNumberOrFalsy } from './number-helper';
import { isArrayValuesNotEmpty } from './array-helper';
import { isShippingPackageWeightSelectedAndNotOther } from './packaging-helper';
import { PackagingDimensionsValues } from './item';

const equalTo = function (ref, msg) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    message: msg || '${path} must be the same as ${reference}',
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref);
    },
  });
};

addMethod(string, 'equalTo', equalTo);

export const validator = (schema, data): IValidatorResult => {
  const validationResult: IValidatorResult = {
    isValid: true,
    errors: [],
  };

  try {
    schema.validateSync(data, { abortEarly: false, stripUnknown: true });
  } catch (error) {
    validationResult.isValid = false;
    validationResult.errors = error.inner;
  }
  return validationResult;
};

export const getError = (path: string, errors: ValidationError[]) => {
  return errors.find((i) => i.path === path);
};

const addressSchema = object().shape({
  recipient: string().trim().required('required field'),
  street: string().trim().required('required field'),
  address2: string().trim(),
  postalCode: string().trim().required('required field'),
  city: string().trim().required('required field'),
  state: string().trim(),
  country: string().trim().required('required field').length(2),
});

export const validateAddress = (address: IAddress) => {
  return validator(addressSchema, address);
};

export const confirmAddressSchema = addressSchema.concat(
  object().shape({
    contactPerson: string().trim().required('required field'),
    phoneNumber: string().trim().required('required field'),
    deliveryDate: string().trim().required('required field'),
    email: string()
      .trim()
      .email('not a valid email address')
      .required('required valid email address'),
  })
);

export const validateConfirmAddress = (address: IConfirmAddress) => {
  return validator(confirmAddressSchema, address);
};

const billingAddressSchema = addressSchema.concat(
  object().shape({
    email: string()
      .trim()
      .email('not a valid email address')
      .required('required valid email address'),
  })
);

export const validateBillingAddress = (address: IBillingAddress) => {
  return validator(billingAddressSchema, address);
};

const makeOrderSchema = object().shape({
  quantity: number()
    .typeError('quantity must be a number')
    .integer('quantity must be an integer')
    .min(1)
    .required('quantity is required'),
  frequency: string().trim().required(),
});

interface IMakeOrderData {
  quantity: string;
  frequency: ItemFrequencyValues;
}

export const validateMakeOrder = (orderData: IMakeOrderData) => {
  return validator(makeOrderSchema, orderData);
};

const numberSchema = (fieldName: string) => {
  return object().shape({
    sampleSize: number()
      .typeError(`${fieldName} must be a number`)
      .required(`${fieldName} is required`)
      .min(1)
      .positive(`${fieldName} must be a positive number`)
      .integer(`${fieldName} must be an integer`),
  });
};

export const validateNumber = (value, fieldName: string) => {
  return validator(numberSchema(fieldName), value);
};

const requiredStringHelper = (fieldName: string) =>
  string().trim().required(`${fieldName} is required`);

const makeTenderSchema = object<IInterestForm>().shape({
  frequency: requiredStringHelper('Frequency'),
  quantity: number().required().min(1),
  preferedPrice: number()
    .typeError('Price must be a number')
    .required('Price is required')
    .min(0),
  preferedCommercialTerms: requiredStringHelper('Commercial terms'),
  isPaymentTermsAccepted: boolean(),
  preferedPaymentTerms: string().when('isPaymentTermsAccepted', {
    is: false,
    then: string().required(
      "You need to specify your payment terms when not accepting the seller's terms"
    ),
  }),
});

export const validateMakeTender = (interestForm: IInterestForm) => {
  return validator(makeTenderSchema, interestForm);
};

const minOrEmpty = (minLength: number) => {
  return (value: string) => {
    if (value || value === '') {
      if (value === '' || value.length >= minLength) {
        return true;
      }
      return false;
    }
    return true;
  };
};

const signUpSchema = object().shape({
  company: string().required('Enter name of company'),
  zipcode: string().required('Enter zip code'),
  city: string().required('Enter company city'),
  country: string().required('Enter company country'),
  vatno: string().required('Enter company VAT number'),
  name: string().required('Enter name of contact person'),
  email: string()
    .email('Email is not valid')
    .required('Enter your email address'),
  password: string()
    .min(6, 'Your password must be at least 6 characters')
    .max(200, 'Your password must not be more than 200 characters')
    .required('You must enter a password'),
  // @ts-ignore
  password_verify: string().equalTo(
    ref('password'),
    "The two passwords don't match"
  ),
  phone: string()
    .test('minOrEmpty', 'Please enter a valid phone number', minOrEmpty(8))
    .max(20, 'Please enter a valid number'),
});

export const validateSignUp = (data: ISignUpData): IValidatorResult => {
  return validator(signUpSchema, data);
};

const newPasswordSchema = object().shape({
  new_password: string()
    .min(6, 'Your password must be at least 6 characters')
    .max(200, 'Your password must not be more than 200 characters')
    .required('You must enter a password'),
  // @ts-ignore
  password_verify: string().equalTo(
    ref('new_password'),
    "The two passwords don't match"
  ),
});

interface INewPasswordData {
  new_password: string;
  password_verify: string;
}

export const validateNewPassword = (data: INewPasswordData) => {
  return validator(newPasswordSchema, data);
};

const profileSchema = object().shape({
  name: string().required('Enter your name'),
  email: string()
    .email('Email is not valid')
    .required('Enter your email address'),
  new_password: string()
    .min(6, 'Your password must be at least 6 characters')
    .max(200, 'Your password must not be more than 200 characters')
    .required('You must enter a password'),
  // @ts-ignore
  password_verify: string().equalTo(
    ref('new_password'),
    "The two passwords don't match"
  ),
  password: string().required('Enter current password'),
});

interface IProfileData {
  name: string;
  email: string;
  new_password: string;
  password_verify: string;
  password: string;
}

export const validateProfile = (data: IProfileData) => {
  return validator(profileSchema, data);
};

const digitsOnly = (value) => /^\d*\.?\d*$/.test(value);
const messageMaxLength = 2000;
const externalNegotiationSchema = object().shape({
  kgPrice: string()
    .test(
      'Digits only',
      'Price is required and should have digits only',
      digitsOnly
    )
    .required('Price is required'),
  quantity: string()
    .test(
      'Digits only',
      'Quantity is required and should have digits only',
      digitsOnly
    )
    .required('Quantity is required'),
  commercialTerms: string().nullable().required('Commercial terms is required'),
  frequency: string().required('Frequency is required'),
  message: string().max(
    messageMaxLength,
    `The details can not be more than ${messageMaxLength} chars`
  ),
});

export const validateExternalNegotiation = (
  data: IExternalNegotiationFormData
) => {
  return validator(externalNegotiationSchema, data);
};

export const mfiRangeValidator = object().shape({
  value: string()
    .test('numberCheck', 'Please use numbers for min/max MFI', (value) => {
      if (value) {
        const values = value.split('-');
        return isNumberOrFalsy(values[0]) && isNumberOrFalsy(values[1]);
      }
      return true;
    })
    .test(
      'negativeCheck',
      'Max. MFI must be greater than min. MFI',
      (value) => {
        if (value) {
          const values = value.split('-');
          if (values.length > 1 && isArrayValuesNotEmpty(values)) {
            const valuesAsNumbers = values.map(Number);
            if (valuesAsNumbers[0] > valuesAsNumbers[1]) {
              return false;
            }
          }
        }
        return true;
      }
    ),
  condition: object().shape({
    temperature: string()
      .typeError('MFI temperature must be a number')
      .test(
        'numberCheck',
        'Please use a number for MFI temperature',
        isNumberOrFalsy
      ),
    weight: string()
      .typeError('MFI weight must be a number')
      .test(
        'numberCheck',
        'Please use a number for MFI weight',
        isNumberOrFalsy
      ),
  }),
});

const newCustomOfferSchema = object().shape({
  attr: object().shape({
    [ItemAttributes.Packaging]: string().required('Packaging is required'),
    [ItemAttributes.KgPrice]: number()
      .required('Price is required')
      .typeError('Price must be a number')
      .min(0)
      .positive('Price must be a positive number'),
    [ItemAttributes.Supply]: number()
      .typeError('Available supply must be a number')
      .integer('Available supply must be a number')
      .required('Available supply is required')
      .min(0, 'Available supply must be greater than or equal to 0'),
    [ItemAttributes.Frequency]: string().required('Capacity is required'),
    [ItemAttributes.ShippingPackageWeight]: number().when(
      ItemAttributes.Packaging,
      {
        is: isShippingPackageWeightSelectedAndNotOther,
        then: number()
          .required('Shipping weight is required')
          .typeError('Shipping weight must be a number'),
      }
    ),
    [ItemAttributes.PaymentPreferences]: string().required(
      'Payment preferences are required'
    ),
    [ItemAttributes.ShippingPackageDimensions]: object().shape({
      [PackagingDimensionsValues.Height]: number()
        .typeError('Package height must be a number')
        .integer('Package height must be a number')
        .min(0, 'Package height must be greater than or equal to 0'),
      [PackagingDimensionsValues.Width]: number()
        .typeError('Package width must be a number')
        .integer('Package width must be a number')
        // .required('Package width is required')
        .min(0, 'Package width must be greater than or equal to 0'),
      [PackagingDimensionsValues.Length]: number()
        .typeError('Package length must be a number')
        .integer('Package length must be a number')
        .min(0, 'Package length must be greater than or equal to 0'),
    }),
  }),
});

export const validateNewCustomOfferData = (data: IItemAttributes) => {
  return validator(newCustomOfferSchema, data);
};

export const partnerDataSchema = object().shape({
  kgPrice: number()
    .required('Price is required')
    .typeError('Price must be a number')
    .min(0)
    .positive('Price must be a positive number'),
  yearMaxPrice: number()
    .required('Year max price is required')
    .typeError('Year max price must be a number')
    .min(0)
    .positive('Year max price must be a positive number'),
  // startDate: string()
  //   .typeError('Start date must be a string'),
});

export const validatePartnerData = (data: any) => {
  return validator(partnerDataSchema, data);
};

// [DataSheetAttributes.MfiRange]: object().shape({
//   value: string()
//       .test('numberCheck', 'Please use numbers for MFI min/max', (value) => {
//           if (value) {
//               const values = value.split('-');
//               return isNumberOrFalsy(values[0]) && isNumberOrFalsy(values[1]);
//           }
//           return true;
//       })
//       .test('negativeCheck', 'Max MFI. must be greater than min. MFI', (value) => {
//           if (value) {
//               const values = value.split('-');
//               if (values.length > 1 && values[0] > values[1]) {
//                   return false;
//               }
//           }
//           return true;
//       }),
//   condition: object().shape({
//       temperature: string()
//           .typeError('MFI temperature must be a number')
//           .test('numberCheck', 'Please use a number for MFI temperature', isNumberOrFalsy),
//       weight: string()
//           .typeError('MFI weight must be a number')
//           .test('numberCheck', 'Please use a number for MFI weight', isNumberOrFalsy),
//   })
// })
