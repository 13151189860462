export { default as Send } from '@material-ui/icons/Send';
export { default as Check } from '@material-ui/icons/Check';
export { default as AddToPhotosOutlined } from '@material-ui/icons/AddToPhotosOutlined';
export { default as Delete } from '@material-ui/icons/DeleteRounded';
// export { default as DeleteForever } from '@material-ui/icons/DeleteForever';
export { default as RestoreFromTrash } from '@material-ui/icons/RestoreFromTrash';
export { default as FilterList } from '@material-ui/icons/FilterList';
export { default as ArrowDropDown } from '@material-ui/icons/ArrowDropDown';
export { default as Edit } from '@material-ui/icons/EditRounded';
export { default as Save } from '@material-ui/icons/Save';
export { default as AttachMoney } from '@material-ui/icons/AttachMoney';
export { default as Attachment } from '@material-ui/icons/Attachment';
export { default as CompassCalibration } from '@material-ui/icons/CompassCalibration';
export { default as Explore } from '@material-ui/icons/Explore';
export { default as DoneIcon } from '@material-ui/icons/Done';
export { default as AddIcon } from '@material-ui/icons/Add';
// export { default as Store } from '@material-ui/icons/Store';
export { default as StorefrontOutlined } from '@material-ui/icons/StorefrontOutlined';
export { default as ContactSupportOutlined } from '@material-ui/icons/ContactSupportOutlined';
export { default as AddAPhotoOutlined } from '@material-ui/icons/AddAPhotoOutlined';
export { default as ColorizeOutlined } from '@material-ui/icons/ColorizeOutlined';
export { default as Close } from '@material-ui/icons/Close';
export { default as CloseRounded } from '@material-ui/icons/CloseRounded';
export { default as Photo } from '@material-ui/icons/Photo';
export { default as ExitToAppOutlined } from '@material-ui/icons/ExitToAppOutlined';
// export { default as LocalMallOutlined } from '@material-ui/icons/LocalMallOutlined';
export { default as LocalOfferOutlined } from '@material-ui/icons/LocalOfferOutlined';
export { default as ShoppingBasketOutlined } from '@material-ui/icons/ShoppingBasketOutlined';
export { default as PersonOutlined } from '@material-ui/icons/PersonOutlined';
export { default as ArrowBackIos } from '@material-ui/icons/ArrowBackIos';
// export { default as InfoIcon } from '@material-ui/icons/Info';
// export { default as ErrorIcon } from '@material-ui/icons/Error';
export { default as DownloadIcon } from '@material-ui/icons/GetApp';
export { default as ReceiptOutlined } from '@material-ui/icons/ReceiptOutlined';
export { default as HelpOutlineOutlined } from '@material-ui/icons/HelpOutlineOutlined';
export { default as AssignmentOutlined } from '@material-ui/icons/AssignmentOutlined';
export { default as Room } from '@material-ui/icons/Room';
export { default as ArrowUpwardOutlined } from '@material-ui/icons/ArrowUpwardOutlined';
export { default as ArrowDownwardOutlined } from '@material-ui/icons/ArrowDownwardOutlined';
export { default as ArrowForwardOutlined } from '@material-ui/icons/ArrowForwardOutlined';
export { default as AssignmentReturnedRounded } from '@material-ui/icons/AssignmentReturnedRounded';
export { default as MenuRounded } from '@material-ui/icons/MenuRounded';
export { default as ArrowForwardRounded } from '@material-ui/icons/ArrowForwardRounded';
export { default as ArrowBackRounded } from '@material-ui/icons/ArrowBackRounded';
export { default as PauseCircleOutlineRounded } from '@material-ui/icons/PauseCircleOutlineRounded';
export { default as CheckCircleOutline } from '@material-ui/icons/CheckCircleOutlineRounded';
export { default as DashboardOutlined } from '@material-ui/icons/DashboardOutlined';
export { default as ExtensionOutlined } from '@material-ui/icons/ExtensionOutlined';
// export { default as ListAltOutlinedIcon } from '@material-ui/icons/ListAltOutlined';
export { default as ForumOutlined } from '@material-ui/icons/ForumOutlined';
export { default as Share } from '@material-ui/icons/ShareRounded';
export { default as ExpandMore } from '@material-ui/icons/ExpandMoreRounded';
export { default as ChevronLeft } from '@material-ui/icons/ChevronLeft';
export { default as Clear } from '@material-ui/icons/Clear';
export { default as Search } from '@material-ui/icons/SearchRounded';
// export { default as Warning } from '@material-ui/icons/Warning';
// @material-ui/icons
export { default as Refresh } from '@material-ui/icons/RefreshRounded';
export { default as Print } from '@material-ui/icons/PrintRounded';
export { default as HelpRounded } from '@material-ui/icons/HelpRounded';
