﻿import { IMergedAuthProps } from 'shared/unistore/Auth/withAuth';
import { h, FunctionComponent } from 'preact';
import { useState } from 'preact/hooks';
import { Box } from '@@helpers/material-ui/core';
import { TextField } from 'components/ui/TextField';
import { SecondaryButton } from 'components/ui/Button';
import { ParagraphSmall } from 'components/ui/typography/Fonts';

interface IProps {
  auth: IMergedAuthProps;
}

const LoginLink: FunctionComponent<IProps> = ({ auth }) => {
  const [emailAddress, setEmailAddress] = useState('');

  const handleSubmit = (evt: Event) => {
    evt.preventDefault();
    auth.sendLinkLogin(emailAddress);
  };

  const handleInput = (evt: any) => {
    // TODO:react-type
    const { value } = evt.target;
    setEmailAddress(value);
    auth.validateLoginLinkEmail(value);
  };

  const isButtonEnabled = !auth.fetching && auth.isLoginLinkEmailValid;

  if (auth.isLoginLinkSent) {
    return (
      <div class="mt3 mb3" style="color:#71B881;">
        Please check your email. It shouldn't take longer than a minute or two.
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} class="flex flex-column">
      <Box mb={1}>
        <ParagraphSmall>
          Forgot your password? It's ok! Get a link instead.
        </ParagraphSmall>
      </Box>
      <Box mb={1}>
        <TextField
          size="small"
          variant="outlined"
          id="forgotten-password-username"
          label="Email"
          type="email"
          value={emailAddress}
          fullWidth
          onInput={handleInput}
          autoCapitalize="off"
          autoComplete="off"
        />
      </Box>
      <SecondaryButton type="submit" disabled={!isButtonEnabled}>
        Send link
      </SecondaryButton>
    </form>
  );
};

export default LoginLink;
