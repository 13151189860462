﻿// Types
import { IStore } from 'shared/unistore';
import { IModel } from './index';

import { Store, BoundAction } from 'unistore';
import { getItems } from 'api/item';
import { updateUserPhoneNumber } from 'api/user';

export interface IActions {
  fetchItems: BoundAction;
  deleteItems: BoundAction;
  updatePhoneNumber: BoundAction;
}

interface IFilterOptions {
  plasticType?: string;
  source?: string;
  type?: string;
  orderBy?: string;
  orderDirection?: 'asc' | 'desc';
}

const actions = (store: Store<IStore>) => ({
  fetchItems: async (
    { sellingTool }: { sellingTool: IModel },
    filterOptions: IFilterOptions
  ) => {
    store.setState({
      sellingTool: {
        ...sellingTool,
        fetching: true,
      },
    });
    console.log('filterOptions', filterOptions);
    try {
      const result = await getItems(filterOptions);
      const { data } = result;
      store.setState({
        sellingTool: {
          ...store.getState().sellingTool,
          items: data.items,
        },
      });

      console.log('result', result);
    } catch (error) {
      console.log('error fetching items', error);
    }
    store.setState({
      sellingTool: {
        ...store.getState().sellingTool,
        fetching: false,
      },
    });
  },
  deleteItems: async (
    { sellingTool }: { sellingTool: IModel },
    itemIds: string[]
  ) => {
    store.setState({
      sellingTool: {
        ...sellingTool,
        deleting: true,
      },
    });

    try {
      const result = await deleteItems(itemIds);
      console.log('result', result);
    } catch (error) {
      console.log('error fetching items', error);
    }

    store.setState({
      sellingTool: {
        ...sellingTool,
        deleting: false,
      },
    });
    console.log('itemIds', itemIds);
  },
  updatePhoneNumber: async (
    { sellingTool }: { sellingTool: IModel },
    userId: string,
    phoneNumber: string
  ) => {
    store.setState({
      sellingTool: {
        ...sellingTool,
        updatingPhoneNumber: true,
      },
    });

    try {
      const result = await updateUserPhoneNumber(userId, phoneNumber);
      console.log('result', result);
    } catch (error) {
      console.log('error updating phone number', error);
    }

    store.setState({
      sellingTool: {
        ...store.getState().sellingTool,
        updatingPhoneNumber: false,
      },
    });
  },
});

export default actions;
