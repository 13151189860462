﻿import { IReference, IUser, ILocation, ICompany } from '@@types';
import { getCountryName } from './country-helper';

export const getCompanyFromUser = (user: IUser) => {
  // TODO typing
  return user?.companies && user.companies.length > 0
    ? user.companies[0]
    : null;
};

export const getCompanyFromCredentials = (credentials: any): ICompany => {
  // TODO typing
  return credentials?.companies && credentials.companies.length > 0
    ? credentials.companies[0]
    : null;
};

export const getReferenceFromUser = (user: IUser): IReference => {
  const companyData = getCompanyFromUser(user);
  const ref: IReference = {
    name: user.name,
    email: user.email,
    vatno: companyData?.vatno,
  };
  return ref;
};

export const getLocationFromUser = (user: IUser): ILocation => {
  const companyData = getCompanyFromUser(user);
  const location: ILocation = {
    city: companyData?.city,
    country: companyData?.country,
  };
  return location;
};

export const getLocationString = (location: ILocation): string => {
  return `${location.city}, ${getCountryName(location.country)}`;
};

export const getLocationStringFromUser = (user: IUser): string => {
  const location = getLocationFromUser(user);
  return getLocationString(location);
};

export const testUsers = [
  '617',
  '619',
  '110915131',
  '110915125',
  '79144938',
  '17209329',
  '45639670',
  '110915109',
  '110915119',
  '110915120',
  '110915121',
];
