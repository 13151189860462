import { h, FunctionComponent } from 'preact';
import { container } from 'styles/layout';
import { Header, Footer, Main } from 'components/LayoutBase';
import {
  IMergedAuthProps,
  withAuth,
} from '../../shared/unistore/Auth/withAuth';
import { Box } from '@@helpers/material-ui/core';
import Loading from 'components/ui/Loading';

interface IProps {
  auth: IMergedAuthProps;
}

export const PageLoading: FunctionComponent<IProps> = ({ auth }) => {
  return (
    <div class={container}>
      <Header auth={auth} />
      <Main maxWidth="lg">
        <Box display="flex" flexGrow={1} style={{ position: 'relative' }}>
          <Box pt={2} flexGrow={1}>
            <Loading />
          </Box>
        </Box>
      </Main>
      <Footer />
    </div>
  );
};

export default withAuth(PageLoading);
