﻿import { Store } from 'unistore';
import { IStore } from 'shared/unistore';
import { getActivities, getActivity } from 'api/activity';
import { IModel, defaultState } from './index';

export const reset = () => {
  return {
    messages: defaultState,
  };
};

const actions = (store: Store<IStore>) => ({
  reset,
  getMessages: async ({ messages }: { messages: IModel }) => {
    store.setState({
      messages: {
        ...messages,
        isFetching: true,
      },
    });

    let res: any;
    try {
      res = await getActivities();
    } catch (error) {
      console.log(error);
    }
    console.log(res);

    store.setState({
      messages: {
        ...messages,
        isFetching: false,
        messages: res && res.data ? res.data.activities : [],
      },
    });
  },
  getMessage: async (
    { messages }: { messages: IModel },
    activityId: string
  ) => {
    store.setState({
      messages: {
        ...messages,
        isFetching: true,
      },
    });

    let res: any;
    try {
      res = await getActivity(activityId);
    } catch (error) {
      console.log(error);
    }
    console.log(res);

    store.setState({
      messages: {
        ...messages,
        isFetching: false,
        messages: res && res.data ? [res.data.activity] : [],
      },
    });
  },
});

export default actions;
