﻿import { Fragment, h } from 'preact';
import { memo } from 'preact/compat';
import { Box, Grid, Container } from '@@helpers/material-ui/core';
import { makeStyles } from '@@helpers/material-ui/core/styles';
import BrandingSymbol from 'components/ui/BrandingSymbol';
import SimpleSnackbar from 'components/ui/SnackBar';
import { FooterLinks, ParagraphMedium } from 'components/ui/typography/Fonts';
import RouterLink from 'components/RouterLink';
import Compliance from 'components/Compliance/Compliance';

interface IProps {
  isServer?: boolean;
  url?: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.sea.main,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  brandingSymbol: {
    display: 'inline-block',
    maxWidth: '80px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '120px',
    },
  },
  linkList: {
    padding: 0,
    listStyle: 'none',
  },
  linkListItem: {
    paddingBottom: theme.spacing(2),
  },
  copyright: {
    color: theme.palette.white.main,
  },
  linkListLink: {
    textDecoration: 'none',
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'none',
      fontWeight: 500,
    },
  },
}));

const Footer = ({ isServer, url }: IProps) => {
  const classes = useStyles();
  return (
    <Fragment>
      <Compliance url={url} />
      <Box className={classes.root} component="footer">
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Box component="span" className={classes.brandingSymbol}>
                <a
                  class="no-underline"
                  href="https://atomler.com"
                  title="Atomler"
                >
                  <BrandingSymbol componentId="footer-symbol" variant="pearl" />
                </a>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <ul className={classes.linkList}>
                <li className={classes.linkListItem}>
                  <RouterLink
                    href="mailto:hello@atomler.com"
                    className={classes.linkListLink}
                  >
                    <FooterLinks>Contact us</FooterLinks>
                  </RouterLink>
                </li>
                <li className={classes.linkListItem}>
                  <RouterLink
                    href="/terms-and-conditions"
                    className={classes.linkListLink}
                  >
                    <FooterLinks>Terms &amp; conditions</FooterLinks>
                  </RouterLink>
                </li>
                <li className={classes.linkListItem}>
                  <RouterLink
                    href="/privacy-policy"
                    className={classes.linkListLink}
                  >
                    <FooterLinks>Privacy policy</FooterLinks>
                  </RouterLink>
                </li>
                <li className={classes.linkListItem}>
                  <a
                    href="https://www.atomler.com/faq"
                    className={classes.linkListLink}
                  >
                    <FooterLinks>Customer service</FooterLinks>
                  </a>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12}>
              <ParagraphMedium className={classes.copyright}>
                &copy; 2022 Atomler AB
              </ParagraphMedium>
            </Grid>
          </Grid>
          {/* <span> - <a class={textLink} href="mailto:hello@atomler.com">Contact</a></span> */}
          {!isServer && <SimpleSnackbar />}
        </Container>
      </Box>
    </Fragment>
  );
};

export default memo(Footer, () => true);
