import {
  CREATE_MATERIAL_QUALITY_SAMPLE_URL,
  GET_MATERIAL_QUALITY_SAMPLE_LIST_URL,
  GET_MATERIAL_QUALITY_SAMPLE_URL,
  UPDATE_MATERIAL_QUALITY_SAMPLE_URL,
} from '@@constants/url';
import { fetcher } from '@@helpers/fetch-helper';
import { IMaterialQualitySampleCreatePayload } from '@@types/materialQualitySample';

export const createMaterialQualitySample = async (
  data: IMaterialQualitySampleCreatePayload
) => {
  return fetcher.post(CREATE_MATERIAL_QUALITY_SAMPLE_URL(), data, {
    withCredentials: true,
  });
};

export const updateMaterialQualitySample = async (
  data: IMaterialQualitySampleCreatePayload
) => {
  return fetcher.put(UPDATE_MATERIAL_QUALITY_SAMPLE_URL(data._key), data, {
    withCredentials: true,
  });
};

export const completeMaterialQualitySample = async (
  data: IMaterialQualitySampleCreatePayload
) => {
  return fetcher.put(CREATE_MATERIAL_QUALITY_SAMPLE_URL(), data, {
    withCredentials: true,
  });
};

export const getMaterialQualitySampleDataList = async (
  serviceProviderId: string,
  finished: boolean
) => {
  return fetcher.get(
    GET_MATERIAL_QUALITY_SAMPLE_LIST_URL(serviceProviderId, finished),
    { withCredentials: true }
  );
};

export const getMaterialQualitySampleData = async (
  trackingId: string,
  state: string
) => {
  return fetcher.get(GET_MATERIAL_QUALITY_SAMPLE_URL(trackingId, state), {
    withCredentials: true,
  });
};
