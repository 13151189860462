﻿import { h, FunctionComponent } from 'preact';
import { Container } from '@@helpers/material-ui/core';
import { Theme, makeStyles } from '@@helpers/material-ui/core/styles';

type MaxWidth = 'xs' | 'sm' | 'smmd' | 'md' | 'lg';

interface IProps {
  isSplash?: boolean;
  style?: any;
  maxWidth?: MaxWidth;
}

const useStyles = (isSplash: boolean, maxWidth?: MaxWidth) =>
  makeStyles((theme: Theme) => {
    return {
      root: {
        width: '100%',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: isSplash ? 'center' : 'unset',
        position: isSplash ? 'relative' : 'static',
        maxWidth:
          !isSplash && maxWidth ? theme.breakpoints.values[maxWidth] : 'none',
      },
    };
  });

const Main: FunctionComponent<IProps> = ({
  isSplash,
  style,
  children,
  maxWidth = 'lg',
}) => {
  const classes = useStyles(isSplash, maxWidth)();
  const classNames = classes.root + (isSplash ? ' ' : '');
  return (
    <Container
      maxWidth={maxWidth === 'smmd' || isSplash ? false : maxWidth}
      component="main"
      style={style}
      children={children}
      className={classNames}
    />
  );
};

export default Main;
