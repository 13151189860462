import { IValidationError } from '@@types';
import {
  ItemColorValues,
  ItemGradeValues,
  ItemPlasticTypeValues,
} from '@@helpers/item';
export { default as actions } from './actions';

export enum Steps {
  Form = 'form',
  Graph = 'graph',
}

// tslint:disable-next-line:no-empty-interface
export interface IUser {
  id: string;
  name: string;
  role: string;
  company: string;
}

export interface IItem {
  plasticType?: ItemPlasticTypeValues;
  grade?: ItemGradeValues;
  color?: ItemColorValues;
  month?: number;
}

export interface IModel {
  fetching: boolean;
  errors: IValidationError[];
  statusMessage?: string;
  newItem?: IItem;
  grades?: string[];
  colors?: string[];
  step: Steps;
  users?: IUser[];
  userId?: string;
}

export const defaultState: IModel = {
  fetching: false,
  newItem: {
    month: 12,
  },
  errors: [],
  step: Steps.Form,
};

export const state = (): IModel => {
  return {
    ...defaultState,
  };
};

export default state;
