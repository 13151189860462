import { getOptionsLabelFromValue, IReadonlyMap } from './index';
import { mapValueWithLabel } from './mappers';
export enum ItemColorValues {
  Any = 'any',
  Amber = 'amber',
  Black = 'black',
  Blue = 'blue',
  Brown = 'brown',
  Green = 'green',
  Grey = 'grey',
  Mixed = 'mixed',
  Natural = 'natural',
  Red = 'red',
  Transparent = 'transparent',
  White = 'white',
  Yellow = 'yellow',
  Other = 'other',
}

const itemColorLabels: IReadonlyMap<ItemColorValues, string> = new Map([
  [ItemColorValues.Any, 'Any'],
  [ItemColorValues.Amber, 'Amber'],
  [ItemColorValues.Black, 'Black'],
  [ItemColorValues.Blue, 'Blue'],
  [ItemColorValues.Brown, 'Brown'],
  [ItemColorValues.Green, 'Green'],
  [ItemColorValues.Grey, 'Grey'],
  [ItemColorValues.Mixed, 'Mixed'],
  [ItemColorValues.Natural, 'Natural'],
  [ItemColorValues.Red, 'Red'],
  [ItemColorValues.Transparent, 'Transparent'],
  [ItemColorValues.White, 'White'],
  [ItemColorValues.Yellow, 'Yellow'],
  [ItemColorValues.Other, 'Other/unknown'],
]);

export const colorOptions = mapValueWithLabel(ItemColorValues, itemColorLabels);

export const colorOptionsValueArray = colorOptions.map((color) => color.value);

export const customColorOptions = (colorArray) => {
  const customColorOption = mapValueWithLabel(colorArray, itemColorLabels);
  return customColorOption;
};

export const getColorOptions = (newItem) => {
  return colorOptions.map((item) => {
    return {
      ...item,
      checked: newItem.color === item.value,
    };
  });
};

export const getColorOptionsPriceIndex = (newItem, colorArray) => {
  const ColorOptionsPriceIndex = mapValueWithLabel(colorArray, itemColorLabels);
  return ColorOptionsPriceIndex.map((item) => {
    return {
      ...item,
      checked: newItem.color === item.value,
    };
  });
};

export const getColorOptionsLabel = (value: string) =>
  getOptionsLabelFromValue(colorOptions, value);
