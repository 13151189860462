﻿import { getOptionsLabelFromValue, IReadonlyMap } from './index';
import { mapValueWithLabel } from './mappers';

export enum ItemSourceValues {
  Any = 'any',
  PostConsumer = 'post_consumer',
  PostIndustrial = 'post_industrial',
  Other = 'other',
}

const itemSourceLabels: IReadonlyMap<ItemSourceValues, string> = new Map([
  [ItemSourceValues.Any, 'Any'],
  [ItemSourceValues.PostConsumer, 'Post-consumer'],
  [ItemSourceValues.PostIndustrial, 'Post-industrial'],
  [ItemSourceValues.Other, 'Other/unknown'],
]);

export const sourceOptions = mapValueWithLabel(
  ItemSourceValues,
  itemSourceLabels
);

export const getSourceOptions = (newItem: any) => {
  return sourceOptions.map((item) => {
    return {
      ...item,
      checked: newItem.source === item.value,
    };
  });
};

export const getSourceOptionsLabel = (value: string) =>
  getOptionsLabelFromValue(sourceOptions, value);
