﻿import { getOptionsLabelFromValue, IReadonlyMap, IStringDict } from './index';
import { mapValueWithLabel } from './mappers';

// https://en.wikipedia.org/wiki/Molding_(process)
export enum ItemGradeValues {
  Any = 'any',
  BlowMolding = 'blow_molding',
  CompressionMolding = 'compression_molding',
  ExtrusionMolding = 'extrusion_molding',
  InjectionMolding = 'injection_molding',
  RotationalMolding = 'rotational_molding',
  Thermoforming = 'thermoforming', // Vacuum forming, a simplified version of thermoforming
  Other = 'other',
}

const itemGradeLabels: IReadonlyMap<ItemGradeValues, string> = new Map([
  [ItemGradeValues.Any, 'Any'],
  [ItemGradeValues.BlowMolding, 'Blow molding'],
  [ItemGradeValues.CompressionMolding, 'Compression molding'],
  [ItemGradeValues.ExtrusionMolding, 'Extrusion molding'],
  [ItemGradeValues.InjectionMolding, 'Injection molding'],
  [ItemGradeValues.RotationalMolding, 'Rotational molding'],
  [ItemGradeValues.Thermoforming, 'Thermoforming/vacuum forming'],
  [ItemGradeValues.Other, 'Other/unknown'],
]);

export const gradeOptions = mapValueWithLabel(ItemGradeValues, itemGradeLabels);

export const customGradeOptions = (gradeArray: IStringDict) => {
  const customGradeOption = mapValueWithLabel(
    gradeArray,
    itemGradeLabels
  ).filter((grade) => grade && grade.label);
  return customGradeOption;
};

export const getGradeOptions = (newItem: any) => {
  return gradeOptions.map((item) => {
    return {
      ...item,
      checked: newItem.source === item.value,
    };
  });
};

export const getGradeOptionsPriceIndex = (newItem, gradeArray) => {
  const GradeOptionsPriceIndex = mapValueWithLabel(gradeArray, itemGradeLabels);
  return GradeOptionsPriceIndex.map((item) => {
    return {
      ...item,
      checked: newItem.source === item.value,
    };
  });
};

export const getGradeOptionsLabel = (value: string) =>
  getOptionsLabelFromValue(gradeOptions, value);
