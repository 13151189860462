export interface IServiceProviderPayload {
  email: string;
  password: string;
}
export interface IServiceProvider extends IServiceProviderPayload {
  type: ServiceProvidersType;
  serviceProviderId: string;
}
export interface IDBserviceProvider {
  _key: string;
  email: string;
  type: ServiceProvidersType;
  serviceProviderId: string;
}

export enum ServiceProvidersType {
  Shipping = 'shipping',
  Lab = 'lab',
}

export interface IServiceProviderListQuery {
  type: ServiceProvidersType;
}
