﻿import { h, FunctionComponent } from 'preact';

interface IProps {
  variant?: 'dark' | 'light';
  width?: number;
}

const colorCodes = {
  dark: '#282828',
  light: '#fff',
};

// width="597" height="106"
const Logo: FunctionComponent<IProps> = ({ variant = 'dark', width = 200 }) => {
  const color = colorCodes[variant];
  return (
    <svg
      width={width}
      viewBox="0 0 597 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ 'max-width': '100%' }}
    >
      <path
        d="M59.3426 39.7754C63.1785 37.6448 66.4243 34.1917 68.3423 29.857C72.9897 19.2774 68.121 6.93459 57.4246 2.30601C46.7282 -2.32256 34.4088 2.52642 29.7614 13.1795C27.7697 17.7346 27.5484 22.657 28.8024 27.1387L30.8679 35.1468C33.5974 45.4325 28.8762 56.2325 19.5076 61.3754L12.8684 65.0489C8.36856 67.106 4.60637 70.7795 2.46708 75.7019C-2.18033 86.2815 2.68839 98.6244 13.3848 103.253C24.0075 107.882 36.4006 103.033 41.048 92.3795C43.1135 87.6774 43.261 82.6081 41.9332 78.053L39.9415 70.2652C37.2858 59.906 42.0807 49.106 51.4493 44.0366L59.3426 39.7754Z"
        fill={color}
      />
      <path
        d="M76.2356 105.384C87.8468 105.384 97.2596 96.0091 97.2596 84.4449C97.2596 72.8807 87.8468 63.5061 76.2356 63.5061C64.6243 63.5061 55.2115 72.8807 55.2115 84.4449C55.2115 96.0091 64.6243 105.384 76.2356 105.384Z"
        fill={color}
      />
      <path
        d="M168.299 23.0979C151.479 23.0979 141.004 30.9591 137.39 44.0367L149.045 47.5632C151.627 38.5265 158.93 34.5591 168.077 34.5591C180.913 34.5591 186.077 40.2897 186.519 52.1183C175.454 53.5142 162.176 55.0571 152.881 57.6285C141.373 61.0081 134.439 67.6938 134.439 79.5224C134.439 91.5714 142.922 101.784 159.815 101.784C172.282 101.784 181.577 97.2285 187.552 88.1918V99.7999H198.47V53.6612C198.47 47.9306 198.101 42.4204 196.257 37.7183C192.052 28.0204 181.798 23.0979 168.299 23.0979ZM184.97 74.3795C182.979 83.6367 174.864 91.5714 161.586 91.351C150.889 91.351 146.537 85.3999 146.537 79.302C146.537 72.3959 152.07 69.0163 158.192 67.1061C165.495 65.1224 175.602 63.9469 186.151 62.551C186.151 66.0775 185.929 70.853 184.97 74.3795Z"
        fill={color}
      />
      <path
        d="M225.1 84.445C223.33 81.2858 223.551 77.1715 223.551 71.0001V35.3674H246.124V25.3021H223.551V4.58374H211.306V25.3021V35.3674V71.6613C211.306 79.3756 210.937 85.1062 213.888 90.7633C219.051 101.049 232.699 102.592 246.198 99.8001V89.147C236.018 90.7633 228.494 90.396 225.1 84.445Z"
        fill={color}
      />
      <path
        d="M288.099 22.2898C266.116 22.2898 252.026 38.0857 252.026 61.5225C252.026 84.8123 265.673 100.976 288.099 100.976C310.303 100.976 324.171 85.0327 324.171 61.5225C324.098 38.5265 310.451 22.2898 288.099 22.2898ZM288.099 89.3674C273.05 89.3674 265.157 78.3469 265.157 61.5225C265.157 45.1388 272.46 33.898 288.099 33.898C303.369 33.898 311.041 44.7714 311.041 61.5225C311.041 78.1265 303.516 89.3674 288.099 89.3674Z"
        fill={color}
      />
      <path
        d="M412.841 23.5387C403.104 23.5387 394.62 28.0938 390.268 35.7346C386.284 28.0203 378.612 23.5387 368.506 23.5387C359.58 23.5387 352.056 27.2856 347.335 33.3835V25.4489H336.269V100.02H348.736V52.706C348.736 41.8325 355.302 34.5591 364.965 34.5591C374.629 34.5591 381.194 41.6856 381.194 52.9264V100.094H393.44V52.706C393.44 40.0693 401.554 34.5591 409.89 34.5591C419.185 34.5591 425.898 41.2448 425.898 52.3386V100.094H438.144V49.5468C438.144 33.5305 428.701 23.3182 412.841 23.5387Z"
        fill={color}
      />
      <path
        d="M462.782 4.58374H450.537V99.8735H462.782V4.58374Z"
        fill={color}
      />
      <path
        d="M510.953 23.0979C489.191 23.0979 474.88 38.453 474.88 62.9183C474.88 86.2081 489.339 101.784 511.543 101.784C526.223 101.784 538.469 94.2897 544.444 81.0652L532.567 76.951C528.436 85.6204 520.912 90.1755 510.953 90.1755C497.306 90.1755 489.191 81.5061 488.159 65.9306H545.551C546.583 39.4816 533.526 23.0979 510.953 23.0979ZM488.38 56.2326C490.15 42.053 497.896 34.3387 511.322 34.3387C524.01 34.3387 531.092 41.6122 532.715 56.2326H488.38Z"
        fill={color}
      />
      <path
        d="M575.058 29.7836C572.697 31.5468 570.337 34.1182 568.714 37.057V25.3019H557.649V99.8734H570.115V62.404C570.115 53.5142 571.886 44.6244 579.189 39.555C584.353 35.808 591.066 35.4407 596.229 36.7631V25.3019C589.517 23.906 580.812 25.3019 575.058 29.7836Z"
        fill={color}
      />
    </svg>
  );
};

export default Logo;
