﻿// import { getOptionsLabelFromValue } from './index';
import { IItem } from '@@types';
export enum ItemPaymentOptionsValues {
  AtomlerPay = 'secure',
  Invoice = 'invoice',
}

export const paymentOptions = [
  { label: 'AtomlerPay', value: ItemPaymentOptionsValues.AtomlerPay },
  { label: 'Invoice', value: ItemPaymentOptionsValues.Invoice },
  { label: 'Visa/Mastercard', value: 'card', show: false },
];

export const getPaymentOptionsLabel = (value: string) => {
  const option = paymentOptions.find((opt) => opt.value === value);
  return option.label;
};

const hasPayment = (paymentType: ItemPaymentOptionsValues, item: IItem) => {
  if (item && item.attr && item.attr.paymentOptions) {
    return !!item.attr.paymentOptions.find(
      (paymentOption) => paymentOption.type === paymentType
    );
  }
  return false;
};

export const getPaymentOptions = (newItem) => {
  return paymentOptions
    .filter((item) => item.show !== false)
    .map((item) => {
      return {
        ...item,
        checked: hasPayment(item.value as ItemPaymentOptionsValues, newItem),
      };
    });
};

export const getCheckedPaymentOptions = (newItem) => {
  const options = getPaymentOptions(newItem);
  return options.reduce((acc, curr) => {
    if (curr.checked) {
      acc.push(curr);
    }
    return acc;
  }, []);
};

export enum PaymentOptions {
  Secure = 'secure',
  Invoice = 'invoice',
  Card = 'card',
  Other = 'other',
}
