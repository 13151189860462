﻿import { IValidationError } from '@@types';
import { BoundAction, Store } from 'unistore';
import { IStore } from 'shared/unistore';
import { createMatch } from 'api/match';
import { getItems } from 'api/item';
import { fetchUsers } from 'api/user';
import { defaultState } from './index';
import { IFilterOptions } from 'shared/api/item';
import { object, string, ValidationError } from 'yup';
export interface IActions {
  fetchItems: BoundAction;
  fetchMatches: BoundAction;
  saveMatch: BoundAction;
  fetchUsers: BoundAction;
  setSelectedId: BoundAction;
  unsetDisplayMatch: BoundAction;
}

const errors = {
  offerUserId: 'Offer user is required',
  offerItemId: 'Offer item is required',
  requestUserId: 'Request user is required',
  requestItemId: 'Request item is required',
};

const matchValidationRules = object().shape({
  offerUserId: string()
    .typeError(errors.offerUserId)
    .required(errors.offerUserId),
  offerItemId: string()
    .typeError(errors.offerItemId)
    .required(errors.offerItemId),
  requestUserId: string()
    .typeError(errors.requestUserId)
    .required(errors.requestUserId),
  requestItemId: string().nullable(),
});

interface IValidationPayload {
  offerUserId: string;
  offerItemId: string;
  requestUserId: string;
  requestItemId: string;
}

export interface IMatch extends IValidationPayload {
  createDate: string;
  userId: string;
  remoteAddress: string;
  _key: string;
}

// Helper ffs
export const validatePayload = (
  payload: IValidationPayload
): IValidationError[] => {
  let validationErrors: ValidationError;
  try {
    matchValidationRules.validateSync(payload, { abortEarly: false });
  } catch (err) {
    validationErrors = err;
  }
  return validationErrors
    ? validationErrors.inner.map((e) => ({
        msg: e.message,
        prop: e.path,
      }))
    : [];
};
// export const itemValidationSchema = object().shape({
//   const emailValidationRules = object().shape({
//     email: string().email('Email is not valid').required('Enter your email address'),
// });

const fetchOrder: IFilterOptions = {
  orderBy: 'plasticType',
  orderDirection: 'asc',
};

const actions = (store: Store<IStore>) => {
  const setBool = (
    prop: 'fetchingItems' | 'fetchingMatches' | 'saving',
    value: boolean
  ) => {
    store.setState({
      matcher: {
        ...store.getState().matcher,
        [prop]: value,
      },
    });
  };
  return {
    reset: () => {
      store.setState(
        {
          ...store.getState(),
          matcher: defaultState,
        },
        true
      );
    },
    unsetDisplayMatch: ({ matcher }: IStore) => {
      return {
        matcher: {
          ...matcher,
          displayMatch: null,
        },
      };
    },
    fetchItems: async () => {
      setBool('fetchingItems', true);
      try {
        const result = await getItems(fetchOrder);
        const { data } = result;
        store.setState({
          matcher: {
            ...store.getState().matcher,
            items: data.items,
          },
        });
        console.log('result', result);
      } catch (error) {
        console.log('error fetching items', error);
      }
      setBool('fetchingItems', false);
    },
    fetchUsers: async () => {
      store.setState({
        matcher: {
          ...store.getState().matcher,
        },
      });

      try {
        const reply = await fetchUsers();
        const { data } = reply;
        store.setState({
          matcher: {
            ...store.getState().matcher,
            users: data.users,
          },
        });
      } catch (error) {
        console.log('error fetching users', error);
        // TODO Handle this plz. Maybe add a step?
      }
    },
    saveMatch: async ({ matcher }: IStore) => {
      setBool('saving', true);
      const { offerItemId, requestItemId, offerUserId, requestUserId } =
        matcher;
      const payload = {
        offerItemId,
        requestItemId,
        offerUserId,
        requestUserId,
      };
      const validationErrors = validatePayload(payload);
      if (validationErrors.length > 0) {
        return {
          matcher: {
            ...matcher,
            errors: validationErrors,
          },
        };
      } else {
        try {
          const returnData = await createMatch(payload);
          const { new: match } = returnData.data;
          store.setState({
            matcher: {
              ...store.getState().matcher,
              displayMatch: match,
            },
          });
        } catch (error) {
          console.log('Error creating match', error);
        }
        setBool('saving', false);
      }
    },
    setSelectedId: (
      { matcher },
      prop: 'offerUserId' | 'requestUserId' | 'offerItemId' | 'requestItemId',
      id: string
    ) => {
      if (prop === 'offerUserId') {
        return {
          matcher: {
            ...matcher,
            [prop]: id,
            offerItemId: null,
          },
        };
      }
      if (prop === 'requestUserId') {
        return {
          matcher: {
            ...matcher,
            [prop]: id,
            requestItemId: null,
          },
        };
      }

      return {
        matcher: {
          ...matcher,
          [prop]: id,
        },
      };
    },
  };
};

export default actions;
