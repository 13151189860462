﻿import { FormOptions } from '@@helpers/sign-up/formOptions';
import { getOptionsLabelFromValue, IReadonlyMap } from './index';
import { mapValueWithLabel } from './mappers';

export enum ItemFormValues {
  Any = 'any',
  Bales = 'bales',
  Film = 'film',
  Granulate = 'granulate',
  Regrind = 'regrind',
  Other = 'other',
}

export const ItemFormValuesMap = (value: ItemFormValues) => {
  switch (value) {
    case ItemFormValues.Bales:
      return [FormOptions.baledFilm, FormOptions.baledHardPlastic];
    case ItemFormValues.Film:
      return [FormOptions.baledFilm];
    case ItemFormValues.Granulate:
      return [FormOptions.regranulate];
    case ItemFormValues.Regrind:
      return [FormOptions.regrind];
    case ItemFormValues.Other:
      return [FormOptions.other, FormOptions.filmRolls, FormOptions.scrap];
    case ItemFormValues.Any:
      return [
        FormOptions.other,
        FormOptions.filmRolls,
        FormOptions.baledHardPlastic,
        FormOptions.scrap,
        FormOptions.baledFilm,
        FormOptions.regranulate,
        FormOptions.regrind,
      ];
    default:
      return [FormOptions.other];
  }
};

const itemFormLabels: IReadonlyMap<ItemFormValues, string> = new Map([
  [ItemFormValues.Any, 'Any'],
  [ItemFormValues.Bales, 'Bales'],
  [ItemFormValues.Film, 'Film'],
  [ItemFormValues.Granulate, 'Granulate/pellet'],
  [ItemFormValues.Regrind, 'Regrind'],
  [ItemFormValues.Other, 'Other'],
]);

export const itemFormOptions = mapValueWithLabel(
  ItemFormValues,
  itemFormLabels
);

export const customItemFormOptions = (formArray) => {
  const customFormOption = mapValueWithLabel(formArray, itemFormLabels);
  const filtered = customFormOption.filter((option) => {
    return option.label !== undefined;
  });
  return filtered;
};

export const getItemFormsLabel = (value: string) =>
  getOptionsLabelFromValue(itemFormOptions, value);

export const getItemForms = (newItem) => {
  return itemFormOptions.map((item) => {
    return {
      ...item,
      checked: newItem.commercialTerms === item.value,
    };
  });
};
