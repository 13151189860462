﻿import { IValidatorResult, IServerError, ISignUpData } from '@@types';

export enum Steps {
  Done = 'done',
  Form = 'form',
  OTP = 'otp',
}

export interface IModel {
  creating?: boolean;
  errors?: IValidatorResult;
  serverError?: IServerError;
  form?: ISignUpData;
  statusMessage?: string;
  step: Steps;
}

export const defaultUserForm: ISignUpData = {
  zipcode: '',
  city: '',
  country: '',
  vatno: '',
  name: '',
  email: '',
  company: '',
  password: '',
  password_verify: '',
  verified: false, // really?
  helpWith: [],
  interestedMaterials: [],
  capabilities: [],
  preferredForm: [],
  phone: '',
};

export const defaultState: IModel = {
  creating: false,
  errors: null,
  serverError: null,
  form: defaultUserForm,
  statusMessage: null,
  step: Steps.Form,
};

export const state = (): IModel => {
  return {
    ...defaultState,
  };
};

export default state;
