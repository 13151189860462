import { h } from 'preact';

interface IProps extends h.JSX.SVGAttributes<SVGSVGElement> {
  primaryColor?: string;
  secondaryColor?: string;
  size?: number;
}

export const Heartheart = (props: IProps) => (
  <svg
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      d="M9.6538 4.36873L10 4.70096L10.3462 4.36873C11.2301 3.52052 12.4287 3 13.75 3C16.4654 3 18.6667 5.20127 18.6667 7.91667C18.6667 10.3697 17.3277 12.3031 15.6384 13.7698C13.9486 15.2367 11.9604 16.1884 10.7983 16.6665C10.282 16.8789 9.718 16.8789 9.20171 16.6665C8.03956 16.1884 6.05136 15.2368 4.36163 13.7698C2.67229 12.3032 1.33334 10.3697 1.33334 7.91667C1.33334 5.20127 3.5346 3 6.25 3C7.57127 3 8.76992 3.52052 9.6538 4.36873Z"
      stroke="black"
    />
    <path
      opacity="0.5"
      d="M9.66774 7.75813L10 8.0536L10.3323 7.75813L11.2298 6.95995C11.6635 6.57427 12.2549 6.33398 12.9167 6.33398C14.2879 6.33398 15.3333 7.35538 15.3333 8.53676C15.3333 10.0659 14.1958 11.3482 12.5675 12.377C11.7927 12.8665 11.005 13.2328 10.404 13.4775C10.2551 13.5381 10.1194 13.5906 10 13.6351C9.88062 13.5906 9.74485 13.5381 9.59601 13.4775C8.99495 13.2328 8.20725 12.8665 7.4325 12.377C5.80418 11.3482 4.66666 10.0659 4.66666 8.53676C4.66666 7.35538 5.7121 6.33398 7.08333 6.33398C7.74511 6.33398 8.33648 6.57427 8.77018 6.95995L9.66774 7.75813Z"
      stroke="black"
    />
  </svg>
);

export default Heartheart;
