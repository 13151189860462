export enum IMaterialQualityState {
  Registered = 'registered',
  UnRegistered = 'un-registered',
  Finished = 'finished',
}
export interface IMaterialQualityRegisterParams {
  description: string;
  appearance: {
    name: string;
    size: string;
  };
  visualContamination: boolean;
  deliveryDate?: string;
  datasheet?: any;
}

export interface IMaterialQualityCreatePayload {
  materialQualitySampleId: string;
  state: string;
  serviceProviderId: string;
  trackingId: string;
}

export interface IMaterialQualityDataSheetPayload {
  datasheet: { [key: string]: any };
  publish: boolean;
}

export enum IMaterialQualityPackages {
  APPEARANCE = 'Visual appearance',
  PHYSICAL = 'Physical properties',
  MECHANICAL = 'Mechanical properties',
  MATERIAL = 'Material composition',
}

export interface ILabTerminalRowData {
  trackingId: string;
  materialType: string;
  packages: Array<string>;
  state: string;
  weight: string;
  orderDate: string;
  deliveryDate: string;
  materialRegisterUrl: string;
  printUrl: string;
  materialDatasheetUrl: string;
}

export interface IMissingMaterialQualityFormDataSchema {
  active: boolean;
  missingPhysicalData: string;
  missingMechanicalData: string;
  missingMaterialData: string;
  payload: any;
}
