﻿import { getOptionsLabelFromValue, IReadonlyMap } from './';
import { mapValueWithLabel } from './mappers';

export enum CommercialTermsValues {
  EXW = 'exw',
  FCA = 'fca',
  FAS = 'fas',
  FOB = 'fob',
  CFR = 'cfr',
  CIF = 'cif',
  CIP = 'cip',
  CPT = 'cpt',
  DAP = 'dap',
  DPU = 'dpu',
  // DAT = 'dat', // Not in
  DDP = 'ddp',
  Other = 'other',
}

const commercialTermsLabels: IReadonlyMap<CommercialTermsValues, string> =
  new Map([
    [CommercialTermsValues.EXW, 'EXW - Ex Works'],
    [CommercialTermsValues.FCA, 'FCA - Free carrier'],
    [CommercialTermsValues.FAS, 'FAS - Free alongside ship'],
    [CommercialTermsValues.FOB, 'FOB - Free on board'],
    [CommercialTermsValues.CFR, 'CFR - Cost and freight'],
    [CommercialTermsValues.CIF, 'CIF - Cost, insurance and freight'],
    [CommercialTermsValues.CIP, 'CIP - Carriage and insurance paid to'],
    [CommercialTermsValues.CPT, 'CPT - Carriage paid to'],
    [CommercialTermsValues.DAP, 'DAP - Delivered at place'],
    [CommercialTermsValues.DPU, 'DPU - Delivered at place unloaded'],
    // [CommercialTermsValues.DAT, 'DAT - Delivered at terminal'],
    [CommercialTermsValues.DDP, 'DDP - Delivered duty paid'],
    [CommercialTermsValues.Other, 'Other'],
  ]);

export const commercialTerms = mapValueWithLabel(
  CommercialTermsValues,
  commercialTermsLabels
);

export const getCommercialTermsLabel = (value: string) =>
  getOptionsLabelFromValue(commercialTerms, value);

export const getCommercialTerms = (newItem) => {
  return commercialTerms.map((item) => {
    return {
      ...item,
      checked: newItem.commercialTerms === item.value,
    };
  });
};
