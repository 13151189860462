﻿import { ItemAttributes, IItem } from './../types';
import {
  ItemPackagingValues,
  getPackagingOptionsLabel,
} from './item/packagingOptions';
import { safeLowerCase } from './string-helper';

export const isShippingPackageWeightSelectedAndNotOther = (
  packagingValue: ItemPackagingValues
) => {
  const isOther = packagingValue === ItemPackagingValues.Other;
  const isEmpty = !packagingValue;
  return !isOther && !isEmpty;
};

export const getShippingWeightUnit = (packagingValue: ItemPackagingValues) => {
  const packagingLabel =
    safeLowerCase(getPackagingOptionsLabel(packagingValue)) || '';
  const labelEndsWithS =
    packagingLabel.charAt(packagingLabel.length - 1) === 's';
  const packageIsBags = packagingLabel === 'bags';
  const singularPackagingLabel = labelEndsWithS
    ? packagingLabel.slice(0, -1)
    : packagingLabel;
  const actualPackagingLabel = packageIsBags
    ? 'pallet'
    : singularPackagingLabel;
  return packagingValue &&
    packagingValue.toLowerCase &&
    isShippingPackageWeightSelectedAndNotOther(packagingValue)
    ? 'kg / ' + actualPackagingLabel
    : null;
};

export const getShippingDimensionsUnit = (
  packagingValue: ItemPackagingValues
) => {
  const packagingLabel =
    safeLowerCase(getPackagingOptionsLabel(packagingValue)) || '';
  const labelEndsWithS =
    packagingLabel.charAt(packagingLabel.length - 1) === 's';
  const isBagsOctabinPalletCollar =
    packagingLabel === 'bags' ||
    packagingLabel === 'big bag' ||
    packagingLabel === 'octabin' ||
    packagingLabel === 'pallet collar';
  const singularPackagingLabel = labelEndsWithS
    ? packagingLabel.slice(0, -1)
    : packagingLabel;
  const shippingDimensionUnit = isBagsOctabinPalletCollar
    ? 'pallet'
    : singularPackagingLabel;
  return packagingValue &&
    packagingValue.toLowerCase &&
    isShippingPackageWeightSelectedAndNotOther(packagingValue)
    ? 'Packaging dimensions / ' + shippingDimensionUnit
    : null;
};

export const getShippingWeightText = (item: IItem) => {
  const packagingValue = item.attr[ItemAttributes.Packaging];
  if (Array.isArray(packagingValue)) {
    // This will fail if we use it for requests
    return null;
  }

  const shippingWeightValue = item.attr[ItemAttributes.ShippingPackageWeight];
  const shippingWeightUnit = getShippingWeightUnit(packagingValue);
  return shippingWeightValue
    ? shippingWeightValue + ' ' + shippingWeightUnit
    : '';
};
