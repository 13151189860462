﻿import { h, Component, FunctionComponent } from 'preact';
import { useState } from 'preact/hooks';
// import * as Portal from 'preact-portal';
import { Box, Grid, Button, IconButton } from '@@helpers/material-ui/core';
import { Close } from '@@helpers/material-ui/icons';
import { H3, ParagraphMedium } from 'components/ui/typography/Fonts';
import { AtomlerButton } from 'components/ui/Button';

type ModalWidth = 'normal' | 'narrow' | 'wide';

interface IProps {
  onClose: () => any;
  narrow?: boolean;
  overRideCss?: string | undefined;
  surfaceStyle?: any;
  width?: ModalWidth;
  closeBtn?: boolean;
  closeBtnStyle?: any;
  onCloseIconButton?: () => any;
}

export class Modal extends Component<IProps> {
  private wrapperRef = undefined;
  private isMounted = false;

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (evt: Event) => {
    if (this.isMounted) {
      const { onClose } = this.props;
      if (onClose && this.wrapperRef && !this.wrapperRef.contains(evt.target)) {
        onClose();
      }
    }
  };

  handleEscClick = (evt: KeyboardEvent) => {
    const { onClose } = this.props;
    if (evt.key === 'Escape' || evt.keyCode === 27) {
      onClose();
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    // document.addEventListener('click', this.handleClickOutside);
    document.addEventListener('keyup', this.handleEscClick);
    setTimeout(() => {
      this.isMounted = true;
    }, 40);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    // document.addEventListener('click', this.handleClickOutside);
    document.removeEventListener('keyup', this.handleEscClick);
    this.isMounted = false;
  }

  public render() {
    const {
      children,
      narrow,
      overRideCss,
      surfaceStyle,
      width,
      closeBtn = true,
      closeBtnStyle,
      onCloseIconButton,
    } = this.props;
    const surfaceCssClass =
      overRideCss === undefined ? 'modal__surface' : overRideCss;
    let modalWidth: ModalWidth = width || 'normal';
    if (narrow) {
      modalWidth = 'narrow';
    }
    const surfaceClass =
      surfaceCssClass +
      (modalWidth !== 'normal' ? ` modal__surface--${modalWidth}` : '');
    return (
      // <Portal into="body">
      <div class="modal">
        <div class={surfaceClass} ref={this.setWrapperRef} style={surfaceStyle}>
          <Box className="absolute top-0 right-0" sty>
            {closeBtn ? (
              <IconButton
                onClick={onCloseIconButton || this.props.onClose}
                color="default"
                aria-label="close modal"
                component="span"
                style={closeBtnStyle}
              >
                <Close />
              </IconButton>
            ) : null}
          </Box>
          {children}
        </div>
        <div className="modal__backdrop" />
      </div>
      // </Portal>
    );
  }
}

export const ModalBody: FunctionComponent = (props) => {
  return (
    <div class="modal__body">
      <ParagraphMedium component="div">{props.children}</ParagraphMedium>
    </div>
  );
};

export const ModalFooter: FunctionComponent = (props) => {
  return (
    <Box mt={2} className="modal__footer">
      {props.children}
    </Box>
  );
};

export const ModalHeader: FunctionComponent = (props) => {
  return (
    <div class="modal__header">
      <Box mb={2}>
        <H3 gutterBottom>{props.children}</H3>
      </Box>
    </div>
  );
};

interface IConfirmProps {
  onCancel: () => unknown;
  onConfirm: (evt: Event) => Promise<unknown>;
  children: h.JSX.Element | h.JSX.Element[] | string;
  width?: ModalWidth;
  title?: string;
}

export const ConfirmModal: FunctionComponent<IConfirmProps> = ({
  width = 'normal',
  title = 'Confirm',
  onCancel,
  children,
  onConfirm,
}) => {
  const [disabledButtons, setDisableButtons] = useState(false);
  const handleConfirm = async (evt: Event) => {
    setDisableButtons(true);
    try {
      await onConfirm(evt);
    } catch (error) {
      console.log('error');
      throw error;
    } finally {
      setTimeout(() => {
        setDisableButtons(false);
      }, 2000);
    }
  };

  return (
    <Modal width={width} onClose={onCancel}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <AtomlerButton
              onClick={onCancel}
              fullWidth
              disabled={disabledButtons}
              ourProps={{
                variant: 'secondary',
                size: 'small',
              }}
            >
              Cancel
            </AtomlerButton>
          </Grid>
          <Grid item xs={12} sm={6}>
            <AtomlerButton
              onClick={handleConfirm}
              fullWidth
              disabled={disabledButtons}
              ourProps={{
                variant: 'negative',
                size: 'small',
              }}
            >
              Confirm
            </AtomlerButton>
          </Grid>
        </Grid>
      </ModalFooter>
    </Modal>
  );
};

interface IModalButtonProps {
  onClick?: (evt: Event) => void;
  disabled?: boolean;
  isPrimary?: boolean;
}

export const ModalFooterButton: FunctionComponent<IModalButtonProps> = (
  props
) => {
  const { children, onClick, disabled, isPrimary } = props;
  return (
    <Box ml={1}>
      <Button
        type={isPrimary ? 'submit' : 'button'}
        color={isPrimary ? 'secondary' : 'default'}
        variant={isPrimary ? 'contained' : 'outlined'}
        onClick={onClick}
        disabled={disabled || false}
        disableElevation
        {...props}
      >
        {children}
      </Button>
    </Box>
  );
};
