import { SecondaryButton, SmallButton } from 'components/ui/Button';
import { h, FunctionComponent } from 'preact';
import { useCookieConsent } from '@use-cookie-consent/core';
import { Modal, ModalBody } from 'components/modal/Modal';
import Grid from '@material-ui/core/Grid';
import theme from 'styles/theme';

export const Compliance: FunctionComponent<any> = ({ url }) => {
  const { consent, acceptAllCookies, declineAllCookies } = useCookieConsent();
  return Object.entries(consent).length === 0 && url != '/privacy-policy' ? (
    <Modal
      width="narrow"
      surfaceStyle={{
        backgroundColor: theme.palette.sand.main,
      }}
      closeBtn={false}
      onClose={() => {
        null;
      }}
    >
      <ModalBody>
        <Grid container item spacing={2}>
          <Grid xs={12} item style={{ padding: '1.5rem' }}>
            This website uses cookies to improve your experience.{' '}
            <a
              href="privacy-policy"
              style={{ color: theme.palette.black.main }}
            >
              Read more
            </a>
          </Grid>
          <Grid xs={12} item container spacing={1}>
            <Grid xs={12} md={6} item>
              <SmallButton onClick={() => acceptAllCookies()} fullWidth>
                Accept
              </SmallButton>
            </Grid>
            <Grid xs={12} md={6} item>
              <SecondaryButton onClick={() => declineAllCookies()} fullWidth>
                Decline
              </SecondaryButton>
            </Grid>
          </Grid>
        </Grid>
      </ModalBody>
    </Modal>
  ) : null;
};

export default Compliance;
