﻿import { h, FunctionComponent } from 'preact';
import { H1, ParagraphLarge } from 'components/ui/typography/Fonts';
import { Box } from '@@helpers/material-ui/core';
import { makeStyles, Theme } from '@@helpers/material-ui/core/styles';

interface IProps {
  preHeadline?: string;
  headline: string;
  description?: string | JSX.Element[] | JSX.Element;
  rightAligned?: string | JSX.Element[] | JSX.Element;
}

const useStyles = makeStyles((theme: Theme) => ({
  rightAligned: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
      float: 'right',
    },
  },
}));

export const PageIntro: FunctionComponent<IProps> = ({
  preHeadline,
  headline,
  description,
  rightAligned,
}) => {
  const classes = useStyles();
  return (
    <Box mb={4}>
      {preHeadline && (
        <Box mb={1}>
          <ParagraphLarge weight="bold">{preHeadline}</ParagraphLarge>
        </Box>
      )}
      {headline && <H1>{headline}</H1>}
      {rightAligned && (
        <Box className={classes.rightAligned}>{rightAligned}</Box>
      )}
      {description && (
        <Box mt={2}>
          <ParagraphLarge weight="regular">{description}</ParagraphLarge>
        </Box>
      )}
    </Box>
  );
};

export default PageIntro;
