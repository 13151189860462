﻿import { getOptionsLabelFromValue } from './index';

export enum ItemQualityControlledValues {
  Yes = 'yes',
  No = 'no',
  Other = 'other',
}

export const qualityControlledOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
  { label: 'Other/unknown', value: 'other' },
];

export const getQualityControlledOptions = (newItem) => {
  return qualityControlledOptions.map((item) => {
    return {
      ...item,
      checked: newItem.qualityControlled === item.value,
    };
  });
};

export const getQualityControlledLabel = (value: string) =>
  getOptionsLabelFromValue(qualityControlledOptions, value);
