﻿import Container from './Container';
import Main from './Main';
import Header from './Header';
import Footer from './Footer';
import PageIntro from './PageIntro';
import EmojionalFeedback from './feedbackModal';

export { Container, Main, Header, Footer, PageIntro, EmojionalFeedback };

export default {
  Container,
  Main,
  Header,
  Footer,
  PageIntro,
  EmojionalFeedback,
};
