﻿import { IFile } from '@@types';
import { BoundAction, Store } from 'unistore';
import { IStore } from 'shared/unistore';
// import { IModel, defaultState, IItemModel, IPlasticAttrs } from './index';
// import { getItem as getItemApi } from 'api/item';
import { IModel, defaultState } from './';
import { getItem, updateItem } from 'api/item';
import { isValidQuantity } from '@@helpers/item/validators';

// export interface IUser {
//     companies: any[];
//     email: string;
//     id: string;
//     name: string;
//     _key: string;
// }

// export interface IDbItemModel {
//     attr: IPlasticAttrs;
//     createDate: Date;
//     title: string;
//     user: IUser;
//     userId: string;
// }

export interface IActions {
  reset: BoundAction;
  fetchItem: BoundAction;
  saveFiles: BoundAction;
  saveSupply: BoundAction;
  updateSupply: BoundAction;
}

export const reset = () => {
  return {
    editItem: defaultState,
  };
};

interface IEditItem {
  editItem: IModel;
} // Helper

const actions = (store: Store<IStore>) => ({
  reset,
  fetchItem: async ({ editItem }: IEditItem, itemId: string) => {
    store.setState({
      editItem: {
        ...editItem,
        fetching: true,
      },
    });

    let result: any;
    try {
      result = await getItem(itemId);
    } catch (error) {
      console.log('error fetching item', itemId, error);
    }

    const item =
      result && result.data && result.data.item ? result.data.item : null;
    store.setState({
      editItem: {
        ...editItem,
        fetching: false,
        itemId: item._key,
        title: item.title,
      },
    });
  },
  saveFiles: async (
    { editItem }: IEditItem,
    itemId: string,
    newFiles: IFile[]
  ) => {
    store.setState({
      editItem: {
        ...editItem,
        updating: true,
      },
    });

    const newProps = {
      files: newFiles,
    };

    let result: any;
    try {
      result = await updateItem(itemId, newProps);
    } catch (error) {
      console.log('error saving files', itemId, error);
    }
  },
  updateProp: async ({ editItem }: IEditItem, prop: string, value: string) => {
    store.setState({
      editItem: {
        ...editItem,
        updating: true,
        newSupplyIsValid: true,
        message: null,
      },
    });

    const newProp = {
      [prop]: value,
    };

    let result: any;
    try {
      result = await updateItem(editItem.itemId, newProp);
    } catch (error) {
      console.log('error saving supply', editItem.itemId, error);
    }
  },
  saveSupply: async ({ editItem }: IEditItem) => {
    store.setState({
      editItem: {
        ...editItem,
        updating: true,
        newSupplyIsValid: true,
        message: null,
      },
    });

    const newProps = {
      supply: parseInt(editItem.newSupply, 10),
    };

    let result: any;
    try {
      result = await updateItem(editItem.itemId, newProps);
    } catch (error) {
      console.log('error saving supply', editItem.itemId, error);
    }
  },
  updateSupply: async ({ editItem }: IEditItem, newSupply: string) => {
    const isValid = await isValidQuantity(newSupply);
    console.log('isValid', isValid);

    store.setState({
      editItem: {
        ...editItem,
        newSupply,
        newSupplyIsValid: isValid,
        message: isValid ? null : 'Supply must be a number',
      },
    });
  },
});

export default actions;
// const mapDbItemToItem = (dbItem: IDbItemModel): IItemModel => {
//     return {
//         title: dbItem.title,
//         color: dbItem.attr.color,
