﻿import { IAddress } from './address';

export enum InvoiceTypes {
  order = 'order',
  materialTest = 'materialTest',
}

export interface IInvoiceType {
  type: InvoiceTypes;
  id: string;
}

export interface IInvoiceItem {
  name: string;
  quantity: number;
  unitCost: number;
  description?: string;
}

export interface IInvoiceFields {
  tax?: '%';
  discounts?: boolean;
  shipping?: boolean;
}

export interface IInvoiceDataInput {
  to: IAddress;
  toVAT: string;
  vatCountry: string;
  number: number;
  paymentTerms: string;
  items: IInvoiceItem[];
  fields: IInvoiceFields;
  duration?: number;
  // tax: number;
}

export interface IInvoiceGeneratePayload {
  itemId: string;
  invoiceNumber: string;
}
