﻿import { h, JSX, ComponentType } from 'preact';
import { connect as unistoreConnect } from 'unistore/preact';
import { actions } from './actions';
import { ICredentials, IModel } from './index';
import { ILinkLoginData } from '@@types/user';
import { ILoginData } from 'shared/unistore/Auth/actions';

interface IAuthActions {
  hideLogin: () => void;
  linkLogin: (linkLoginInfo: ILinkLoginData) => void;
  login: (
    loginData: ILoginData,
    redirectUrl?: string,
    serviceProviderLogin?: boolean
  ) => void;
  logout: () => void;
  reset: () => void;
  sendLinkLogin: (email: string) => void;
  showLogin: () => void;
  validateLoginEmail: (email: string) => void;
  validateLoginLinkEmail: (email: string) => void;
  generateOTP: (isRegenerated: boolean) => Promise<void>;
  checkOTP: (OTPValue: string) => void;
  deleteOTP: (OTPData: Record<string, unknown>) => void;
  setCredentials: (credentials: ICredentials) => void;
}

// Dummy for SSR
export const defaultAuthActions: IAuthActions = {
  hideLogin: () => null,
  linkLogin: () => null,
  login: () => null,
  logout: () => null,
  reset: () => null,
  sendLinkLogin: () => null,
  showLogin: () => null,
  validateLoginEmail: () => null,
  validateLoginLinkEmail: () => null,
  generateOTP: () => null,
  checkOTP: () => null,
  deleteOTP: () => null,
  setCredentials: () => null,
};

export interface IProps extends IAuthActions {
  auth: IModel;
}

export type IMergedAuthProps = IModel & IAuthActions;

// export const withAuth = <P extends IProps> (WrappedComponent: AnyComponent<any, any>) => {
export const withAuth = <P extends IProps>(
  WrappedComponent: ComponentType<any>
) => {
  return unistoreConnect(
    'auth',
    actions
  )((props: P & JSX.Element) => {
    const {
      hideLogin,
      linkLogin,
      login,
      logout,
      reset,
      sendLinkLogin,
      showLogin,
      validateLoginEmail,
      validateLoginLinkEmail,
      generateOTP,
      checkOTP,
      deleteOTP,
      setCredentials,
      ...restProps
    } = props;

    const auth: IMergedAuthProps = {
      ...props.auth,
      hideLogin,
      linkLogin,
      login,
      logout,
      reset,
      sendLinkLogin,
      showLogin,
      validateLoginEmail,
      validateLoginLinkEmail,
      generateOTP,
      checkOTP,
      deleteOTP,
      setCredentials,
    };

    return <WrappedComponent {...restProps} auth={auth} />;
  });
};
