﻿import { h, FunctionComponent } from 'preact';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@@helpers/material-ui/core';
import {
  makeStyles,
  Theme,
  withStyles,
} from '@@helpers/material-ui/core/styles';

export const MenuButton = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 25,
    border: '2px',
    borderColor: theme.palette.black.main,
    textTransform: 'none',
    borderStyle: 'solid',
    fontSize: '.9rem',
    fontFamily:
      'Manrope, "Roboto", "Franklin Gothic Medium", Tahoma, sans-serif',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
}))(MuiButton);

export const Button = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 100,
    fontSize: '20px',
    backgroundColor: theme.palette.fire.main,
    color: theme.palette.white.main,
    textTransform: 'none',
    fontWeight: 400,
    fontFamily:
      'Manrope, "Roboto", "Franklin Gothic Medium", Tahoma, sans-serif',
    paddingTop: theme.spacing(1) * 2.5,
    paddingBottom: theme.spacing(1) * 2.5,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    lineHeight: 'unset',
    '&:hover': {
      backgroundColor: theme.palette.fire.light,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.pearl.dark,
      color: theme.palette.sand.dark,
    },
  },
}))(MuiButton);

// export const MediumButton = withStyles((theme: Theme) => ({
//   root: {
//     borderRadius: 25,
//     fontSize: '20px',
//     backgroundColor: theme.palette.primary.main,
//     color: '#fff',
//     textTransform: 'none',
//     fontWeight: 400,
//     fontFamily: 'Manrope, "Roboto", "Franklin Gothic Medium", Tahoma, sans-serif',
//     paddingTop: theme.spacing(1),
//     paddingBottom: theme.spacing(1),
//     paddingLeft: theme.spacing(2),
//     paddingRight: theme.spacing(2),
//     lineHeight: 'unset',
//     '&:hover': {
//       backgroundColor: theme.palette.primary.light
//     }
//   }
// }))(MuiButton);

const BaseButton = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 100,
    fontSize: '20px',
    textTransform: 'none',
    fontWeight: 400,
    fontFamily:
      'Manrope, "Roboto", "Franklin Gothic Medium", Tahoma, sans-serif',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    lineHeight: 'unset',
    '&.Mui-disabled': {
      backgroundColor: theme.palette.pearl.dark,
      color: theme.palette.sand.dark,
    },
  },
}))(MuiButton);

const BaseButtonSmall = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 100,
    fontSize: '16px',
    textTransform: 'none',
    fontWeight: 400,
    fontFamily:
      'Manrope, "Roboto", "Franklin Gothic Medium", Tahoma, sans-serif',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    lineHeight: 'unset',
    '&.Mui-disabled': {
      backgroundColor: theme.palette.pearl.dark,
      color: theme.palette.sand.dark,
    },
  },
}))(MuiButton);

const BaseButtonMini = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 100,
    textTransform: 'none',
    fontWeight: 400,
    fontFamily:
      'Manrope, "Roboto", "Franklin Gothic Medium", Tahoma, sans-serif',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: '14px',
    lineHeight: 'unset',
    '&.Mui-disabled': {
      backgroundColor: theme.palette.pearl.dark,
      color: theme.palette.sand.dark,
    },
  },
}))(MuiButton);

export const SmallButton = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.fire.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.fire.light,
    },
  },
}))(BaseButtonSmall);

export const MiniButton = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.fire.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.fire.light,
    },
  },
}))(BaseButtonMini);

export const SmallButtonAlt = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.sea.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.sea.light,
    },
  },
}))(BaseButtonSmall);

export const MiniButtonAlt = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.sea.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.sea.light,
    },
  },
}))(BaseButtonMini);

export const DeleteButton = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.negative.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.negative.light,
    },
  },
}))(BaseButtonSmall);

export const SecondaryButtonBig = withStyles((theme: Theme) => ({
  root: {
    border: '2px',
    borderColor: theme.palette.black.main,
    borderStyle: 'solid',
    color: theme.palette.black.main,
    paddingTop: theme.spacing(2) - 2,
    paddingBottom: theme.spacing(2) - 2,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    // '&.Mui-disabled': {
    //   borderColor: theme.palette.white.main,
    //   color: theme.palette.sand.dark
    // },
  },
  // label: {
  //   textTransform: 'none'
  // }
}))(BaseButton);

export const SecondaryButton = withStyles((theme: Theme) => ({
  root: {
    border: '2px',
    borderColor: theme.palette.black.main,
    borderStyle: 'solid',
    color: theme.palette.black.main,
    paddingTop: theme.spacing(2) - 2,
    paddingBottom: theme.spacing(2) - 2,
    backgroundColor: 'transparent',
    '&.Mui-disabled': {
      color: theme.palette.sand.dark,
      borderColor: theme.palette.sand.dark,
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.black.light,
      borderColor: theme.palette.black.light,
      textDecoration: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  // label: {
  //   textTransform: 'none'
  // }
}))(BaseButtonSmall);

export const SecondaryButtonBlack = withStyles((theme: Theme) => ({
  root: {
    borderColor: theme.palette.white.main,
    color: theme.palette.white.main,
    '&.Mui-disabled': {
      color: theme.palette.sand.dark,
      borderColor: theme.palette.sand.dark,
      backgroundColor: 'transparent',
    },
    '&:hover': {
      color: theme.palette.white.main,
      borderColor: theme.palette.white.main,
      textDecoration: 'underline',
    },
  },
  // label: {
  //   textTransform: 'none'
  // }
}))(SecondaryButton);

export const SecondaryButtonMini = withStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1) - 2,
    paddingBottom: theme.spacing(1) - 2,
    fontSize: '14px',
  },
}))(SecondaryButton);

export const SecondaryButtonMiniDelete = withStyles((theme: Theme) => ({
  root: {
    borderColor: theme.palette.negative.main,
    color: theme.palette.negative.main,
    '&:hover': {
      borderColor: theme.palette.negative.light,
      color: theme.palette.negative.light,
      backgroundColor: 'unset',
    },
  },
}))(SecondaryButtonMini);

export const EmptyButtonMini = withStyles((theme: Theme) => ({
  root: {
    borderColor: theme.palette.background.default,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1) - 2,
    paddingBottom: theme.spacing(1) - 2,
    fontSize: '14px',
    '&:hover': {
      borderColor: theme.palette.background.default,
      color: theme.palette.negative.light,
      backgroundColor: 'unset',
    },
  },
}))(SecondaryButton);

export const SecondaryGreenButtonMini = withStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1) - 2,
    paddingBottom: theme.spacing(1) - 2,
    fontSize: '14px',
    borderColor: theme.palette.sea.main,
    '&:hover': {
      borderColor: theme.palette.sea.light,
    },
  },
}))(SecondaryButton);

interface IAtomlerButtonOurProps {
  size: 'normal' | 'small' | 'mini';
  variant:
    | 'primary'
    | 'primaryAlt'
    | 'black'
    | 'pearl'
    | 'secondary'
    | 'secondaryGreen'
    | 'negative'
    | 'neutral'
    | 'positive';
}

interface IAtomlerButton extends MuiButtonProps {
  ourProps: IAtomlerButtonOurProps;
}

const useStyles = (props: IAtomlerButtonOurProps) =>
  makeStyles((theme) => {
    let sizeStyles = {};
    let variantStyles = {};
    props.size = props.size || 'normal';
    props.variant = props.variant || 'primary';

    switch (props.size) {
      case 'normal':
        sizeStyles = {
          fontSize: '20px',
          paddingTop: theme.spacing(1) * 2.5,
          paddingBottom: theme.spacing(1) * 2.5,
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        };
        break;
      case 'small':
        sizeStyles = {
          fontSize: '16px',
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
        };
        break;
      case 'mini':
        sizeStyles = {
          fontSize: '14px',
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
        };
        break;
    }

    switch (props.variant) {
      case 'primary':
        variantStyles = {
          backgroundColor: theme.palette.fire.main,
          color: theme.palette.white.main,
          '&:hover': {
            backgroundColor: theme.palette.fire.light,
          },
        };
        break;
      case 'primaryAlt':
        variantStyles = {
          backgroundColor: theme.palette.sea.main,
          color: theme.palette.white.main,
          '&:hover': {
            backgroundColor: theme.palette.sea.light,
          },
        };
        break;
      case 'secondary':
        variantStyles = {
          border: '2px',
          borderColor: theme.palette.black.main,
          borderStyle: 'solid',
          color: theme.palette.black.main,
          paddingTop: theme.spacing(2) - 2,
          paddingBottom: theme.spacing(2) - 2,
          '&.Mui-disabled': {
            color: theme.palette.sand.dark,
            borderColor: theme.palette.sand.dark,
            backgroundColor: 'transparent',
          },
          '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.black.light,
            borderColor: theme.palette.black.light,
            textDecoration: 'none',
          },
        };
        break;
      case 'secondaryGreen':
        variantStyles = {
          border: '2px',
          borderColor: theme.palette.sea.main,
          borderStyle: 'solid',
          color: theme.palette.sea.main,
          paddingTop: theme.spacing(2) - 2,
          paddingBottom: theme.spacing(2) - 2,
          fontWeight: 'bold',
          '&.Mui-disabled': {
            color: theme.palette.sand.dark,
            borderColor: theme.palette.sand.dark,
            backgroundColor: 'transparent',
          },
          '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.sea.light,
            borderColor: theme.palette.sea.light,
            textDecoration: 'none',
          },
        };
        break;
      case 'pearl':
        variantStyles = {
          backgroundColor: theme.palette.pearl.main,
          color: theme.palette.black.main,
          '&:hover': {
            backgroundColor: theme.palette.pearl.dark,
          },
        };
        break;
      case 'negative':
        variantStyles = {
          backgroundColor: theme.palette.negative.main,
          color: theme.palette.white.main,
          '&:hover': {
            backgroundColor: theme.palette.negative.light,
          },
        };
        break;
    }

    return {
      root: {
        whiteSpace: 'nowrap',
        borderRadius: 100,
        textTransform: 'none',
        fontWeight: 400,
        fontFamily:
          'Manrope, "Roboto", "Franklin Gothic Medium", Tahoma, sans-serif',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        lineHeight: 'unset',
        '&.Mui-disabled': {
          backgroundColor: theme.palette.pearl.dark,
          color: theme.palette.sand.dark,
        },
        ...sizeStyles,
        ...variantStyles,
      },
    };
  });

export const AtomlerButton: FunctionComponent<IAtomlerButton> = (props) => {
  const { ourProps, ...restProps } = props;
  const classes = useStyles(ourProps)();

  return <MuiButton classes={classes} {...restProps} />;
};

export default MuiButton;
