﻿import { IDBItem } from '@@types';
export { default as actions } from './actions';

export interface IItemMatch {
  score: number;
  item: IDBItem;
}

export interface IModel {
  fetchingMatches: boolean;
  matches: IItemMatch[];
  externalMatches: IItemMatch[];
}

export const defaultState: IModel = {
  fetchingMatches: false,
  matches: [],
  externalMatches: [],
};

export const state = () => {
  return {
    ...defaultState,
  };
};

export default state;
