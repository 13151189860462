﻿let cachedIsTouchDevice: boolean;
const isClient = typeof window !== 'undefined';

export const isTouchDevice = () => {
  if (!isClient) {
    return false;
  }
  if (cachedIsTouchDevice !== undefined) {
    return cachedIsTouchDevice;
  }

  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = (mediaQuery: string) => {
    return window.matchMedia(mediaQuery).matches;
  };

  if (
    'ontouchstart' in window ||
    // eslint-disable-next-line no-undef
    navigator.maxTouchPoints > 0 ||
    (navigator as unknown as { msMaxTouchPoints: number }).msMaxTouchPoints > 0
  ) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(
    ''
  );
  return (cachedIsTouchDevice = mq(query));
};
