﻿import './polyfill';
import router from '../shared/routes/router';
import './style/main.scss';
import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: 'https://528f4c3f114240ffa12c3bd4d523de54@sentry.io/1157092',
});

if (process.env.NODE_ENV === 'development') {
  console.log('in dev');
  if (module.hot) {
    console.log('module hot exists');
    module.hot.accept();
  }
}

router();
