// Types
// import {IStore} from 'shared/unistore';
import { IModel, SnackbarSeverity } from './index';
import { BoundAction } from 'unistore';

export interface IActions {
  open: BoundAction;
  close: BoundAction;
}

export const close = ({ notifications }: { notifications: IModel }) => {
  return {
    notifications: {
      ...notifications,
      open: false,
    },
  };
};

export const open = (
  { notifications }: { notifications: IModel },
  message: string,
  severity: SnackbarSeverity = 'error'
) => {
  return {
    notifications: {
      ...notifications,
      open: true,
      message,
      severity,
    },
  };
};

const actions = () => ({
  open,
  close,
});

export default actions;
