﻿import { IItemMatch } from './';
import { BoundAction, Store } from 'unistore';
import { IStore } from 'shared/unistore';
import { defaultState } from './';
import { getMatches } from './../../api/matches';
import { getPrice } from 'api/price';
import { roundTwoDecimals } from '@@helpers/number-helper';
// import { matches as fakeMatches } from '../../containers/fakedata';

export interface IActions {
  reset: BoundAction;
  fetchItemMatches: BoundAction;
  fetchPriceEstimation: BoundAction;
}

const actions = (store: Store<IStore>) => {
  return {
    reset: () => {
      store.setState(
        {
          ...store.getState(),
          itemMatches: defaultState,
        },
        true
      );
    },
    fetchItemMatches: async ({ itemMatches }: IStore, itemId: string) => {
      try {
        const matchesForItem = await getMatches(itemId);
        const matchesWithData = matchesForItem?.data?.matches;
        // console.log('fakeMatches', fakeMatches.matches);
        // const matchesWithData = fakeMatches.matches;
        const externalMatchesWithData = matchesForItem?.data?.external_matches;

        const dataTransformer = (data): IItemMatch[] => {
          if (data) {
            return data.map((i) => {
              return {
                score: i[1],
                item: i[2],
              };
            });
          }
          return null;
        };

        const matches = dataTransformer(matchesWithData);
        const externalMatches = dataTransformer(externalMatchesWithData);

        store.setState({
          ...store.getState(),
          itemMatches: {
            ...itemMatches,
            matches,
            externalMatches,
          },
        });
      } catch (error) {
        console.log('Error getting matches for item', error);
      }
    },
    fetchPriceEstimation: async ({ itemView }: IStore) => {
      if (!itemView?.data?.attr?.kgPrice) {
        return;
      }

      store.setState({
        itemView: {
          ...itemView,
          fetchingPriceEstimate: true,
        },
      });

      try {
        const response = await getPrice({
          plasticType: itemView.data?.attr?.plasticType,
          grade: itemView.data?.attr?.grade,
          color: itemView.data?.attr?.color,
          recycled: true,
          user_price: itemView.data?.attr?.kgPrice,
        });

        if (response?.data) {
          const priceEstimate = response.data?.priceEstimate
            ? {
                ...response.data.priceEstimate,
                priceDiff: roundTwoDecimals(
                  response.data.priceEstimate.priceDiff
                ),
              }
            : {};

          return {
            itemView: {
              ...itemView,
              priceEstimate,
              fetchingPriceEstimate: false,
            },
          };
        }
        return {
          itemView: {
            ...itemView,
            priceEstimate: null,
            fetchingPriceEstimate: false,
          },
        };
      } catch (error) {
        store.setState({
          itemView: {
            ...itemView,
            fetchingPriceEstimate: false,
          },
        });

        console.log('Price estimation error', error);
        throw new Error('Price estimation error' + error);
      }
    },
  };
};

export default actions;
