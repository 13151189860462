﻿import { object, string } from 'yup';
import { IModel } from './index';

const messageSchema = object().shape({
  message: string().required().max(300),
});

export const isMessageValid = (contactSupplier: IModel) => {
  return messageSchema.isValidSync({
    message: contactSupplier.message,
  });
};

export default {
  isMessageValid,
};
