import { IModel } from './index';
import { object, string, ValidationError } from 'yup';

interface ISellValidationError {
  msg: string;
  prop: string;
}

export const itemValidationSchema = object().shape({
  plasticType: string().required('Plastic type is required'),
  grade: string().required('Grade is required'),
  color: string().required('Color is required'),
});

export const validate = (priceIndex: IModel): ISellValidationError[] => {
  let errors: ValidationError;
  try {
    itemValidationSchema.validateSync(priceIndex.newItem, {
      abortEarly: false,
    });
  } catch (err) {
    errors = err;
  }

  return errors
    ? errors.inner.map((e) => ({
        msg: e.message,
        prop: e.path,
      }))
    : [];
};
