﻿import { IStringDict, IReadonlyMap } from './';

export const mapValueWithLabel = (
  values: IStringDict,
  labelsMap: IReadonlyMap<string, string>
) => {
  return Object.keys(values).map((value) => {
    return {
      value: values[value],
      label: labelsMap.get(values[value]),
    };
  });
};
