﻿import { Store } from 'unistore';
import { IStore } from 'shared/unistore'; // initStore
import { fetchUser, updateUser, updatePasswordFromLoginLink } from 'api/user';
import { signUp as userSignUp } from 'api/user';
import { getCompanyFromUser } from '@@helpers/user-helper';
import { validateNewPassword } from '@@helpers/validator-helper';
import { defaultState } from './index';

export const steps = {
  // CONFIRM: 'confirm',
  DONE: 'done',
  FORM: 'form',
};

export const actions = (store: Store<IStore>) => {
  const profileSetState = (newProps) => {
    store.setState({
      profile: {
        ...store.getState().profile,
        ...newProps,
      },
    });
  };

  const profileSetStatus = (message: string, secondsToLive = 3) => {
    const millisecondsToLive = secondsToLive * 1000;
    profileSetState({
      statusMessage: message,
    });

    setTimeout(() => {
      profileSetState({
        statusMessage: null,
      });
    }, millisecondsToLive);
  };

  const resetPasswordFormStatus = () => {
    // self.passwordForm.statusMessage = null;
    profileSetState({
      passwordForm: {
        ...store.getState().profile.passwordForm,
        statusMessage: null,
      },
    });
  };

  const setPasswordFormStatus = (message: string, secondsToLive = 3) => {
    const millisecondsToLive = secondsToLive * 1000;
    profileSetState({
      passwordForm: {
        ...store.getState().profile.passwordForm,
        statusMessage: message,
      },
    });

    setTimeout(() => {
      resetPasswordFormStatus();
    }, millisecondsToLive);
  };

  return {
    fetch: async ({ profile }: IStore) => {
      profileSetState({
        fetching: true,
        statusMessage: null,
      });

      try {
        const response = await fetchUser();
        if (response.data && response.data.user) {
          // TODO: Fix this
          const rUser = response.data.user;
          const company = getCompanyFromUser(rUser);
          delete rUser.companies;
          if (company) {
            rUser.company = company;
          }

          profileSetState({
            form: rUser,
          });
        }
      } catch (error) {
        profileSetState({
          errorMessage: 'Fetching profile failed',
        });
      }
      profileSetState({
        fetching: false,
      });
    },
    updateFormProp: ({ profile }: IStore, name: string, value: unknown) => {
      profileSetState({
        form: {
          ...store.getState().profile.form,
          [name]: value,
        },
      });
    },
    updatePasswordFormProp: (
      { profile }: IStore,
      name: string,
      value: unknown
    ) => {
      profileSetState({
        passwordForm: {
          ...store.getState().profile.passwordForm,
          [name]: value,
        },
      });
    },
    reset: ({ profile }: IStore) => {
      store.setState({
        profile: defaultState,
      });
    },
    resetStatus: ({ profile }: IStore) => {
      profileSetState({
        statusMessage: null,
      });
    },
    setStatus: ({ profile }: IStore, message: string, secondsToLive = 3) => {
      profileSetStatus(message, secondsToLive);
    },
    register: async ({ profile }: IStore) => {
      profileSetState({
        errors: [],
        serverError: null,
      });

      // const isValid = validate form

      try {
        const response = await userSignUp(store.getState().profile.form);

        profileSetState({
          step: steps.DONE,
        });
        // self.credentials = response.data;
      } catch (error) {
        console.log('error', error);
        profileSetState({
          serverError: error.response.data,
        });
      }
    },
    update: async ({ profile }: IStore) => {
      profileSetState({
        errors: [],
        serverError: null,
      });

      const validationErrors = validateNewPassword({
        new_password: profile.form.new_password,
        password_verify: profile.form.password_verify,
      });

      if (!validationErrors.isValid) {
        const errors = validationErrors.errors.map((err) => {
          return {
            errors: [err.message],
            message: err.message,
            path: err.path,
          };
        });
        profileSetState({
          errors,
        });
        return;
      }

      profileSetState({
        updating: true,
      });

      try {
        const response = await updateUser(profile.form);
        // self.credentials = response.data;
        profileSetStatus('Profile updated');
        profileSetState({
          form: {
            ...store.getState().profile.form,
            new_password: '',
            password_verify: '',
            password: '',
          },
        });
      } catch (error) {
        console.log('error', error);
        // self.errorMessage = 'Authentication failed';
        // TODO Handle unauthorized
        profileSetState({
          serverError: error.response.data,
        });
      }

      profileSetState({
        updating: false,
      });
    },
    updatePasswordForm: async ({ profile }: IStore, loginLinkData) => {
      profileSetState({
        passwordForm: {
          ...store.getState().profile.passwordForm,
          errors: [],
          serverError: null,
        },
      });

      const validationErrors = validateNewPassword({
        new_password: profile.passwordForm.new_password,
        password_verify: profile.passwordForm.password_verify,
      });

      if (!validationErrors.isValid) {
        const errors = validationErrors.errors;
        profileSetState({
          passwordForm: {
            ...store.getState().profile.passwordForm,
            errors: errors,
          },
        });
        return;
      }

      profileSetState({
        passwordForm: {
          ...store.getState().profile.passwordForm,
          updating: true,
        },
      });
      const data = {
        new_password: profile.passwordForm.new_password,
        password_verify: profile.passwordForm.password_verify,
        loginLink: loginLinkData,
      };
      try {
        const response = await updatePasswordFromLoginLink(data);
        // self.credentials = response.data;
        setPasswordFormStatus('Password updated');
      } catch (error) {
        console.log('error', error);
        // self.errorMessage = 'Authentication failed';
        // TODO Handle unauthorized
        profileSetState({
          passwordForm: {
            ...store.getState().profile.passwordForm,
            serverError: error.response.data,
          },
        });
      }
      setPasswordFormStatus('Password updated');

      profileSetState({
        passwordForm: {
          ...store.getState().profile.passwordForm,
          isPasswordChanged: true,
          new_password: '',
          password_verify: '',
          updating: false,
        },
      });
    },
    setPasswordFormStatus: (
      { profile }: IStore,
      message: string,
      secondsToLive = 3
    ) => {
      setPasswordFormStatus(message, secondsToLive);
    },
    resetPasswordFormStatus: resetPasswordFormStatus,
  };
};

export default actions;
