﻿import { IReadonlyMap } from './index';
import { mapValueWithLabel } from './mappers';
// import { getOptionsLabelFromValue, IReadonlyMap, IStringDict } from './index';
export enum ImageRequiredValues {
  Yes = 'yes',
}

const imageRequiredLabels: IReadonlyMap<ImageRequiredValues, string> = new Map([
  [ImageRequiredValues.Yes, 'Yes'],
]);

export const imageRequiredOptions = mapValueWithLabel(
  ImageRequiredValues,
  imageRequiredLabels
);

export const imageRequiredValueArray = imageRequiredOptions.map(
  (item) => item.value
);

export const getImageRequiredOptions = (newItem) => {
  return imageRequiredOptions.map((item) => {
    return {
      ...item,
      checked: newItem.attr.imageRequired === item.value,
    };
  });
};

// export const getImageRequiredOptionsLabel = (value: ImageRequiredValues) =>
//   getOptionsLabelFromValue(imageRequiredOptions, value);
