﻿import { h, FunctionComponent } from 'preact';
import { makeStyles } from '@@helpers/material-ui/core/styles';
import {
  TextField as MuiTextField,
  TextFieldProps,
} from '@@helpers/material-ui/core';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.white.main
//   }
// }));
const useTextFieldStyles = makeStyles((_theme) => ({
  root: {
    borderRadius: 8,
    fontFamily: 'Manrope',
    // paddingLeft: theme.spacing(1)/2,
  },
}));

// const useOutlinedStyles = makeStyles((theme) => ({
//   root: {
//     "& $notchedOutline": {
//       borderColor: "red"
//     },
//   },
//   input: {

//   }
// }));
const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    fontSize: theme.typography.body2.fontSize,
    fontFamily: 'Manrope',
    borderRadius: 8,
    backgroundColor: theme.palette.pearl.main,
    paddingLeft: theme.spacing(1) / 2,
    paddingRight: theme.spacing(1) / 2,
    color: theme.palette.black.main,
    '&:active': {
      backgroundColor: theme.palette.white.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.white.main,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.pearl.dark,
      borderColor: theme.palette.pearl.dark,
      borderRadius: 8,
      '& $notchedOutline': {
        borderColor: theme.palette.pearl.dark,
        borderRadius: 8,
      },
    },

    '& $notchedOutline': {
      borderColor: 'transparent',
      borderWidth: 2,
    },
    // '&.Mui-error $notchedOutline': {
    //   borderColor: 'hotpink',
    //   borderWidth: 2,
    // },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.pearl.dark,
    },
    '&$focused $notchedOutline': {
      borderColor: theme.palette.pearl.dark,
      // backgroundColor: 'transparent'
    },
  },
  input: {
    fontSize: theme.typography.body2.fontSize,
    paddingLeft: theme.spacing(1) / 2,
    height: 'initial',
  },
  focused: {
    backgroundColor: theme.palette.white.main,
  },
  notchedOutline: {},
}));

const useLabelStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    fontSize: theme.typography.body2.fontSize,
    fontFamily: 'Manrope',
    '&.Mui-error': {
      color: theme.palette.black.main,
    },
    '&.Mui-focused': {
      color: theme.palette.black.main,
    },
  },
  // focused: {
  //   color: theme.palette.fire.main
  // }
}));

export const TextField: FunctionComponent<TextFieldProps> = (props) => {
  const classes = useTextFieldStyles();
  const outLinedInputStyles = useOutlinedInputStyles();
  const labelStyles = useLabelStyles();

  const newInputProps = props.InputProps
    ? {
        ...props.InputProps,
        classes: outLinedInputStyles,
      }
    : {
        classes: outLinedInputStyles,
      };

  const newInputLabelProps = props.InputLabelProps
    ? {
        ...props.InputLabelProps,
        classes: labelStyles,
      }
    : {
        classes: labelStyles,
      };

  return (
    <MuiTextField
      className={classes.root}
      size="small"
      variant="outlined"
      {...props}
      InputProps={newInputProps}
      InputLabelProps={newInputLabelProps}
    />
  );
};

export default TextField;
