import { ComponentConstructor, h } from 'preact';
import { Suspense, lazy } from 'preact/compat';
import { default as PageLoading } from './PageLoading';
const suspenseIt = (TheComponent: ComponentConstructor<unknown, unknown>) => {
  return (props: Record<string, unknown>) => {
    return (
      <Suspense fallback={<PageLoading {...props} />}>
        <TheComponent {...props} />
      </Suspense>
    );
  };
};

// export { default as Home } from './Home';
// export { default as Item } from './Item';
export const Home = suspenseIt(lazy(() => import('./Home')));
export const Item = suspenseIt(lazy(() => import('./Item')));

export const EditItem = suspenseIt(lazy(() => import('./EditItem2')));
export const Profile = suspenseIt(lazy(() => import('./Profile')));

export const Sell = suspenseIt(lazy(() => import('./Sell')));
export const SignUp = suspenseIt(lazy(() => import('./SignUp')));
export const Terms = suspenseIt(lazy(() => import('./Terms')));
export const ResultList = suspenseIt(lazy(() => import('./ResultList')));
export const Privacy = suspenseIt(lazy(() => import('./Privacy')));
export const Orders = suspenseIt(lazy(() => import('./Orders')));
export const LinkLogin = suspenseIt(lazy(() => import('./LinkLogin')));
export const Messages = suspenseIt(lazy(() => import('./Messages')));
export const Error = suspenseIt(lazy(() => import('./Error')));
export const Buy = suspenseIt(lazy(() => import('./Buy')));
export const SellingTool = suspenseIt(lazy(() => import('./SellingTool')));
export const Matcher = suspenseIt(lazy(() => import('./Matcher')));
export const Users = suspenseIt(lazy(() => import('./Users')));
export const MaterialTest = suspenseIt(lazy(() => import('./MaterialTest')));
export const RequestSample = suspenseIt(lazy(() => import('./RequestSample')));
export const OrderSummary = suspenseIt(lazy(() => import('./OrderSummary')));
export const OrderResponse = suspenseIt(lazy(() => import('./OrderResponse')));
export const Invoices = suspenseIt(lazy(() => import('./Invoices')));
export const ItemExternal = suspenseIt(lazy(() => import('./ItemExternal')));
export const FindExternal = suspenseIt(lazy(() => import('./FindExternal')));
export const Log = suspenseIt(lazy(() => import('./Log')));
export const Icons = suspenseIt(lazy(() => import('./Icons')));
export const Styles = suspenseIt(lazy(() => import('./Styles')));
export const Logistics = suspenseIt(lazy(() => import('./Logistics')));
export const Store = suspenseIt(lazy(() => import('./Store')));
export const Inventory = suspenseIt(lazy(() => import('./Inventory')));
export const Map = suspenseIt(lazy(() => import('./Map')));
export const SirMatchAlot = suspenseIt(lazy(() => import('./SirMatchAlot')));
export const AdminInvoices = suspenseIt(lazy(() => import('./AdminInvoices')));
export const AdminCoupons = suspenseIt(lazy(() => import('./AdminCoupons')));
export const ShippingTerminal = suspenseIt(
  lazy(() => import('./ShippingTerminal'))
);
export const ShippingTerminalResponse = suspenseIt(
  lazy(() => import('./ShippingTerminalResponse'))
);
export const LabTerminal = suspenseIt(lazy(() => import('./LabTerminal')));
export const LabTerminalRegister = suspenseIt(
  lazy(() => import('./LabTerminalRegister'))
);
export const LabTerminalDatasheet = suspenseIt(
  lazy(() => import('./LabTerminalDatasheet'))
);
export const MaterialQualityDatasheet = suspenseIt(
  lazy(() => import('./MaterialQualityDatasheet'))
);
export const LabTerminalCompanyList = suspenseIt(
  lazy(() => import('./LabTerminalCompanyList'))
);
export const MaterialListingPolicy = suspenseIt(
  lazy(() => import('./MaterialListingPolicy'))
);
export const QualityTestTerms = suspenseIt(
  lazy(() => import('./QualityTestTerms'))
);
export const ActiveSales = suspenseIt(lazy(() => import('./ActiveSales')));

// export const Item = (props) => {
//   return (
//     <Suspense fallback={<div>loading...</div>}>
//       <IItem  { ...props }/>
//     </Suspense>
//   )
// }

// export { default as EditItem } from './EditItem2';
// export { default as Profile } from './Profile';
// export { default as Sell } from './Sell';
// // export { default as Selling } from './Selling';
// export { default as SignUp } from './SignUp';
// export { default as Terms } from './Terms';
// export { default as ResultList } from './ResultList';
// export { default as Privacy } from './Privacy';
// export { default as Orders } from './Orders';
// // export { default as Sold } from './Sold';
// export { default as LinkLogin } from './LinkLogin';
// export { default as Messages } from './Messages';
// // export { default as Message } from './Message';
// export { default as Error } from './Error';
// export { default as Buy } from './Buy';
// // export { default as Buying } from './Buying';
// // export { default as Match } from './Match';
// export { default as SellingTool } from './SellingTool';
// export { default as Matcher } from './Matcher';
// export { default as Users } from './Users';
// export { default as MaterialTest } from './MaterialTest';
// export { default as RequestSample } from './RequestSample';
// export { default as OrderSummary } from './OrderSummary';
// export { default as OrderResponse } from './OrderResponse';
// export { default as Invoices } from './Invoices';
// export { default as ItemExternal } from './ItemExternal';
// export { default as FindExternal } from './FindExternal';
// export { default as Log } from './Log';
// export { default as Icons } from './Icons';
// export { default as Styles } from './Styles';
// export { default as Logistics } from './Logistics';
// export { default as Store } from './Store';
// export { default as Inventory } from './Inventory';
// // export { default as CompanyMap } from './CompanyMap';
// export { default as Map } from './Map';
// export { default as SirMatchAlot } from './SirMatchAlot';
// export { default as AdminInvoices } from './AdminInvoices';
// export { default as AdminCoupons } from './AdminCoupons';
// export { default as ShippingTerminal } from './ShippingTerminal';
// export { default as ShippingTerminalResponse } from './ShippingTerminalResponse';
// export { default as LabTerminal } from './LabTerminal';
// export { default as LabTerminalRegister } from './LabTerminalRegister';
// export { default as LabTerminalDatasheet } from './LabTerminalDatasheet';
// export { default as MaterialQualityDatasheet } from './MaterialQualityDatasheet';
// export { default as LabTerminalCompanyList } from './LabTerminalCompanyList';
// export { default as MaterialListingPolicy } from './MaterialListingPolicy';
// export { default as QualityTestTerms } from './QualityTestTerms';
// export { default as ActiveSales } from './ActiveSales';
// export { default as SimpleSell } from './SimpleSell';
// export { default as Confirm } from './Confirm';
// export { default as PriceIndex } from './PriceIndex';
