﻿import { Store } from 'unistore';
import { object, string } from 'yup';
import { IStore } from 'shared/unistore'; // initStore
import { ICredentials, IModel } from './';
import {
  login as userLogin,
  logout as userLogout,
  linkLogin as userLinkLogin,
  sendlinkLogin as userSendLinkLogin,
  insertOTPDetails,
  checkOTPDetails,
  deleteOTPData,
} from 'api/user';

import { login as serviceProvider_Login } from 'api/serviceProvider';
import { ILinkLoginData } from '@@types/user';
// import StepOTP from '../../components/OTPForm';
// import { isVoidExpression } from 'typescript';
interface IOnlyAuth {
  auth: IModel;
}

export const showLogin = ({ auth }: IStore): IOnlyAuth => {
  return {
    auth: {
      ...auth,
      isLoginShown: true,
    },
  };
};
const hideLogin = ({ auth }: IStore): IOnlyAuth => {
  return {
    auth: {
      ...auth,
      isLoginShown: false,
      loginMessage: null,
      isLoginLinkSent: false,
      isLoginEmailValid: false,
      isLoginLinkEmailValid: false,
    },
  };
};

export interface ILoginData {
  username: string;
  password: string;
}

const emailValidationRules = object().shape({
  email: string()
    .email('Email is not valid')
    .required('Enter your email address'),
});

const getReturnUrlParam = () => {
  const url = new URL(window.location.href);
  return url.searchParams.get('returnUrl');
};

// This is basically a check to see if the login modal is open upon page initialization.
// We have it to "pop" the modal from external urls, such as the wix site.
const isSigningIn = () => {
  return window.location.search.indexOf('signIn=true') > -1;
};

export const standaloneLogout = async (
  { auth }: IStore,
  store: Store<IStore>
) => {
  store.setState({
    auth: {
      ...auth,
      fetching: true,
    },
  });

  try {
    await userLogout();
  } catch (error) {
    console.log('logout error', error);
    // TODO Handle unauthorized
  }

  store.setState({
    auth: {
      ...store.getState().auth,
      fetching: false,
      credentials: null,
      loginLinkCredentials: null,
    },
  });
};

const updateCredentials = (
  store: Store<IStore>,
  newCredentials: ICredentials
) => {
  store.setState({
    auth: {
      ...store.getState().auth,
      credentials: newCredentials,
    },
  });
};

export const actions = (store: Store<IStore>) => ({
  showLogin,
  hideLogin,
  setCredentials: async (
    _state: IStore,
    credentials: ICredentials
  ): Promise<void> => {
    updateCredentials(store, credentials);
  },
  login: async (
    state: IStore,
    formData: ILoginData,
    redirectUrl: string,
    serviceProviderLogin: boolean
  ): Promise<void> => {
    const { auth } = state;
    store.setState({
      auth: {
        ...auth,
        errorMessage: null,
        fetching: true,
      },
    });

    try {
      let response;
      if (serviceProviderLogin) {
        response = await serviceProvider_Login({
          email: formData.username,
          password: formData.password,
        });
      } else {
        response = await userLogin(formData);
      }
      store.setState({
        auth: {
          ...store.getState().auth,
          credentials: { ...response.data },
          loginLinkCredentials: null,
        },
      });

      store.setState(hideLogin(store.getState()));
      const returnUrlParam = getReturnUrlParam();

      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else if (returnUrlParam) {
        window.location.href = returnUrlParam;
      } else if (window.location.pathname === '/sign-up') {
        if (isSigningIn()) {
          // If you change anything in here, make sure the "Sign in"-link
          // still works from the wix Atomler site.
          window.location.href = 'https://market.atomler.com';
        }
        // else {
        //     window.location.href = 'https://www.atomler.com/thank-you-for-signing-up';
        // }
      } else {
        window.location.reload();
      }
    } catch (error) {
      console.log('error', error);
      store.setState({
        auth: {
          ...store.getState().auth,
          errorMessage: 'Authentication failed',
          fetching: false,
        },
      });
    }
    store.setState({
      auth: {
        ...store.getState().auth,
        fetching: false,
      },
    });
  },
  // logout: async () => {
  // },
  reset: ({ auth }: IStore) => {
    return {
      auth: {
        ...auth,
        fetching: false,
        errorCode: null,
        errorMessage: null,
        loginMessage: null,
      },
    };
  },
  validateLoginEmail: ({ auth }: IStore, email: string) => {
    // TODO: Helper
    const vresult = emailValidationRules.isValidSync({ email });
    return {
      auth: {
        ...auth,
        isLoginEmailValid: vresult,
      },
    };
  },
  validateLoginLinkEmail: ({ auth }: IStore, email: string) => {
    // TODO: Helper
    const vresult = emailValidationRules.isValidSync({ email });
    return {
      auth: {
        ...auth,
        isLoginLinkEmailValid: vresult,
      },
    };
  },
  logout: async ({ auth }: IStore) => {
    store.setState({
      auth: {
        ...auth,
        fetching: true,
      },
    });

    try {
      await userLogout();
    } catch (error) {
      console.log('logout error', error);
      // TODO Handle unauthorized
    }

    store.setState({
      auth: {
        ...store.getState().auth,
        fetching: false,
        credentials: null,
        loginLinkCredentials: null,
      },
    });
  },
  sendLinkLogin: async ({ auth }: IStore, email: string) => {
    // TODO: Helper
    store.setState({
      auth: {
        ...auth,
        fetching: true,
      },
    });

    try {
      await userSendLinkLogin(email);
      store.setState({
        auth: {
          ...store.getState().auth,
          isLoginLinkSent: true,
        },
      });
    } catch (error) {
      console.log('sendLoginLinkError', error);
      // TODO Handle unauthorized
    }

    store.setState({
      auth: {
        ...store.getState().auth,
        fetching: false,
      },
    });
  },
  linkLogin: async ({ auth }: IStore, linkLoginInfo: ILinkLoginData) => {
    // TODO: Helper
    store.setState({
      auth: {
        ...auth,
        fetching: true,
        errorMessage: null,
      },
    });

    try {
      const response = await userLinkLogin(linkLoginInfo);
      store.setState({
        auth: {
          ...store.getState().auth,
          credentials: response.data,
          loginLinkCredentials: {
            linkId: linkLoginInfo.linkId,
            token: linkLoginInfo.token,
          },
        },
      });
    } catch (error) {
      console.log('error', error);

      store.setState({
        auth: {
          ...store.getState().auth,
          errorMessage: 'Authentication failed',
        },
      });
      // TODO Handle unauthorized
    }

    store.setState({
      auth: {
        ...store.getState().auth,
        fetching: false,
      },
    });
  },
  generateOTP: async ({ auth }: IStore, isRegenerated: boolean) => {
    let response = null;
    try {
      response = await insertOTPDetails(isRegenerated);
      store.setState({
        auth: {
          ...store.getState().auth,
          errorCode: response.data.errorCode,
          errorMessage: response.data.message,
          isOTPGenerated: true,
        },
      });
    } catch (error) {
      store.setState({
        auth: {
          ...store.getState().auth,
          errorCode: 404,
          errorMessage:
            'Something went wrong on our side when processing your OTP, please try again!',
        },
      });
    }
    console.log(auth);
  },
  checkOTP: async ({ auth }: IStore, OTPValue: number) => {
    try {
      const response = await checkOTPDetails(OTPValue);
      if (response.data.isVerified) {
        store.setState({
          auth: {
            ...store.getState().auth,
            credentials: {
              ...store.getState().auth.credentials,
              isVerified: true,
            },
            isVerified: response.data.isVerified,
            errorCode: response.data.errorCode,
            errorMessage: response.data.message,
          },
        });
      } else {
        store.setState({
          auth: {
            ...store.getState().auth,
            credentials: {
              ...store.getState().auth.credentials,
              isVerified: false,
            },
            isVerified: response.data.isVerified,
            errorCode: response.data.errorCode,
            errorMessage: response.data.message,
          },
        });
      }
    } catch (error) {
      store.setState({
        auth: {
          ...store.getState().auth,
          errorCode: 404,
          errorMessage:
            'Something went wrong on our side when processing your OTP, please try again!',
        },
      });
    }
    console.log(auth);
  },
  deleteOTP: async ({ auth }: IStore, OTPData: Record<string, unknown>) => {
    try {
      await deleteOTPData(OTPData);
    } catch (error) {
      store.setState({
        auth: {
          ...store.getState().auth,
          errorCode: 404,
          errorMessage:
            'Something went wrong on our side when processing your OTP, please try again!',
        },
      });
    }
    console.log(auth);
  },
});

export default actions;
