﻿import { IFile } from '@@types';
import { ItemPlasticTypeValues } from '@@helpers/item';

export interface IMyItem {
  _key: string;
  type: string;
  title: string;
  userId: string;
  attr?: {
    files?: IFile[];
    plasticType: ItemPlasticTypeValues;
    samplesAvailable: 'yes' | 'no';
  };
}

export interface IModel {
  fetching: boolean;
  items: IMyItem[];
}

export const defaultState: IModel = {
  fetching: false,
  items: [],
};

export const state = (): IModel => {
  return {
    ...defaultState,
  };
};

export default state;
