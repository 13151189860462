﻿import { TypographyVariant } from '@@helpers/material-ui/core/styles';
export * from './item';
export * from './order';
export * from './user';
export * from './db';
export * from './materialTest';
export * from './address';
export * from './date';
export * from './validator';
export * from './invoice';
export * from './emailTemplate';
export * from './price';
export * from './logistics';
export * from './company';
export * from './message';
export * from './serviceFee';
export * from './coupon';
export * from './shippingRequestResponses';
export * from './customOffer';
export * from './serviceProvider';
export * from './tracking';
export * from './materialQuality';
export * from './fonts';
export * from './materialQualitySample';
export * from './activeSales';

export interface IHTMLInputEvent extends Event {
  currentTarget: HTMLInputElement;
  target: HTMLInputElement;
  detail?: any;
}

export enum YesNoUknown {
  Yes = 'yes',
  No = 'no',
  Unknown = 'unknown',
}

export interface IValidationError {
  msg: string;
  prop: string;
}

type GridSize =
  | boolean
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 'auto';

interface IGridSizes {
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
}

export interface IDescriptionListGridSize {
  key: IGridSizes;
  value: IGridSizes;
}

export interface IDescriptionListItem {
  label: string;
  value: string | string[];
  classes?: string;
  typographyVariant?: TypographyVariant;
  hasTopDivider?: boolean;
  hasBottomDivider?: boolean;
  valuesAreNumbers?: boolean;
  gridSize?: IDescriptionListGridSize;
  verticalAlign?: 'center' | 'flex-end' | 'flex-start';
  LabelComponent?: any;
  ValueComponent?: any;
}
