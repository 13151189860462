﻿import { createTheme } from '@@helpers/material-ui/core/styles';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    black: Palette['primary'];
    pearl: Palette['primary'];
    white: Palette['primary'];
    sand: Palette['primary'];
    fire: Palette['primary'];
    sea: Palette['primary'];
    positive: Palette['primary'];
    neutral: Palette['primary'];
    negative: Palette['primary'];
  }
  interface PaletteOptions {
    black: PaletteOptions['primary'];
    pearl: PaletteOptions['primary'];
    white: PaletteOptions['primary'];
    sand: PaletteOptions['primary'];
    fire: PaletteOptions['primary'];
    sea: PaletteOptions['primary'];
    positive: PaletteOptions['primary'];
    neutral: PaletteOptions['primary'];
    negative: PaletteOptions['primary'];
  }
}

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    smmd: true;
    md: true;
    lg: true;
    xl: true;
  }
}
// Colors
// =============================
// Black #282828
// Light black #5C5C5C

// White #FFFFFF

// Pearl aka Dark white: #F6F6F5
// Dark pearl: #EEEEEB

// Sand: #E3E1DD
// Dark sand: #D1CFC9

// Fire: #DC7A65
// Light fire: #E78772

// Sea: #3C6568

// Positive #71B881
// Light positive #8EC69A

// Neutral #E9A96F
// Light neutral #EDBA8B

// Negative #D35B5B
// Light negative #DC7C7C

// Fonts
// =============================

// "Statement"
// Mobile: Lora, medium, 54px
// Web: Lora, medium, 100px

// "Page title"
// Mobile: Lora, medium, 40px
// Web: Lora, medium, 64px

// H1
// Mobile: Lora, medium, 38px
// Web: Lora, medium, 56px

// H2
// Mobile: Manrope, SemiBold, 28px
// Web: Manrope, SemiBold, 36px

// H3
// Mobile: Manrope, SemiBold, 20px
// Web: Manrope, SemiBold, 24px

// Large paragraph
// Manrope, regular/bold, 18

// Medium paragraph
// Manrope, regular/bold, 16

// Small paragraph
// Manrope, regular/bold, 14

// A custom theme for this app
const palette = {
  primary: {
    main: '#19a974',
  },
  secondary: {
    main: '#357edd',
  },
  error: {
    main: '#D35B5B',
  },
  warning: {
    main: '#E9A96F',
  },
  success: {
    main: '#71B881',
  },
  background: {
    default: '#fff',
  },
  black: {
    main: '#282828',
    light: '#5C5C5C',
  },
  pearl: {
    main: '#F6F6F5',
    dark: '#EEEEEB',
  },
  white: {
    main: '#FFFFFF',
  },
  sand: {
    main: '#E3E1DD',
    dark: '#D1CFC9',
  },
  fire: {
    main: '#D88D63',
    light: '#E0A482',
  },
  sea: {
    main: '#3C6568',
    light: '#638486',
  },
  positive: {
    main: '#71B881',
    light: '#8EC69A',
  },
  neutral: {
    main: '#E9A96F',
    light: '#EDBA8B',
  },
  negative: {
    main: '#D35B5B',
    light: '#DC7C7C',
  },
  text: {
    primary: '#282828',
    secondary: '#5C5C5C',
  },
};

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      smmd: 860,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: palette,
  typography: {
    fontFamily: [
      'Manrope',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '1.8rem',
      fontWeight: 400,
      color: palette.black.main,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 400,
      color: palette.black.main,
    },
    h3: {
      fontSize: '1.2rem', // 19.2px
      fontWeight: 400,
      color: palette.black.main,
    },
    h4: {
      fontSize: '1rem', // 16px
      fontWeight: 600,
      color: palette.black.main,
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        boxSizing: 'content-box !important' as 'content-box', // Hack to override the css from normalize on input[type="text"] etc
      },
    },
    MuiRadio: {
      root: {
        '&$checked': {
          color: palette.sea.main,
        },
      },
      colorPrimary: {
        '&$checked': {
          color: palette.fire.main,
        },
      },
      colorSecondary: {
        '&$checked': {
          color: palette.sea.main,
        },
      },
    },
  },
});

export default theme;
