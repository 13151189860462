﻿import { getOptionsLabelFromValue } from './index';

export const shippingOptions = [
  { label: 'DHL', value: 'dhl' },
  { label: 'DDP', value: 'ddp' },
  { label: 'Pick up', value: 'pick_up' },
  { label: 'Other', value: 'other' },
];

export const getShippingOptionsLabel = (value: string) =>
  getOptionsLabelFromValue(shippingOptions, value);

// export const getShippingOptions = newItem => {
//     return shippingOptions.map(item => {
//         return {
//             ...item,
//             checked: newItem.hasShipping(item.value)
//         };
//     });
// };

// export const getCheckedShippingOptions = newItem => {
//     const options = getShippingOptions(newItem);
//     return options.reduce((acc, curr) => {
//         if (curr.checked) {
//             acc.push(curr);
//         }
//         return acc;
//     }, []);
// };
