﻿import { ICredentials } from 'shared/unistore/Auth';
import { AxiosResponse } from 'axios';
import { fetcher } from '@@helpers/fetch-helper';
import {
  API_LOGIN,
  API_LOGOUT,
  API_SIGN_UP,
  API_LOGIN_LINK,
  API_SEND_LOGIN_LINK,
  API_LOGIN_LINK_PASSWORD_CHANGE,
  API_USERS,
  API_USER_ID,
  API_USER_PAYMENT_PREFERENCES,
  API_DELETE_USER,
  API_UNDELETE_USER,
  API_POST_OTP_DATA,
  API_CHECK_OTP,
  API_DELETE_OTP,
  API_POST_FEEDBACK,
  API_ACCEPT_TERMS,
} from '@@constants/url';
import { ILinkLoginData } from '@@types/user';

export interface ILoginData {
  username: string;
  password: string;
}

export const login = (loginData: ILoginData) => {
  return fetcher.post(API_LOGIN, loginData, { withCredentials: true });
};

export const logout = () => {
  return fetcher.post(API_LOGOUT, {}, { withCredentials: true });
};

export const signUp = (userData) => {
  return fetcher.post(API_SIGN_UP, userData, { withCredentials: true });
};

export const fetchUser = () => {
  return fetcher.get(API_SIGN_UP, { withCredentials: true });
};

export const fetchUserPaymentPreferences = (): Promise<AxiosResponse<any>> => {
  return fetcher.get(API_USER_PAYMENT_PREFERENCES, { withCredentials: true });
};

export const updateUserPaymentPreferences = (
  paymentPreferences: string
): Promise<AxiosResponse<any>> => {
  return fetcher.put(
    API_USER_PAYMENT_PREFERENCES,
    { paymentPreferences },
    { withCredentials: true }
  );
};

export const fetchUsers = (): Promise<AxiosResponse<any>> => {
  return fetcher.get(API_USERS, { withCredentials: true });
};

export const deleteUser = (userId: string): Promise<AxiosResponse<any>> => {
  return fetcher.put(API_DELETE_USER, { userId }, { withCredentials: true });
};

export const undeleteUser = (userId: string): Promise<AxiosResponse<any>> => {
  return fetcher.put(API_UNDELETE_USER, { userId }, { withCredentials: true });
};

export const updateUser = (userData): Promise<AxiosResponse<any>> => {
  return fetcher.put(API_SIGN_UP, userData, { withCredentials: true });
};

export const updateUserPhoneNumber = (
  userId: string,
  phoneNumber: string
): Promise<AxiosResponse<any>> => {
  return fetcher.put(
    API_USER_ID(userId),
    { phoneNumber },
    { withCredentials: true }
  );
};

export const updatePasswordFromLoginLink = (
  userData
): Promise<AxiosResponse<any>> => {
  return fetcher.put(API_LOGIN_LINK_PASSWORD_CHANGE, userData, {
    withCredentials: true,
  });
};

export const linkLogin = (
  linkLoginData: ILinkLoginData
): Promise<AxiosResponse<any>> => {
  return fetcher.get(API_LOGIN_LINK(linkLoginData), { withCredentials: true });
};

export const sendlinkLogin = (
  username: string
): Promise<AxiosResponse<any>> => {
  return fetcher.get(API_SEND_LOGIN_LINK(username), { withCredentials: true });
};

export const insertOTPDetails = (
  isRegenerated: boolean
): Promise<AxiosResponse<any>> => {
  return fetcher.post(
    API_POST_OTP_DATA,
    { isRegenerated },
    { withCredentials: true }
  );
};

export const checkOTPDetails = (
  OTPValue: number
): Promise<AxiosResponse<any>> => {
  return fetcher.put(
    API_CHECK_OTP,
    { OTP: OTPValue },
    { withCredentials: true }
  );
};

export const deleteOTPData = (
  OTPData: Record<string, unknown>
): Promise<AxiosResponse<any>> => {
  return fetcher.put(API_DELETE_OTP, OTPData, { withCredentials: true });
};

export const postFeedback = (
  feedbackData: Record<string, unknown>
): Promise<AxiosResponse<any>> => {
  return fetcher.post(API_POST_FEEDBACK, feedbackData, {
    withCredentials: true,
  });
};

export const acceptTerms = (): Promise<
  AxiosResponse<{ auth: ICredentials; newAuth: ICredentials }>
> => {
  return fetcher.put(API_ACCEPT_TERMS, null, { withCredentials: true });
};
