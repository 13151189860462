﻿import { ItemAttributes } from '@@types';
import { IModel } from './index';
import { Attributes as DataSheetAttributes } from '@@helpers/attributes-helper';
import { PackagingDimensionsValues } from '@@helpers/item';
import { isShippingPackageWeightSelectedAndNotOther } from '@@helpers/packaging-helper';
// import { ItemPackagingValues, getFileVariant } from '@@helpers/item';
import { mfiRangeValidator } from '@@helpers/validator-helper';
import { object, string, number, ValidationError } from 'yup';
// import { safeLowerCase } from '@@helpers/string-helper';
// import {
//   getPackagingOptionsLabel,
// } from '@@helpers/item';

interface ISellValidationError {
  msg: string;
  prop: string;
}

// function convertNumber (x) {
//   const parsed = Number(x);
//   if (isNaN(parsed)) { return false }
//   return true;
// }

export const itemImageSchema = object().shape({
  fileId: string().required(),
  name: string().required(),
  size: number().required(),
  type: string().required(),
  variant: string().required(),
});

const editItemValidationProps = object().shape({
  title: string()
    .max(100, 'Title must be at most 100 characters')
    .required('Title is required'),
  attr: object().shape({
    [ItemAttributes.Description]: string().max(
      10000,
      'Description can not be more than 10 000 chars'
    ),
    [ItemAttributes.Packaging]: string().required('Packaging is required'),
    [ItemAttributes.KgPrice]: number()
      .required('Price is required')
      .typeError('Price must be a number')
      .min(0),
    // .positive('Price must be a positive number'),
    // [ItemAttributes.KgPrice]: string()
    //   .required('Price is required'),
    [ItemAttributes.Supply]: number()
      .typeError('Available supply must be a number')
      .integer('Available supply must be a number')
      .required('Available supply is required')
      .min(0, 'Available supply must be greater than or equal to 0'),
    // .positive('Available supply must be a positive number'),
    [ItemAttributes.Frequency]: string().required('Capacity is required'),
    [ItemAttributes.ShippingPackageWeight]: number()
      // Should be required unless not selected or "other" is selected
      .when(ItemAttributes.Packaging, {
        is: isShippingPackageWeightSelectedAndNotOther,
        then: number()
          .required('Shipping weight is required')
          .typeError('Shipping weight must be a number'),
      }),
    // [ItemAttributes.Files]: array()
    //   .of(itemImageSchema)
    //   .test('has-image', 'Image is required', (files: IFile[]) => {
    //     const image = getFileVariant(files, FileVariants.Image);
    //     return !!image;
    //   }),
    [ItemAttributes.PaymentPreferences]: string().required(
      'Payment preferences are required'
    ),
    [ItemAttributes.ShippingPackageDimensions]: object().shape({
      [PackagingDimensionsValues.Height]: number()
        .typeError('Package height must be a number')
        .integer('Package height must be a number')
        // .required('Package height is required')
        .min(0, 'Package height must be greater than or equal to 0'),
      [PackagingDimensionsValues.Width]: number()
        .typeError('Package width must be a number')
        .integer('Package width must be a number')
        // .required('Package width is required')
        .min(0, 'Package width must be greater than or equal to 0'),
      [PackagingDimensionsValues.Length]: number()
        .typeError('Package length must be a number')
        .integer('Package length must be a number')
        // .required('Package length is required')
        .min(0, 'Package length must be greater than or equal to 0'),
    }),
  }),
});

const addItemValidationProps = object().shape({
  attr: object().shape({
    [ItemAttributes.PlasticType]: string().required('Plastic type is required'),
    [ItemAttributes.Color]: string().required('Color is required'),
    [ItemAttributes.Grade]: string().required('Grade is required'),
    [ItemAttributes.Form]: string().required('Form is required'),
    // .positive('Available supply must be a positive number'),
    [ItemAttributes.Source]: string().typeError('Source must be a string'),
    [ItemAttributes.DataSheetAttributes]: object().shape({
      [DataSheetAttributes.Density]: object().shape({
        value: number()
          .typeError('Density must be a number')
          .min(0, 'Density must be greater than or equal to 0'),
      }),
      [DataSheetAttributes.MfiRange]: mfiRangeValidator,
    }),
  }),
});

// export const editItemValidationSchema = object().shape(editItemValidationProps);
// export const itemValidationSchema = object().shape(addItemValidationProps);

export const editItemValidationSchema = editItemValidationProps;
export const itemValidationSchema = editItemValidationProps.concat(
  addItemValidationProps
);

// export const itemValidationSchema = object().shape({
//   title: string()
//     .max(100, 'Title must be at most 100 characters')
//     .required('Title is required'),
//   attr: object().shape({
//     [ItemAttributes.Description]: string()
//       .max(10000, 'Description can not be more than 10 000 chars'),
//     [ItemAttributes.PlasticType]: string()
//       .required('Plastic type is required'),
//     [ItemAttributes.Color]: string()
//       .required('Color is required'),
//     [ItemAttributes.Grade]: string()
//     .required('Grade is required'),
//     [ItemAttributes.Form]: string()
//       .required('Form is required'),
//     [ItemAttributes.Packaging]: string()
//       .required('Packaging is required'),
//     [ItemAttributes.KgPrice]: number()
//       .required('Price is required')
//       .typeError('Price must be a number')
//       .min(0)
//       .positive('Price must be a positive number'),
//     // [ItemAttributes.KgPrice]: string()
//     //   .required('Price is required'),
//     [ItemAttributes.Supply]: number()
//       .typeError('Available supply must be a number')
//       .integer('Available supply must be a number')
//       .required('Available supply is required')
//       .min(0, 'Available supply must be greater than or equal to 0'),
//     // .positive('Available supply must be a positive number'),
//     [ItemAttributes.Frequency]: string()
//       .required('Capacity is required'),
//     [ItemAttributes.Source]: string()
//       .typeError('Source must be a string')
//       .required('Source is required'),
//     [ItemAttributes.DataSheetAttributes]: object().shape({
//       [DataSheetAttributes.Density]: object().shape({
//         value: number()
//           .typeError('Density must be a number')
//           .min(0, 'Density must be greater than or equal to 0')
//       }),
//       [DataSheetAttributes.MfiRange]: mfiRangeValidator
//     }),
//     [ItemAttributes.ShippingPackageWeight]: number()
//       // Should be required unless not selected or "other" is selected
//       .when(ItemAttributes.Packaging, {
//         is: isShippingPackageWeightSelectedAndNotOther,
//         then: number().required('Shipping weight is required')
//         .typeError('Shipping weight must be a number')
//       }),
//     // [ItemAttributes.Files]: array()
//     //   .of(itemImageSchema)
//     //   .test('has-image', 'Image is required', (files: IFile[]) => {
//     //     const image = getFileVariant(files, FileVariants.Image);
//     //     return !!image;
//     //   }),
//     [ItemAttributes.PaymentPreferences]: string()
//       .required('Payment preferences are required'),
//     [ItemAttributes.ShippingPackageDimensions]: object().shape({
//       [PackagingDimensionsValues.Height]: number()
//         .typeError('Package height must be a number')
//         .integer('Package height must be a number')
//         // .required('Package height is required')
//         .min(0, 'Package height must be greater than or equal to 0'),
//       [PackagingDimensionsValues.Width]: number()
//         .typeError('Package width must be a number')
//         .integer('Package width must be a number')
//         // .required('Package width is required')
//         .min(0, 'Package width must be greater than or equal to 0'),
//       [PackagingDimensionsValues.Length]: number()
//         .typeError('Package length must be a number')
//         .integer('Package length must be a number')
//         // .required('Package length is required')
//         .min(0, 'Package length must be greater than or equal to 0'),
//       }),
//     }),
// });

export const validateSell = (item, isEdit = false) => {
  let errors: ValidationError;

  const schema = isEdit ? editItemValidationSchema : itemValidationSchema;

  try {
    schema.validateSync(item, { abortEarly: false });
  } catch (err) {
    errors = err;
  }

  return errors
    ? errors.inner.map((e) => ({
        msg: e.message,
        prop: e.path,
      }))
    : [];
};

export const validate = (sell: IModel): ISellValidationError[] => {
  let errors: ValidationError;
  try {
    itemValidationSchema.validateSync(sell.newItem, { abortEarly: false });
  } catch (err) {
    errors = err;
  }

  return errors
    ? errors.inner.map((e) => ({
        msg: e.message,
        prop: e.path,
      }))
    : [];
};

export const hasShipping = ({ newItem }: IModel, shippingType: string) => {
  const { shippingOptions } = newItem.attr;
  if (shippingOptions) {
    return !!shippingOptions.find((i) => {
      return i.type === shippingType;
    });
  }
};
