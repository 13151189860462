﻿export { default as actions } from './actions';
import { ICompany, ServiceProvidersType } from '@@types';

export interface ICredentials {
  id: string;
  name: string;
  email: string;
  role?: string;
  isVerified: boolean;
  lastAcceptTermsDate: string;
  companies?: ICompany[];
  type?: ServiceProvidersType;
  serviceProviderId?: string;
}
export interface ILoginLinkCredentials {
  linkId: number;
  token: string;
}

export interface IModel {
  fetching: boolean;
  isVerified: boolean;
  isOTPGenerated: boolean;
  credentials: ICredentials;
  isLoginShown: boolean;
  isLoginEmailValid: boolean;
  errorCode: number;
  errorMessage: string;
  loginMessage: string;
  isLoginLinkSent: boolean;
  isLoginLinkEmailValid: boolean;
  loginLinkCredentials: ILoginLinkCredentials;
}

export const defaultState: IModel = {
  fetching: false,
  isVerified: false,
  isOTPGenerated: false,
  credentials: null,
  isLoginShown: false,
  isLoginEmailValid: false,
  errorCode: null,
  errorMessage: null,
  loginMessage: null,
  isLoginLinkSent: false,
  isLoginLinkEmailValid: false,
  loginLinkCredentials: null,
};

export const state = (initialState: IModel) => {
  return {
    ...defaultState,
    ...initialState,
  };
};

export default state;
