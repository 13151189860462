﻿import { useRef } from 'preact/hooks';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;

export function useComponentId(): string {
  const idRef = useRef<number>();
  if (idRef.current === undefined) {
    idRef.current = getUniqueId();
  }
  return 'id' + idRef.current;
}

export default useComponentId;
