﻿import { IReadonlyMap } from './item';
import { getVatPercentage } from './price-helper';
import { roundTwoDecimals } from './number-helper';
import { IMaterialQualityPackages } from '@@types/materialQuality';

export interface IPriceAndDiscount {
  price: number;
  discount: number;
  discountRate: number;
  vatPercent: number;
  vat: number;
  sum: number;
  sumNoVat: number;
  extraSamplePreparation: number;
}

// export enum TestMethods {
//   MeltFlow = 'MELT_FLOW',
//   Ocular = 'OCULAR',
//   Density = 'DENSITY',
//   // TensileAndBending = 'TENSILE_AND_BENDING',
//   HeavyMetal = 'HEAVY_METAL',
//   Purity = 'PURITY',
//   FillerQuantification = 'FILLER_QUANTIFICATION'
// }

// export const availableTestMethods = [
//   TestMethods.MeltFlow,
//   TestMethods.Ocular,
//   TestMethods.Density,
//   // TestMethods.TensileAndBending,
//   TestMethods.HeavyMetal,
//   TestMethods.Purity,
//   TestMethods.FillerQuantification
// ];

// export const testMethodPrice: IReadonlyMap<TestMethods, number> = new Map([
//   [TestMethods.MeltFlow, 110],
//   [TestMethods.Ocular, 0],
//   [TestMethods.Density, 110],
//   // [TestMethods.TensileAndBending, 275],
//   [TestMethods.HeavyMetal, 370],
//   [TestMethods.Purity, 370],
//   [TestMethods.FillerQuantification, 370]
// ]);

// export const testMethodLabel = new Map<TestMethods, string>([
//   [TestMethods.MeltFlow, 'Melt flow'],
//   [TestMethods.Ocular, 'Ocular'],
//   [TestMethods.Density, 'Density'],
//   // [TestMethods.TensileAndBending, 'Tensile and Bending'],
//   [TestMethods.HeavyMetal, 'Heavy Metal'],
//   [TestMethods.Purity, 'Purity'],
//   [TestMethods.FillerQuantification, 'Filler quantification']
// ]);

// export const testMethodsDescriptionMap: IReadonlyMap<
//   TestMethods,
//   string
// > = new Map([
//   [TestMethods.MeltFlow, 'The MFI measurement method is the most common way of testing the melt flow of a material.'],
//   [
//     TestMethods.Ocular,
//     'Clarifies if the sample consists of other materials than plastic.'
//   ],

//   [TestMethods.Density, 'Testing material density can indicate if the sample has fillers.'],
//   // [
//   //   TestMethods.TensileAndBending,
//   //   'Testing mechanical properties of the sample. Requires injection moulding of a test specimen'
//   // ],
//   [TestMethods.HeavyMetal, 'Check if sample contains heavy metals, needed for REACH compliance.'],
//   [
//     TestMethods.Purity,
//     'A DSC analysis confirming if the sample has one type of plastic or several.'
//   ],
//   [
//     TestMethods.FillerQuantification,
//     'Confirm if the sample has a filler and how much.'
//   ]
// ]);

export enum TestMethods {
  Physical = 'PHYSICAL',
  Mechanical = 'MECHANICAL',
  Composition = 'COMPOSITION',
}

export const availableTestMethods = [
  TestMethods.Physical,
  TestMethods.Mechanical,
  TestMethods.Composition,
];

export const testMethodPrice: IReadonlyMap<TestMethods, number> = new Map([
  [TestMethods.Physical, 100],
  [TestMethods.Mechanical, 200],
  [TestMethods.Composition, 300],
]);

export const testMethodLabel = new Map<TestMethods, string>([
  [TestMethods.Physical, 'Physical properties'],
  [TestMethods.Mechanical, 'Mechanical properties'],
  [TestMethods.Composition, 'Material composition'],
]);

export const testMethodsDescriptionMap: IReadonlyMap<
  IMaterialQualityPackages,
  string[]
> = new Map([
  [
    IMaterialQualityPackages.APPEARANCE,
    ['Form', 'Size of form', 'Visual contamination'],
  ],
  [
    IMaterialQualityPackages.PHYSICAL,
    [
      'Density (interval)',
      'Bulk density',
      'Melt flow (interval)',
      'Moisture content',
      'Melting temperature',
    ],
  ],
  [
    IMaterialQualityPackages.MECHANICAL,
    [
      'Impact strength',
      'Tensile modulus',
      'Tensile strength @ break',
      'Elogation @ break',
    ],
  ],
  [
    IMaterialQualityPackages.MATERIAL,
    [
      'Check if sample is single or blend of polymers',
      'Check if material sample contains fillers',
    ],
  ],
]);

export const getTestMethodsTotalPrice = (
  selectedTestMethods: TestMethods[]
) => {
  return selectedTestMethods.reduce(
    (total: number, testMethod: TestMethods) => {
      return total + testMethodPrice.get(testMethod);
    },
    0
  );
};
export const isSamplePackageSelected = (
  selectedTestMethods: IMaterialQualityPackages[],
  testMethod: IMaterialQualityPackages
) => {
  return selectedTestMethods.includes(testMethod);
};

export const isTestMethodSelected = (
  selectedTestMethods: TestMethods[],
  testMethod: TestMethods
) => {
  return selectedTestMethods.includes(testMethod);
};

const requiresExtraSample = [TestMethods.Composition];

export const isExtraSampleRequired = (selectedTestMethods: TestMethods[]) => {
  return false;
  // return !!requiresExtraSample.find((testMethod) => {
  //   return isTestMethodSelected(selectedTestMethods, testMethod);
  // });
};

const getDiscountRate = (selectedTestMethods: TestMethods[]) => {
  const nrOfSelectedMethods = selectedTestMethods.length;
  let discountRate = 0;

  switch (nrOfSelectedMethods) {
    case 0:
    case 1:
    case 2:
      discountRate = 0;
      break;
    case 3:
      discountRate = 0;
      break;
    case 4:
      discountRate = 0;
      break;
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
      discountRate = 0;
      break;
  }
  return discountRate;
};

export const getTestMethodsPriceAndDiscount = (
  selectedTestMethods: TestMethods[],
  countryIso: string
): IPriceAndDiscount => {
  const price = getTestMethodsTotalPrice(selectedTestMethods);
  const discountRate = getDiscountRate(selectedTestMethods);
  const discount = price * (discountRate / 100);
  const extraSamplePreparation = isExtraSampleRequired(selectedTestMethods)
    ? 50
    : 0;
  const sumNoVat = roundTwoDecimals(price - discount + extraSamplePreparation);
  const vatPercent = getVatPercentage(countryIso);
  const vat = roundTwoDecimals(sumNoVat * (vatPercent / 100));
  const sum = roundTwoDecimals(sumNoVat + vat);
  return {
    price,
    discountRate,
    discount,
    extraSamplePreparation,
    vatPercent,
    vat,
    sumNoVat,
    sum,
  };
};

export const removeSelectedTestMethod = (
  selectedTestMethods: TestMethods[],
  testMethod: TestMethods
) => {
  return selectedTestMethods.filter((item) => item !== testMethod);
};

export const removeSelectedSamplePackage = (
  selectedTestMethods: IMaterialQualityPackages[],
  testMethod: IMaterialQualityPackages
) => {
  return selectedTestMethods.filter((item) => item !== testMethod);
};

export const addSelectedTestMethod = (
  selectedTestMethods: TestMethods[],
  testMethod: TestMethods
) => {
  return selectedTestMethods.concat([testMethod]);
};

export const addSelectedSamplePackage = (
  selectedTestMethods: IMaterialQualityPackages[],
  testMethod: IMaterialQualityPackages
) => {
  return selectedTestMethods.concat([testMethod]);
};

export const testMethods = {
  'DIN 53479': {
    _superseded: 'ISO 1183',
    unit: 'g/cm3',
  },
  'ISO 62': {
    unit: '%',
    condition: '23°C/50%RH',
  },
  'ISO 75-1': {
    unit: '°C',
    condition: ['A (1.8 MPa)', 'B (0.45 MPa)'],
  },
  'ISO 178': {
    unit: 'MPa',
    condition: '+23°C',
  },
  'ISO 179': {
    unit: 'kJ/m2',
    condition: ['+23°C', '-20°C'],
  },
  'ISO 294': {
    unit: '%',
  },
  'ISO 306': {
    unit: ['kJ/m2', '°C'],
    condition: ['+23°C', '-20°C'],
  },
  // For ISO 527 the test speed is typically 5 or 50mm/min for measuring strength and elongation and 1mm/min for measuring modulus.
  // http://www.intertek.com/polymers/tensile-testing/iso-527-1/
  'ISO 527': {
    unit: ['MPa', '%', 'N/mm2'],
    condition: ['1mm/min', '5mm/min', '50mm/min'],
  },
  'ISO 1133': {
    unit: 'g/10min',
    condition: '230°C/2,16kg',
  },
  'ISO 1183': {
    unit: 'g/cm3',
  },
  'ISO 2039': {
    unit: 'MPa',
  },
};
