﻿import { IItem } from '@@types';
import { getOptionsLabelFromValue, IReadonlyMap } from './index';
import { mapValueWithLabel } from './mappers';

export enum TimeToDeliveryValues {
  Weeks02 = '0-2',
  Weeks23 = '2-3',
  Weeks34 = '3-4',
  Weeks4plus = '4plus',
}

const timeToDeliveryLabels: IReadonlyMap<TimeToDeliveryValues, string> =
  new Map([
    [TimeToDeliveryValues.Weeks02, '0-2 weeks'],
    [TimeToDeliveryValues.Weeks23, '2-3 weeks'],
    [TimeToDeliveryValues.Weeks34, '3-4 weeks'],
    [TimeToDeliveryValues.Weeks4plus, 'More than 4 weeks'],
  ]);

export const timeToDeliveryOptions = mapValueWithLabel(
  TimeToDeliveryValues,
  timeToDeliveryLabels
);

export const getTimeToDeliveryLabel = (value: string) =>
  getOptionsLabelFromValue(timeToDeliveryOptions, value);

export const getTimeToDeliveryOptions = (newItem: IItem) => {
  return timeToDeliveryOptions.map((item) => {
    return {
      ...item,
      checked: newItem.attr.timeToDelivery === item.value,
    };
  });
};
