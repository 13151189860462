﻿import { IDBItem } from '@@types';
export { default as actions } from './actions';

export interface IModel {
  updatingPhoneNumber: boolean;
  deleting: boolean;
  fetching: boolean;
  creating: boolean;
  uploading: boolean;
  errors: string[];
  items: IDBItem[];
}

export const defaultState: IModel = {
  updatingPhoneNumber: false,
  deleting: false,
  fetching: false,
  creating: false,
  uploading: false,
  errors: [],
  items: [],
};

export const state = (): IModel => {
  return {
    ...defaultState,
  };
};

export default state;
