﻿import axios, { AxiosResponse as FetcherResponse } from 'axios';

export const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response.json ? response.json() : null;
  } else {
    const error = new Error(response.statusText);
    error.message = response;
    console.log('throw error ============', error);
    throw error;
  }
};

export const parseJSON = (response) => {
  return response.json();
};

export const fetcher = axios;
export { FetcherResponse };
