﻿export const getItemUrl = (itemKey: string, matchItemKey: string = null) => {
  return '/item/' + itemKey + (matchItemKey ? '/' + matchItemKey : '');
};

export const getItemUrlWithSlug = (
  itemKey: string,
  slug?: string,
  matchItemKey: string = null
) => {
  const slugString = slug ? slug + '-' : '';
  const matchItemString = matchItemKey ? '/' + matchItemKey : '';
  return '/item/' + slugString + itemKey + matchItemString;
};

export const getExternalItemUrl = (
  itemKey: string,
  matchItemKey: string = null
) => {
  return '/eitem/' + itemKey + (matchItemKey ? '/' + matchItemKey : '');
};

export const getExternalItemUrlWithSlug = (
  itemKey: string,
  slug?: string,
  matchItemKey: string = null
) => {
  const slugString = slug ? slug + '-' : '';
  const matchItemString = matchItemKey ? '/' + matchItemKey : '';
  return '/eitem/' + slugString + itemKey + matchItemString;
};

export const getItemUrlFromItem = (item: any) => {
  return getItemUrl(item._key);
};

// For our human readable urls, looking like this <slug>-<id> and returns { slug: string; id: string; }
// Example input black-pp-mfi-2-7654321
//
export const getCompanyUrl = (companyId: string, slug?: string) => {
  const slugString = slug ? slug + '-' : '';
  return '/company/' + slugString + companyId;
};

interface IHumanReadableUrlData {
  slug: string;
  id: string;
}

export const urlPathSplitter = (urlPath: string): IHumanReadableUrlData => {
  // Splits path looking like this: <slug>-<id>
  const lastIndex = urlPath.lastIndexOf('-');
  const slug = urlPath.substr(0, lastIndex);
  const id = urlPath.substr(lastIndex + 1);
  return {
    slug,
    id,
  };
};
