﻿import type { JSX } from 'preact';
import { IMergedAuthProps } from 'shared/unistore/Auth/withAuth';
import { h, Fragment, FunctionComponent } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { API_LOGIN } from '@@constants/url';
import { Box } from '@@helpers/material-ui/core';
import { TextField } from 'components/ui/TextField';
import { SmallButton } from 'components/ui/Button';
import { ErrorMessage } from 'components/ui/typography/Fonts';

const errors = {
  BAD_CREDENTIALS: 'Bad credentials',
};

interface IProps {
  auth: IMergedAuthProps;
  serviceProviderLogin?: boolean;
}

interface IState {
  error: any;
  username: string;
  password: string;
}

const initialState: IState = {
  error: null,
  username: '',
  password: '',
};

const Login: FunctionComponent<IProps> = ({ auth, serviceProviderLogin }) => {
  const [state, setState] = useState<IState>(initialState);
  useEffect(() => {
    switch (auth.errorCode) {
      case 401:
        setState((prevState: IState) => {
          return {
            ...prevState,
            error: errors.BAD_CREDENTIALS,
          };
        });
        break;
      default:
        break;
    }
  }, [auth.errorCode]);

  useEffect(() => {
    return () => {
      auth.reset();
    };
  }, []);

  const setStateValue = (name: 'username' | 'password') => {
    return ({ target }: { target: HTMLInputElement }) => {
      setState((prevState) => {
        return {
          ...prevState,
          [name]: target.value,
        };
      });
    };
  };

  const handleUpdateUsername = ({ target }: { target: HTMLInputElement }) => {
    setStateValue('username')({ target: target });
    auth.validateLoginEmail(target.value);
  };

  const handleSubmit = (
    evt: JSX.TargetedEvent<HTMLFormElement, SubmitEvent>
  ) => {
    evt.preventDefault();
    const credentials = {
      username: state.username,
      password: state.password,
    };
    if (serviceProviderLogin) {
      auth.login(credentials, null, serviceProviderLogin);
    } else {
      auth.login(credentials);
    }
  };

  const isButtonEnabled = auth.isLoginEmailValid && !auth.fetching;
  const { username, password } = state;

  return (
    <Fragment>
      {!auth.credentials ? (
        <form
          method="post"
          action={API_LOGIN}
          onSubmit={handleSubmit}
          class="flex flex-column"
        >
          <Box mb={1}>
            <TextField
              size="small"
              variant="outlined"
              id="login-username"
              label="Email"
              type="email"
              value={username}
              fullWidth
              onInput={handleUpdateUsername}
            />
          </Box>
          <Box mb={1}>
            <TextField
              size="small"
              variant="outlined"
              id="login-password"
              label="Password"
              type="password"
              value={password}
              fullWidth
              onInput={setStateValue('password')}
            />
          </Box>
          <SmallButton
            type="submit"
            disabled={!isButtonEnabled}
            color="primary"
            variant="contained"
            disableElevation
          >
            Login
          </SmallButton>
          {auth.errorMessage ? (
            <Box mt={1}>
              <ErrorMessage>{auth.errorMessage}</ErrorMessage>
            </Box>
          ) : null}
        </form>
      ) : null}
      {auth.loginMessage ? (
        <p class="green mt2 mb0">{auth.loginMessage}</p>
      ) : null}
    </Fragment>
  );
};

export default Login;
