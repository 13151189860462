﻿import { h, FunctionComponent, cloneElement, Fragment } from 'preact';
import { memo } from 'preact/compat';
import Menu from './Menu';
import { IMergedAuthProps } from '../unistore/Auth/withAuth';
import Logo from 'components/ui/Logo';
import {
  AppBar,
  Container,
  Toolbar,
  useScrollTrigger,
} from '@@helpers/material-ui/core';
import { makeStyles, Theme } from '@@helpers/material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.pearl.main,
    color: theme.palette.black.main,
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  menuItem: {
    color: theme.palette.black.main,
    marginLeft: theme.spacing(4),
  },
  logo: {
    maxWidth: '138px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '200px',
    },
  },
}));

const ElevationScroll = (props) => {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    elevation: trigger ? 2 : 0,
  });
};
interface IProps {
  auth: IMergedAuthProps;
  serviceProviderLogin?: boolean;
  menu?: boolean;
}

const Header: FunctionComponent<IProps> = (props) => {
  const classes = useStyles();
  return (
    <Fragment>
      <ElevationScroll {...props}>
        <AppBar className={classes.root}>
          <Container>
            <Toolbar className={classes.toolbar} disableGutters>
              <a
                className={classes.logo}
                class="no-underline"
                href="https://atomler.com"
                title="Atomler"
              >
                <Logo />
              </a>
              <Menu
                auth={props.auth}
                serviceProviderLogin={props.serviceProviderLogin}
              />
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </Fragment>
  );
};

export default memo(Header);
