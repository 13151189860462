export { default as actions } from './actions';
export type SnackbarSeverity = 'error' | 'warning' | 'info' | 'success';
export interface IModel {
  open: boolean;
  message: string;
  severity: SnackbarSeverity;
}

export const defaultState: IModel = {
  open: false,
  message: '',
  severity: 'error',
};

export const state = () => {
  return {
    ...defaultState,
  };
};

export default state;
