﻿import { IDBItem, IPriceRange } from '@@types';
import { PaymentOptions } from '@@helpers/item/paymentOptions';
export { default as actions } from './actions';
export { default as views } from './views';

enum ShippingOptions {
  DHL = 'dhl',
  DDP = 'ddp',
  PickUp = 'pick_up',
  Other = 'other',
}

export interface IPaymentOption {
  price?: number;
  type: PaymentOptions;
}

export interface IShippingOption {
  price?: number;
  type: ShippingOptions;
}

export interface IModel {
  fetching: boolean;
  deleting: boolean;
  data?: IDBItem;
  fetchingMatch: boolean;
  matchData?: IDBItem;
  priceEstimate?: IPriceRange;
  fetchingPriceEstimate: boolean;
  authorized: boolean;
}

// creditedItemIds: (2) ["11993983", "13875775"]
// match: {_key: "14245208", _id: "matches/14245208", _rev: "_ZspeG6q--_", offerItemId: "13875775", offerUserId: "11669812", …}
// offerItem: {_key: "13875775", _id: "items/13875775", _rev: "_ZpYt9vi--_", attr: {…}, title: "johannes testar 2", …}
// requestItem: {_key: "11993983", _id: "items/11993983", _rev: "_Za6Rku2--_", attr:

export const defaultState: IModel = {
  fetching: false,
  deleting: false,
  data: undefined,
  fetchingMatch: false,
  matchData: null,
  fetchingPriceEstimate: false,
  authorized: true,
};

export const state = (initialData?: IDBItem) => {
  return {
    ...defaultState,
    data: initialData ? initialData : undefined,
  };
};

export default state;
