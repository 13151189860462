﻿import { Store } from 'unistore';
import { IStore } from 'shared/unistore';
import {
  createActivity,
  ActivityTypes,
  createActivityReply,
} from 'api/activity';
import { IModel, defaultState } from './index';

// export const handleSendMessage = async ({ contactSupplier }) => {
//     debugger;

//     store.setState({ count: state.count+1 })

//     const { message } = contactSupplier;
//     const payload = {
//         activityType: ActivityTypes.Message,
//         toUserId: '666',
//         message,
//     };
//     const res = await createActivity(payload);
//     return {

//     }
// };

export const handleChangeMessage = ({ contactSupplier }: any, evt: any) => {
  return {
    contactSupplier: {
      ...contactSupplier,
      message: evt.target.value,
    },
  };
};

export const handleContact = ({ contactSupplier }) => {
  return {
    contactSupplier: {
      ...contactSupplier,
      isContacting: true,
    },
  };
};

export const handleCloseContact = ({ contactSupplier }) => {
  return {
    contactSupplier: {
      ...defaultState,
    },
  };
};

export const reset = () => {
  return {
    contactSupplier: defaultState,
  };
};

const actions = (store: Store<IStore>) => ({
  reset,
  handleChangeMessage,
  handleContact,
  handleCloseContact,
  handleSendMessage: async (
    { contactSupplier }: { contactSupplier: IModel },
    toUserId: string,
    relatedItemId: string
  ) => {
    store.setState({
      contactSupplier: {
        ...contactSupplier,
        isSending: true,
      },
    });

    const { message } = contactSupplier;
    const payload = {
      activityType: ActivityTypes.Message,
      toUserId,
      relatedItemId,
      message,
    };

    let res;
    try {
      res = await createActivity(payload);
    } catch (error) {
      console.log(error);
    }
    console.log('res', res);
    store.setState({
      contactSupplier: {
        ...contactSupplier,
        isSending: false,
        isSent: true,
      },
    });
  },
  handleSendReply: async (
    { contactSupplier }: { contactSupplier: IModel },
    replyToActivityId: string
  ) => {
    store.setState({
      contactSupplier: {
        ...contactSupplier,
        isSending: true,
      },
    });

    const { message } = contactSupplier;
    const payload = {
      activityType: ActivityTypes.Message,
      replyToActivityId,
      message,
    };

    let res;
    try {
      res = await createActivityReply(payload);
    } catch (error) {
      console.log(error);
    }
    console.log('res', res);
    store.setState({
      contactSupplier: {
        ...contactSupplier,
        isSending: false,
        isSent: true,
      },
    });
  },
});

export default actions;
