﻿import { API_CREATE_OFFER } from '@@constants/url';

import { fetcher } from '@@helpers/fetch-helper';
import { AxiosResponse } from 'axios';

export const createOffer = async (
  matchId: string,
  offer: Record<string, unknown>
): Promise<AxiosResponse<any>> => {
  const payload = {
    ...offer,
    matchId,
  };

  return fetcher.post(API_CREATE_OFFER, payload);
  // return fetcher.get(API_GET_MATCH(matchId), { withCredentials: true });
};
