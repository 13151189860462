﻿import { countries } from 'typed-countries';

export const getCountry = (iso: string) => {
  return countries.find((c) => c.iso === iso);
};

export const getCountryName = (iso: string) => {
  const country = getCountry(iso);
  return country ? country.name : null;
};

export const getCountryByName = (countryName: string) => {
  return countries.find((c) => c.name === countryName);
};

export const getCountryISO = (countryName: string) => {
  const country = getCountryByName(countryName);
  return country ? country.iso : null;
};

export { countries };
