﻿import { API_GET_MATCHES, API_GET_ACTIVE_SALES_MATCHES } from '@@constants/url';
import { fetcher } from '@@helpers/fetch-helper';

export const getMatches = async (itemId: string) => {
  return fetcher.get(API_GET_MATCHES(itemId), { withCredentials: true });
};

export const getActiveSalesMatches = async (
  plasticType: string,
  form: string
) => {
  return fetcher.get(API_GET_ACTIVE_SALES_MATCHES(plasticType, form), {
    withCredentials: true,
  });
};
