﻿import { getOptionsLabelFromValue } from './index';

export enum PurityValues {
  Single = 'single',
  Mixed = 'mixed',
  Other = 'other',
}

export const purityOptions = [
  { label: 'Single material', value: 'single' },
  { label: 'Mixed material', value: 'mixed' },
  { label: 'Other', value: 'other' },
];

export const getPurityOptions = (newItem) => {
  return purityOptions.map((item) => {
    return {
      ...item,
      checked: newItem.purity === item.value,
    };
  });
};

export const getPurityOptionsLabel = (value: string) =>
  getOptionsLabelFromValue(purityOptions, value);
