﻿import {
  IItemPostData,
  IDBItem,
  IItem,
  IFile,
  ItemAttributes,
  IAddress,
} from '@@types';
import {
  API_CREATE_ITEM,
  API_GET_ITEMS,
  API_GET_ITEM,
  API_UPDATE_ITEM,
  API_DELETE_ITEMS,
  API_GET_ITEM_AND_MATCH,
  API_GET_EITEM_AND_MATCH,
  API_GET_ESEARCH_ITEMS,
  API_GET_GRAPH_DATA,
  API_GET_PRICE_INDEX_OPTIONS,
  API_PUBLISH_ITEM,
  API_GET_DRAFT_ITEM,
} from '@@constants/url';

import {
  fetcher,
  // checkStatus,
  // parseJSON
} from '@@helpers/fetch-helper';
import { stringify } from 'query-string';
import { defaultErrorHandling } from 'helpers/api-error-handling';
import { ItemStatus } from '@@helpers/item';

export const getItem = async (itemId: string, isDelete?: boolean) => {
  return fetcher.get(API_GET_ITEM(itemId, isDelete), { withCredentials: true });
};

export const getDraftItem = async (itemId: string, status?: ItemStatus) => {
  return fetcher.get(API_GET_DRAFT_ITEM(itemId, status), {
    withCredentials: true,
  });
};

export const getItemAndMatch = async (
  itemId: string,
  matchItemId?: string,
  isDelete?: boolean
) => {
  return fetcher.get(API_GET_ITEM_AND_MATCH(itemId, matchItemId, isDelete), {
    withCredentials: true,
  });
};

export const getEitemAndMatch = async (
  itemId: string,
  matchItemId?: string
) => {
  return fetcher.get(API_GET_EITEM_AND_MATCH(itemId, matchItemId), {
    withCredentials: true,
  });
};

export const deleteItem = async (itemId) => {
  return fetcher.delete(API_GET_ITEM(itemId), { withCredentials: true });
};

export const deleteItems = async (itemIds: string[]) => {
  return fetcher.delete(API_DELETE_ITEMS(itemIds), { withCredentials: true });
};

interface IGetItems {
  data: {
    auth: any;
    items: IDBItem[];
  };
}

export interface IFilterOptions {
  plasticType?: string;
  type?: string;
  source?: string;
  orderBy?: string;
  orderDirection?: 'asc' | 'desc';
}

const apiUrlItemsWithFilter = (filterOptions: IFilterOptions) => {
  return filterOptions
    ? API_GET_ITEMS + '?' + stringify(filterOptions)
    : API_GET_ITEMS;
};

export const getItems = async (
  filterOptions: IFilterOptions
): Promise<IGetItems> => {
  return await fetcher.get(apiUrlItemsWithFilter(filterOptions), {
    withCredentials: true,
  });
};

// interface IUpdateItemProps {
//   supply?: number;
//   files?: IFile[];
// }

export type UpdateAnyItemAttr = {
  [key in ItemAttributes]?: string | number | IFile[];
};

export const updateItem = async (itemId: string, newItem: IItem) => {
  return fetcher.put(API_UPDATE_ITEM(itemId), newItem, {
    withCredentials: true,
  });
};

export const createItem = async (itemData: IItemPostData) => {
  return fetcher
    .post(API_CREATE_ITEM, itemData, { withCredentials: true })
    .catch(defaultErrorHandling);
};

export const publishItem = async (
  itemId: string,
  billingAddress?: IAddress
) => {
  return fetcher
    .put(
      API_PUBLISH_ITEM(itemId),
      { billingAddress },
      { withCredentials: true }
    )
    .catch(defaultErrorHandling);
};

export const getExternalItems = async () => {
  return fetcher.get(API_GET_ESEARCH_ITEMS(null, null), {
    withCredentials: true,
  });
};

export const getGraphData = async (params) => {
  params = {
    ...params,
    recycled: params.recyled || true, // recycled should be true by default
  };
  return fetcher.get(API_GET_GRAPH_DATA(params), { withCredentials: true });
};

export const getPriceIndexOptions = async (
  plasticType: string,
  recycled: boolean
) => {
  return fetcher.get(API_GET_PRICE_INDEX_OPTIONS(plasticType, recycled), {
    withCredentials: true,
  });
};
