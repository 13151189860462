﻿import { h, FunctionComponent, Fragment } from 'preact';
import { useState } from 'preact/hooks';
import {
  Box,
  Grid,
  Drawer as MuiDrawer,
  IconButton,
} from '@@helpers/material-ui/core';
import { MenuRounded, CloseRounded } from '@@helpers/material-ui/icons';
import {
  makeStyles,
  withStyles,
  Theme,
} from '@@helpers/material-ui/core/styles';
import { SecondaryButtonBig } from 'components/ui/Button';
import { IMergedAuthProps } from '../unistore/Auth/withAuth';

const Drawer = withStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.sand.main,
    width: '100%',
    maxWidth: '100%',
  },
}))(MuiDrawer);

const useStyles = makeStyles((theme) => ({
  link: {
    fontSize: '32px',
    fontFamily: 'Manrope',
    color: theme.palette.black.main,
    lineHeight: '200%',
    fontWeight: 600,
    textDecoration: 'none',
    '&:hover': {
      fontWeight: 500,
    },
  },
  ul: {
    listStyle: 'none',
    paddingLeft: 0,
    marginBottom: 0,
    marginTop: 0,
  },
  icon: {
    color: theme.palette.black.main,
  },
}));

interface IProps {
  handleShowNewsletter: (evt: Event) => void;
  auth: IMergedAuthProps;
}

export const Hamburger: FunctionComponent<IProps> = ({
  handleShowNewsletter,
  auth,
}) => {
  const classes = useStyles();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOpenMenu = (evt: Event) => {
    evt.preventDefault();
    setIsMenuOpen(true);
  };

  const handleCloseMenu = (evt: Event) => {
    evt.preventDefault();
    setIsMenuOpen(false);
  };

  const StyledLink = (props) => {
    return <a className={classes.link} {...props} />;
  };

  const handleNewsletterClick = (evt: Event) => {
    evt.preventDefault();
    setIsMenuOpen(false);
    handleShowNewsletter(evt);
  };

  const handleSignInClick = (evt: Event) => {
    evt.preventDefault();
    setIsMenuOpen(false);
    auth.showLogin();
  };

  const handleSignOutClick = (evt: Event) => {
    evt.preventDefault();
    setIsMenuOpen(false);
    auth.logout();
  };

  return (
    <Fragment>
      <IconButton onClick={handleOpenMenu} aria-label="open menu">
        <MenuRounded className={classes.icon} fontSize="large" />
      </IconButton>

      <Drawer anchor="right" open={isMenuOpen} onClose={handleCloseMenu}>
        {/* {list(anchor)} */}
        <Grid container spacing={1}>
          <Grid item container justify="flex-end" xs={12} sm={12}>
            <IconButton onClick={handleCloseMenu} aria-label="close menu">
              <CloseRounded className={classes.icon} fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item xs={12} sm={12} container justify="center">
            <ul className={classes.ul}>
              {/* <li><StyledLink href="/price-index">Price Index</StyledLink></li> */}
              <li>
                <StyledLink href="https://www.atomler.com/about">
                  About us
                </StyledLink>
              </li>
              <li>
                <StyledLink
                  href="https://www-atomler-com.filesusr.com/html/dd5fb8_9b75edaa3605c50657038ef0b6848c70.html"
                  onClick={handleNewsletterClick}
                >
                  Newsletter
                </StyledLink>
              </li>
              <li>
                <StyledLink
                  href="https://calendly.com/atomler-productdemo/30min"
                  target="_blank"
                >
                  Free demo
                </StyledLink>
              </li>
              <li>
                <StyledLink href="/find">Find material</StyledLink>
              </li>
              <li>
                <StyledLink href="/sell">Sell material</StyledLink>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={12} container justify="center">
            <Box mt={3}>
              {auth && auth.credentials ? (
                <SecondaryButtonBig onClick={handleSignOutClick}>
                  Sign out
                </SecondaryButtonBig>
              ) : (
                <SecondaryButtonBig onClick={handleSignInClick}>
                  Sign in
                </SecondaryButtonBig>
              )}
            </Box>
          </Grid>
        </Grid>
      </Drawer>
    </Fragment>
  );
};

export default Hamburger;
