import { Fragment, h, render } from 'preact';
import { initStore } from '../unistore';
import { DEFAULT_PAGE_TITLE } from '@@constants/misc';
import theme from 'styles/theme';
import { close as closeSnackBarFunction } from '../unistore/Notification/actions';
import Main from './Main';
import GA4React from 'ga-4-react';
import * as ReactGA from 'react-ga';
import Snowfall from 'react-snowfall';

// GA Universal
ReactGA.initialize('UA-116640951-1'); // https://atomler.com

// GA 4
let ga4;
const ga4react = new GA4React('G-XNGQHKL06C');

const initialState = window.initialState;
const unistoreStore = initStore(initialState);

const today = new Date();
const month = today.getMonth();

const old = history.pushState;
history.pushState = function (...args) {
  old.apply(this, args);
  scrollTo(0, 0);
};

const trackPageview = (url) => {
  if (ga4?.pageview) {
    ga4.pageview(url);
  } else {
    ga4react
      .initialize()
      .then((ga4Obj) => {
        ga4 = ga4Obj;
        if (ga4?.pageview) {
          ga4.pageview(url);
        }
      })
      .catch((error) => {
        console.log('GA4 error: ', error);
      });
  }
};

const handleRoute = (evt) => {
  const url = evt.url;
  const { key } = evt.current;
  if (process.env.NODE_ENV === 'production') {
    ReactGA.pageview(url); // GA Universal
    trackPageview(url); // GA 4
  } else {
    console.log('NOTE: GA disabled in !production');
  }
  const closeSnackBar = unistoreStore.action(closeSnackBarFunction);
  closeSnackBar();
  switch (key) {
    case 'signUp':
      document.title = 'Sign up - Atomler';
      break;

    default:
      document.title = DEFAULT_PAGE_TITLE;
  }
};

export default () => {
  const rootNode = document.querySelector('#root');
  const existingNode = rootNode.querySelector('div');
  existingNode.remove();
  render(
    <Fragment>
      {month === 11 ? (
        <Snowfall
          color="#dee4fd"
          wind={[-1, 3.5]}
          speed={[0, 3.5]}
          radius={[0.5, 2.5]}
          snowflakeCount={231}
        />
      ) : null}
      <Main
        unistoreStore={unistoreStore}
        theme={theme}
        handleRoute={handleRoute}
      />
    </Fragment>,
    rootNode
  );
};
