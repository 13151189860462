import { randomString } from '@@helpers/string-helper';
import {
  ILinkLoginData,
  ILogisticsParams,
  IGetItemsFilter,
  IGetItemsFilterMap,
  ServiceProvidersType,
} from '@@types';
// console.log('NODE_ENV: ', process.env.NODE_ENV);
// console.log('process.env: ', process.env);
// const domain = process.env.API_ENDPOINT || 'http://localhost:8000';
// const domain = process.env.NODE_ENV === 'production' ? 'http://api.sja.knub.be:1337' : 'http://localhost:8000';
// import { ItemTypes } from '@@helpers/item';
import { ImageSize } from '@@constants/image';
import { OrderStatus } from '@@types';
import { stringify } from 'query-string';
import { ItemStatus } from '@@helpers/item';

const isServer = typeof window === 'undefined';
const isProduction = process.env.NODE_ENV === 'production';
const isTest = process.env.NODE_ENV === 'test';

const envVar = <T>(
  productionValue: T,
  testValue: T,
  developmentValue: T
): T => {
  if (isProduction) {
    return productionValue;
  } else if (isTest) {
    return testValue;
  }
  return developmentValue;
};

const webDomain = envVar(
  'https://market.atomler.com',
  'https://test.market.atomler.com',
  'http://localhost:3000'
);
let domain; // API domain
let imageDomain;
if (isServer && !isTest) {
  domain = envVar(
    'http://api:8000',
    'http://api:8000',
    'http://localhost:8000'
  );
} else {
  // eslint-disable-next-line no-undef
  // const { protocol, port } = document.location;
  domain = process.env.API_ENDPOINT;
}

// Hack, pass proper env variable instead
imageDomain = domain;
if (isServer) {
  imageDomain = imageDomain.replace(
    'http://api:8000',
    'https://api.atomler.com'
  );
}

export const cacheBust = (str: string) => {
  const separator = str.indexOf('?') > -1 ? '&' : '?';
  return str + separator + '_=' + encodeURIComponent(randomString());
};

export const API_LOGIN = domain + '/user/login';
export const API_LOGOUT = domain + '/user/logout';
export const API_AUTH = domain + '/user';
export const API_SIGN_UP = domain + '/user';
export const API_USER_ID = (userId: string) => domain + '/user/' + userId;
export const API_LOGIN_LINK_PASSWORD_CHANGE = domain + '/user/password';
export const API_USERS = domain + '/users';
export const API_DELETE_USER = domain + '/user/delete';
export const API_UNDELETE_USER = domain + '/user/undelete';
// export const API_USER_PAYMENT_PREFERENCES =
//   domain + '/user?meta=paymentPreferences';
export const API_USER_PAYMENT_PREFERENCES = domain + '/user/paymentPreferences';
export const API_MATCH = domain + '/match';
export const API_GET_MATCH = (matchId: string) => domain + `/match/${matchId}`;
export const API_GET_MATCHES = (itemId: string) =>
  domain + `/matches/${itemId}`;
export const API_GET_ACTIVE_SALES_MATCHES = (
  plasticType: string,
  form: string
) => domain + `/activeSales?plasticType=${plasticType}&form=${form}`;
export const API_GET_ITEMS = domain + '/item';
// export const API_GET_MY_ITEMS = (
//   userId: string,
//   type: ItemTypes = ItemTypes.Offer
// ) => domain + `/item?userId=${userId}&type=${type}`;

export const API_GET_MY_ITEMS = (filters: IGetItemsFilter) => {
  // const ____crazy: ItemTypes = ItemTypes.Offer; // The whole build freaks out if i remove this line!
  const allowedFilters = [
    'userId',
    'companyId',
    'type',
    'source',
    'plasticType',
    'orderBy',
    'orderDirection',
    'isDeleted',
    'isExternal',
    'status',
  ];
  const filterObject = Object.keys(filters)
    .filter((key) => allowedFilters.includes(key))
    .reduce((prev, current) => {
      return {
        ...prev,
        [current]: filters[current],
      };
    }, {});
  const queryString = stringify(filterObject);
  return domain + `/item?${queryString}`;
};

export const API_GET_MY_ITEMS_MAP = (filters: IGetItemsFilterMap) => {
  // const ____crazy: ItemTypes = ItemTypes.Offer; // The whole build freaks out if i remove this line!
  const allowedFilters = [
    'grade',
    'form',
    'color',
    'type',
    'source',
    'plasticType',
    'orderBy',
    'orderDirection',
    'isDeleted',
    'isExternal',
    'country',
  ];
  const filterObject = Object.keys(filters)
    .filter((key) => allowedFilters.includes(key))
    .reduce((prev, current) => {
      if (!filters[current]) {
        return prev;
      }

      return {
        ...prev,
        [current]: filters[current],
      };
    }, {});

  const queryString = stringify(filterObject);
  return domain + `/item/map?${queryString}`;
};
export const API_GET_ITEM = (itemId: string, isDelete?: boolean) =>
  domain + `/item/${itemId}${isDelete ? `?isDelete=${isDelete}` : ''}`;

export const API_GET_DRAFT_ITEM = (itemId: string, status?: ItemStatus) =>
  domain + `/item/${itemId}${status ? `?status=${status}` : ''}`;

export const API_GET_ITEM_AND_MATCH = (
  itemId: string,
  matchItemId: string,
  isDelete: boolean
) => domain + `/item/${itemId}?matchItemId=${matchItemId}&isDelete=${isDelete}`;
export const API_GET_EITEM_AND_MATCH = (itemId: string, matchItemId: string) =>
  domain + `/eitem/${itemId}?matchItemId=${matchItemId}`;

export const API_UPDATE_ITEM = (itemId: string) => domain + `/item/${itemId}`;
export const API_CREATE_ITEM = domain + '/item';
export const API_PUBLISH_ITEM = (itemId: string) =>
  domain + `/item/${itemId}/publish`;

export const API_DELETE_ITEMS = (itemIds: string[]) =>
  domain + `/item/${itemIds.join(',')}`;

export const API_CREATE_OFFER = domain + '/offer';
export const API_GET_OFFER = (offerId: string) => domain + `/offer/${offerId}`;

export const API_SEND_MATCH_EMAIL = (itemId: string) =>
  domain + `/match/sendEmail?id=${itemId}`;

export const API_GET_SEARCH_ITEMS = (query: string, plasticType: string) => {
  return (
    domain +
    `/search?${query ? 'q=' + query : 'q='}${
      plasticType ? '&plasticType=' + plasticType : ''
    }`
  );
};

export const API_GET_ESEARCH_ITEMS = (
  query: string,
  plasticType: string,
  noLimit = false
) => {
  return (
    domain +
    `/esearch?${query ? 'q=' + query : 'q='}${
      plasticType ? '&plasticType=' + plasticType : ''
    }${noLimit ? '&noLimit=true' : ''}`
  );
};

export const API_UPLOAD_FILE = domain + '/file';
export const API_GET_FILE = (fileId: string) => domain + `/file/${fileId}`;
export const API_GET_IMAGE = (
  fileId: string,
  fileName: string,
  width: ImageSize
) =>
  imageDomain +
  `/file/${fileId}/image/${encodeURIComponent(fileName)}?w=${width}`;

export const API_GET_EXTERNAL_IMAGE = (file: string, width: ImageSize) =>
  imageDomain + `/file/e?file=${file}&w=${width}`;

export const URL_VIP = (itemId: string) => {
  return `/item/${itemId}`;
};

export const API_CHECK_EMAIL = (email: string) =>
  domain + '/user/isregistered/?email=' + email;

export const API_CREATE_ORDER = domain + '/order';
export const API_GET_RELATED_ORDERS_FOR_ITEM = (
  itemId: string,
  orderStatus: OrderStatus = null
) =>
  domain +
  `/orders/${itemId}${orderStatus ? `?orderStatus=${orderStatus}` : ''}`;
export const API_CONFIRM_ORDER = domain + '/order';
export const API_GET_MY_ORDERS = domain + '/order';
export const API_GET_ORDERS = (itemId) => domain + `/orders/${itemId}`;
export const API_GET_ORDER = (orderId) => domain + `/order/${orderId}`;
export const API_GET_ORDER_RESPONSE = (orderId: string) =>
  domain + `/order/response/${orderId}`;
export const API_ACCEPT_ORDER = (orderId: string) =>
  domain + `/order/accept/${orderId}`;
export const API_DECLINE_ORDER = (orderId: string) =>
  domain + `/order/decline/${orderId}`;
export const API_CANCEL_ORDER = (orderId: string) =>
  domain + `/order/cancel/${orderId}`;

export const API_GET_SOLD = domain + '/sold';

export const API_GET_DISCOVER_PLASTICS = domain + `/discover/plastics`;

export const API_LOGIN_LINK = (loginInfo: ILinkLoginData) => {
  return (
    domain +
    `/user/loginLink/${loginInfo.userId}/${
      loginInfo.linkId
    }/${encodeURIComponent(loginInfo.token)}`
  );
};

export const API_SEND_LOGIN_LINK = (username: string) => {
  return domain + `/user/getLoginLink?username=${username}`;
};

export const API_CREATE_ACTIVITY = domain + `/activity`;
export const API_CREATE_ACTIVITY_REPLY = (activityId: string) =>
  domain + `/activity/${activityId}`;
export const API_GET_ACTIVITIES = domain + `/activity`;
export const API_GET_ACTIVITY = (activityId: string) =>
  domain + `/activity/${activityId}`;

export const API_GET_ORDER_EXP = domain + `/order/confirm`;
export const API_REQUEST_SAMPLE = domain + `/materialTest`;
export const API_GET_MATERIAL_TEST = (materialTestId: string) => {
  return domain + `/materialTest/${materialTestId}`;
};
export const API_REQUEST_IMAGES = domain + `/actions/requestImages`;
export const API_ASK_QUESTIONS = domain + `/actions/contactSupplier`;
export const API_ASK_QUESTIONS_EXTERNAL =
  domain + `/actions/contactSupplierExternal`;

export const API_EXTERNAL_ORDER_REQUEST =
  domain + `/actions/contactSupplierOrderRequest`;

export const API_REQUEST_COMPANY_INFORMATION =
  domain + `/actions/requestInformation`;

export const API_REQUEST_CERTIFICATE = domain + `/actions/requestCertificate`;

export const API_REFRESH_HEADERS = domain + '/refresh';

export const API_GET_INVOICE = (invoiceId: string) =>
  domain + `/invoice/${invoiceId}`;
export const API_GET_INVOICES_FOR_USERS = domain + '/invoice';
export const API_GENERATE_INVOICE = domain + '/invoice/generate';

export const API_GET_PRICE = domain + `/price`;
export const API_GET_LOG = domain + `/log`;
export const API_POST_MAP_LOG = domain + `/log/map`;

export const WEB_ORDER = (orderId: string) => webDomain + `/order/${orderId}`;
export const WEB_ORDER_RESPONSE = (orderId: string) =>
  webDomain + `/order-response/${orderId}`;

export const WEB_ORDER_RESPONSE_DECLINE = (orderId: string) =>
  WEB_ORDER_RESPONSE(orderId) + '#onLoad=decline';

export const API_GET_GRAPH_DATA = (params) => {
  return domain + `/item/getGraphData?matchParams=${JSON.stringify(params)}`;
};

export const API_POST_OTP_DATA = domain + `/user/postOTPData`;

export const API_CHECK_OTP = domain + `/user/checkOTP`;

export const API_DELETE_OTP = domain + `/user/deleteOTPData`;

export const API_GET_PRICE_INDEX_OPTIONS = (
  plasticType: string,
  recycled: boolean
) => {
  return (
    domain +
    `/item/priceIndexOptions?plasticType=${plasticType}&recycled=${recycled}`
  );
};

const queryStringify = (params) => {
  return Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
};

export const API_LOGISTICS_GET_PRICE = (params: ILogisticsParams) => {
  return domain + `/logistics/getPrice?${queryStringify(params)}`;
};

export const API_POST_FEEDBACK = domain + `/feedback`;

export const API_ACCEPT_TERMS = domain + `/user/acceptTerms`;

export const API_GET_COMPANY = (companyId: string) => {
  return domain + `/company/${companyId}`;
};

export const API_COMPANY_NOTES = (companyId: string) => {
  return domain + `/company/${companyId}/notes`;
};

export const API_COMPANY_PREFERENCES = (companyId: string) => {
  return domain + `/company/${companyId}/preferences`;
};

export const API_COMPANY_PARTNER_DATA = (companyId: string) => {
  return domain + `/company/${companyId}/partnerData`;
};

export const API_COMPANY_ITEMS = (companyId: string) => {
  return domain + `/company/${companyId}/items`;
};

export const API_COMPANY_PROFILE = (companyId: string) => {
  return domain + `/profile/${companyId}`;
};

export const API_GET_ENABLED_COMPANY_IDS = domain + '/company/enabledIds';

export const API_GET_INVENTORY = (userId: string, type: string) => {
  return domain + `/item/inventory?user=${userId}&type=${type}`;
};

export const API_GET_MESSAGES = domain + '/message';

export const API_GET_MESSAGES_COMPANY_ID = (companyId: string) => {
  return domain + `/message/${companyId}`;
};

export const API_POST_MESSAGE_COMPANY_ID = (companyId: string) => {
  return domain + `/message/${companyId}`;
};

export const API_POST_MESSAGE_ID_WITHDRAW = (messageId: string) => {
  return domain + `/message/${messageId}/withdraw`;
};

export const API_POST_MESSAGE_ID_DECLINE = (messageId: string) => {
  return domain + `/message/${messageId}/decline`;
};

export const API_POST_MESSAGE_ID_ACCEPT = (messageId: string) => {
  return domain + `/message/${messageId}/accept`;
};

export const API_ADMIN_INVOICE_ITEMS = domain + `/admin/invoice`;

export const API_ADMIN_INVOICE_ITEMS_DONE = (itemId: string) => {
  return domain + `/admin/invoice/${itemId}/done`;
};

export const API_GET_COUPONS = domain + `/coupon`;

export const API_GET_COUPON = (coupon: string) => {
  return domain + `/coupon/${coupon}`;
};

export const API_DELETE_COUPON = (couponId: string) => {
  return domain + `/coupon/${couponId}`;
};

export const API_GET_MY_ADDRESSES = domain + '/address';
export const API_FIND_OR_CREATE_ADDRESS = domain + '/address';

export const API_CREATE_SHIPPING_REQUEST_RESPONSE =
  domain + '/shippingRequestResponse';

export const API_BLOG_URL = domain + '/blogs';
export const BLOG_FEED_URL = 'https://www.atomler.com/blog-feed.xml';
export const BLOG_PAGE_URL = 'https://www.atomler.com/blog';

export const GET_SHIPPING_REQUEST = (
  messageId: string,
  serviceProviderId: string
) =>
  domain +
  `/shippingRequest/${messageId}?serviceProviderId=${serviceProviderId}`;

export const GET_SHIPPING_REQUEST_LIST_URL = (
  registered: boolean,
  serviceProviderId: string
) =>
  domain +
  `/shippingRequest?registered=${registered}&serviceProviderId=${serviceProviderId}`;

export const GET_SHIPPING_REQUEST_RESPONSE_URL = (
  shippingRequestResponseId: string
) => {
  return domain + `/shippingRequestResponse/${shippingRequestResponseId}`;
};

export const API_SHIPPING_REQUEST_PDF = (
  shippingRequestId,
  serviceProviderId
) =>
  domain +
  `/shippingRequest/${shippingRequestId}/pdf?serviceProviderId=${serviceProviderId}`;

export const API_CREATE_CUSTOM_OFFER = domain + '/customoffer';
export const API_EXTEND_CUSTOM_OFFER = (customOfferId: string) =>
  domain + '/customoffer/' + customOfferId + '/extend';
export const SERVICE_PROVIDER_LOGIN = domain + '/serviceProviders/login';

export const GET_MATERIAL_QUALITY_SAMPLE_LIST_URL = (
  serviceProviderId: string,
  finished: boolean
) =>
  domain +
  `/materialQualitySample?serviceProviderId=${serviceProviderId}&finished=${finished}`;

export const GET_MATERIAL_QUALITY_SAMPLE_URL = (id: string, state: string) =>
  domain + `/materialQualitySample/${id}?state=${state}`;

export const REGISTER_MATERIAL_QUALITY_URL = (id: string) =>
  domain + `/materialQuality/${id}`;

export const CLEAN_REGISTER_MATERIAL_QUALITY_URL = (id: string) =>
  domain + `/materialQuality/${id}/clean`;

export const ADD_DATASHEET_MATERIAL_QUALITY_URL = (id: string) =>
  domain + `/materialQuality/${id}/datasheet`;

export const GET_SERVICE_PROVIDERs_LIST = (type: ServiceProvidersType) => {
  return domain + `/serviceProviders?type=${type}`;
};

export const CREATE_MATERIAL_QUALITY_SAMPLE_URL = () =>
  domain + `/materialQualitySample`;

export const UPDATE_MATERIAL_QUALITY_SAMPLE_URL = (id: string) =>
  domain + `/materialQualitySample/${id}`;
