import { getOptionsLabelFromValue, IReadonlyMap } from './index';
import { mapValueWithLabel } from './mappers';

export enum ItemPlasticTypeValues {
  ABS = 'abs',
  EPS = 'eps',
  HDPE = 'hdpe',
  LDPE = 'ldpe',
  LLDPE = 'lldpe',
  PA = 'pa',
  PA6 = 'pa6',
  PA66 = 'pa66',
  PBT = 'pbt',
  PC = 'pc',
  PC_ABS = 'pc_abs',
  PE_ABS = 'pe_abs',
  // PE = 'pe',
  PES = 'pes',
  PET = 'pet',
  PMMA = 'pmma',
  PP = 'pp',
  PP_Copo = 'pp-copo',
  PP_Homo = 'pp-homo',
  PS = 'ps',
  PVC = 'pvc',
  XPS = 'xps',
  Other = 'other',
}

const itemPlasticTypeLabels: IReadonlyMap<ItemPlasticTypeValues, string> =
  new Map([
    [ItemPlasticTypeValues.ABS, 'ABS'],
    [ItemPlasticTypeValues.EPS, 'EPS'],
    [ItemPlasticTypeValues.HDPE, 'HDPE'],
    [ItemPlasticTypeValues.LDPE, 'LDPE'],
    [ItemPlasticTypeValues.LLDPE, 'LLDPE'],
    [ItemPlasticTypeValues.PA, 'PA'],
    [ItemPlasticTypeValues.PA6, 'PA6'],
    [ItemPlasticTypeValues.PA66, 'PA66'],
    [ItemPlasticTypeValues.PBT, 'PBT'],
    [ItemPlasticTypeValues.PC, 'PC'],
    [ItemPlasticTypeValues.PC_ABS, 'PC/ABS'],
    [ItemPlasticTypeValues.PE_ABS, 'PE/ABS'],
    // [ItemPlasticTypeValues.PE, 'PE'],
    [ItemPlasticTypeValues.PES, 'PES'],
    [ItemPlasticTypeValues.PET, 'PET'],
    [ItemPlasticTypeValues.PMMA, 'PMMA'],
    [ItemPlasticTypeValues.PP, 'PP'],
    [ItemPlasticTypeValues.PP_Copo, 'PP Copo'],
    [ItemPlasticTypeValues.PP_Homo, 'PP Homo'],
    [ItemPlasticTypeValues.PS, 'PS'],
    [ItemPlasticTypeValues.PVC, 'PVC'],
    [ItemPlasticTypeValues.XPS, 'XPS'],
    [ItemPlasticTypeValues.Other, 'Other'],
  ]);

export const plasticTypeOptions = mapValueWithLabel(
  ItemPlasticTypeValues,
  itemPlasticTypeLabels
);

export const getPlasticOptionsLabel = (value: ItemPlasticTypeValues) =>
  getOptionsLabelFromValue(plasticTypeOptions, value);

export const getPlasticTypeOptions = (newItem) => {
  return plasticTypeOptions.map((item) => {
    return {
      ...item,
      checked: newItem.attr.plasticType === item.value,
    };
  });
};

export const getPlasticTypeOptionsPriceIndex = (newItem) => {
  return plasticTypeOptions.map((item) => {
    return {
      ...item,
      checked: newItem.plasticType === item.value,
    };
  });
};

export const getPlasticTypeOptionsSignUp = (newItem) => {
  return plasticTypeOptions.map((item) => {
    return {
      ...item,
      checked: newItem.interestedMaterials === item.value,
    };
  });
};
