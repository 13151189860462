﻿import { ICouponCompanyStatistics, IServiceFeeDataFixed } from '@@types';
import { ItemPlasticTypeValues } from './item/plasticTypeOptions';
import { ItemColorValues } from './item/colorOptions';
import { ItemGradeValues } from './item/gradeOptions';
import { SERVICE_FEE_PERCENTAGE } from '../constants/misc';
import { roundTwoDecimals } from './number-helper';

const vatPercentages = {
  SE: 25,
};

export const getVatPercentage = (countryIso: string): number => {
  const vatPercentage = vatPercentages[countryIso];
  return vatPercentage ? vatPercentage : 0;
};

export const getVat = (sumNoVat: number, vatPercent: number): number => {
  return roundTwoDecimals(sumNoVat * (vatPercent / 100));
};

export interface IServiceFee {
  // For old order service
  vatPercent: number;
  vat: number;
  extraVisibility: number;
  materialRegistration: number;
  sum: number;
  sumNoVat: number;
}

export const getMaterialOfferServiceFee = (
  materialRegistration: number,
  countryIso: string,
  extraVisibility = 0,
  coupon?: ICouponCompanyStatistics
): IServiceFeeDataFixed => {
  const vatPercent = getVatPercentage(countryIso);

  let sumNoVat = materialRegistration + extraVisibility;
  let discount = 0;
  if (coupon) {
    discount = sumNoVat > coupon.sumLeft ? coupon.sumLeft : sumNoVat;
    sumNoVat = sumNoVat - discount;
  }

  const vat = getVat(sumNoVat, vatPercent);
  return {
    vatPercent,
    vat,
    sumNoVat: sumNoVat,
    materialRegistration: materialRegistration,
    extraVisibility: extraVisibility,
    sum: roundTwoDecimals(sumNoVat + vat),
    couponData: {
      couponId: coupon?.couponId || null,
      discount,
    },
  };
};

export const getOrderServiceFee = (
  quantity: string,
  kgPrice: string,
  countryIso: string
): IServiceFee => {
  const vatPercent = getVatPercentage(countryIso);
  const sumNoVat = roundTwoDecimals(
    Number(quantity) * Number(kgPrice) * (SERVICE_FEE_PERCENTAGE / 100)
  );
  const vat = getVat(sumNoVat, vatPercent);
  const sum = roundTwoDecimals(sumNoVat + vat);

  return {
    vatPercent,
    vat,
    sum,
    sumNoVat,
    extraVisibility: 0,
    materialRegistration: 0,
  };
};

// for i in materialPrices
// filter i.plasticType != null
// filter i.color != null
// filter i.grade != null
// return DISTINCT({
// plasticType: i.plasticType,
// color: i.color,
// grade: i.grade
// })

// ldpe 	black 	extrusion_molding
// ldpe 	black 	injection_molding
// hdpe 	mixed 	blow_molding
// hdpe 	black 	injection_molding
// abs 	natural 	injection_molding
// abs 	natural 	extrusion_molding

// const materialsWithGraphs = [
//   [ItemPlasticTypeValues.LDPE, ItemColorValues.Black, ItemGradeValues.ExtrusionMolding],
//   [ItemPlasticTypeValues.LDPE, ItemColorValues.Black, ItemGradeValues.InjectionMolding],
//   [ItemPlasticTypeValues.HDPE, ItemColorValues.Mixed, ItemGradeValues.BlowMolding],
//   [ItemPlasticTypeValues.HDPE, ItemColorValues.Black, ItemGradeValues.InjectionMolding],
//   [ItemPlasticTypeValues.ABS, ItemColorValues.Natural, ItemGradeValues.InjectionMolding],
//   [ItemPlasticTypeValues.ABS, ItemColorValues.Natural, ItemGradeValues.ExtrusionMolding],
// ];

const materialsWithGraphs = [
  {
    plasticType: ItemPlasticTypeValues.LDPE,
    color: 'black', //ItemColorValues.Black doesn't work here for some reason
    grade: ItemGradeValues.ExtrusionMolding,
  },
  {
    plasticType: 'ldpe',
    color: 'black',
    grade: 'injection_molding',
  },
  {
    plasticType: 'hdpe',
    color: 'mixed',
    grade: 'blow_molding',
  },
  {
    plasticType: 'hdpe',
    color: 'black',
    grade: 'injection_molding',
  },
  {
    plasticType: 'abs',
    color: 'natural',
    grade: 'injection_molding',
  },
  {
    plasticType: 'abs',
    color: 'natural',
    grade: 'extrusion_molding',
  },
];

interface IHasPriceGraph {
  plasticType: ItemPlasticTypeValues;
  color: ItemColorValues;
  grade: ItemGradeValues;
}

export const hasPriceGraph = ({
  plasticType,
  color,
  grade,
}: IHasPriceGraph) => {
  return materialsWithGraphs.find((i) => {
    return (
      plasticType === i.plasticType && color === i.color && grade === i.grade
    );
  });
};
