import { useEffect, useState } from 'preact/hooks';
import { h } from 'preact';
import { SmallButton } from './ui/Button';
import { postFeedback } from 'api/user';

const floatingStyles = {
  position: 'fixed',
  bottom: '30px',
  left: '30px',
  zIndex: '1300', // Snackbar is 1400
  display: 'block',
};

const floatingBoxStyles = {
  backgroundColor: '#E3E1DD',
  fontSize: '1em',
  margin: '10px',
  marginLeft: '0px',
  padding: '10px',
  borderRadius: '25px',
  width: 'fit-content',
};

const feedbackQuestionStyles = {
  verticalAlign: 'middle',
  display: 'inline-block',
  margin: '5px',
};

const feedbackOptionStyles = {
  display: 'inline-block',
  verticalAlign: 'middle',
  cursor: 'pointer',
  fontSize: '1.5em',
  margin: '5px',
};

const floatingIconStyles = {
  fontSize: '1em',
  display: 'block',
  cursor: 'pointer',
  float: 'left',
};

const handleAnswerSubmit = (
  finalObject,
  setFinalObject,
  question,
  answer,
  page,
  showFilled,
  setFilled,
  index
) => {
  const currentObject = { ...finalObject };
  currentObject.questions.push({ question: question, answer: answer });
  setFinalObject(currentObject);

  const fillStatus = showFilled;
  fillStatus[index] = true;
  setFilled(fillStatus);

  const updatedFillStatus = showFilled;
  const checker = (arr) => arr.every(Boolean);
  if (checker(updatedFillStatus)) {
    const currentObject = { ...finalObject };
    currentObject['page'] = page;
    postFeedback(currentObject);
  }
};

const FeedbackQuestion = ({
  finalObject,
  setFinalObject,
  handleAnswerSubmit,
  question,
  answers,
  page,
  showFilled,
  setFilled,
  index,
}) => {
  const emojiMapping = {
    yes: String.fromCodePoint(0x1f600),
    no: String.fromCodePoint(0x1f614),
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
  };
  const isLastIndex = showFilled.lastIndexOf(true) === showFilled.length - 1;
  const lastItem = index === showFilled.length - 1;
  return (
    <div>
      {showFilled[index] ? (
        isLastIndex && lastItem ? (
          <div style={floatingBoxStyles}>
            <div className="feedback-question" style={feedbackQuestionStyles}>
              Thank you for your feedback!
            </div>
          </div>
        ) : null
      ) : (
        <div style={floatingBoxStyles}>
          <div className="feedback-question" style={feedbackQuestionStyles}>
            {question}
          </div>
          {answers.map((answer) => (
            <div
              className="feedback-option"
              style={feedbackOptionStyles}
              onClick={() => {
                handleAnswerSubmit(
                  finalObject,
                  setFinalObject,
                  question,
                  answer,
                  page,
                  showFilled,
                  setFilled,
                  index
                );
              }}
            >
              {' '}
              <span role="img">{emojiMapping[answer]}</span>{' '}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const EmojionalFeedback = ({
  commonQuestions = { 'How would you rate Atomler?': ['yes', 'no'] },
  overrideQuestions = {},
  page,
}) => {
  const questions =
    Object.keys(overrideQuestions).length !== 0
      ? overrideQuestions
      : commonQuestions;
  const [showPrompts, setShowPrompts] = useState(false);
  const [showFilled, setFilled] = useState(
    new Array(Object.keys(questions).length).fill(false)
  );
  const [finalObject, setFinalObject] = useState({ questions: [] });

  return (
    <div
      style={floatingStyles}
      onMouseEnter={() => setShowPrompts(true)}
      onMouseLeave={() => setShowPrompts(false)}
    >
      {showPrompts ? (
        <div>
          {Object.entries(questions).map(([question, answers], index) => (
            <FeedbackQuestion
              finalObject={finalObject}
              setFinalObject={setFinalObject}
              handleAnswerSubmit={handleAnswerSubmit}
              question={question}
              answers={answers}
              page={page}
              showFilled={showFilled}
              setFilled={setFilled}
              index={index}
            />
          ))}
        </div>
      ) : null}
      <div id="floating-icon" style={floatingIconStyles}>
        <SmallButton>Give us feedback!</SmallButton>
      </div>
    </div>
  );
};

export default EmojionalFeedback;
