﻿import { getOptionsLabelFromValue } from './index';

export enum FreeOnPickupValues {
  Yes = 'yes',
  No = 'no',
}

export const freeOnPickupOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

export const getFreeOnPickupOptions = (newItem) => {
  return freeOnPickupOptions.map((item) => {
    return {
      ...item,
      checked: newItem.samplesAvailable === item.value,
    };
  });
};

export const getFreeOnPickupLabel = (value: string) =>
  getOptionsLabelFromValue(freeOnPickupOptions, value);
