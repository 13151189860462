﻿import { IDBItem, IOfferAttributes, IValidationError } from '@@types';
export { default as actions } from './actions';

export interface IMatchResult {
  creditedItemIds: string[];
  match: any; // IMatch?
  offerItem: IDBItem;
  requestItem: IDBItem;
}

export interface IModel {
  fetching: boolean;
  matchData?: IMatchResult;
  offer: IOfferAttributes;
  errors: IValidationError[];
}

export const defaultState: IModel = {
  fetching: false,
  matchData: null,
  offer: {},
};

export const state = () => {
  return {
    ...defaultState,
  };
};

export default state;
