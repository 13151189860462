﻿import createStore, { Store } from 'unistore';
import devtools from 'unistore/devtools';
import contactSupplier, { IModel as IContactSupplier } from './ContactSupplier';
import messages, { IModel as IMessages } from './Messages';
import itemView, { IModel as IItemView } from './ItemView';
import auth, { IModel as IAuth } from './Auth';
import editItem, { IModel as IEditItem } from './EditItem';
import sell, { IModel as ISell } from './Sell';
import buy, { IModel as IBuy } from './Buy';
import matcher, { IModel as IMatcher } from './Matcher';
import sellingTool, { IModel as ISellingTool } from './SellingTool';
import match, { IModel as IMatch } from './Match';
import itemMatches, { IModel as IItemMatches } from './ItemMatches';
import notifications, { IModel as INotification } from './Notification';
import priceIndex, { IModel as IPriceIndex } from './PriceIndex';
import profile, { IModel as IProfile } from './Profile';
import signUp, { IModel as ISignUp } from './SignUp';
import myItems, { IModel as IMyItems } from './MyItems';

// import sell, { IModel } from './Sell';
export interface IStore {
  auth: IAuth;
  contactSupplier: IContactSupplier;
  messages: IMessages;
  itemView: IItemView;
  editItem: IEditItem;
  sell: ISell;
  buy: IBuy;
  sellingTool: ISellingTool;
  matcher: IMatcher;
  match: IMatch;
  itemMatches: IItemMatches;
  notifications: INotification;
  priceIndex: IPriceIndex;
  profile: IProfile;
  signUp: ISignUp;
  myItems: IMyItems;
}

let cachedStore;

const createStoreWrapper = (initialState: IStore) => {
  return process.env.NODE_ENV === 'production'
    ? createStore<IStore>(initialState)
    : devtools(createStore<IStore>(initialState));
};

export const initStore = (initialState: IStore): Store<IStore> => {
  cachedStore = createStoreWrapper({
    auth: auth(initialState.auth),
    contactSupplier: contactSupplier(),
    messages: messages(),
    itemView: itemView(initialState?.itemView?.data),
    editItem: editItem(),
    sell: sell(),
    buy: buy(),
    sellingTool: sellingTool(),
    matcher: matcher(),
    match: match(),
    itemMatches: itemMatches(),
    notifications: notifications(),
    priceIndex: priceIndex(),
    profile: profile(),
    signUp: signUp(),
    myItems: myItems(),
  });
  return cachedStore;
};

export { cachedStore };
