﻿import { ICompany } from '@@types';
export { default as actions } from './actions';

export const steps = {
  DONE: 'done',
  FORM: 'form',
};

interface IServerError {
  errorCode: number;
  message?: string;
}

interface IValidationErrorLocal {
  errors: string[];
  message: string;
  path: string;
}

interface IUserForm {
  id?: string;
  name?: string;
  email?: string;
  company?: ICompany;
  new_password?: string;
  password_verify?: string;
  password?: string;
}

const defaultUserForm: IUserForm = {
  name: '',
  email: '',
  new_password: '',
  password_verify: '',
  password: '',
};

interface IPasswordForm {
  updating?: boolean;
  isPasswordChanged?: boolean;
  errors?: IValidationErrorLocal[];
  serverError?: IServerError;
  statusMessage?: string;
  new_password: string;
  password_verify: string;
}

const defaultPasswordForm: IPasswordForm = {
  updating: false,
  isPasswordChanged: false,
  errors: [],
  serverError: null,
  statusMessage: null,
  new_password: '',
  password_verify: '',
};

export interface IModel {
  fetching?: boolean;
  updating?: boolean;
  creating?: boolean;
  errors?: IValidationErrorLocal[];
  serverError?: IServerError;
  form?: IUserForm;
  passwordForm?: IPasswordForm;
  statusMessage?: string;
  step: 'done' | 'form';
}

export const defaultState: IModel = {
  fetching: false,
  updating: false,
  creating: false,
  errors: [],
  serverError: null,
  form: defaultUserForm,
  passwordForm: defaultPasswordForm,
  step: 'form',
};

export const state = (): IModel => {
  return {
    ...defaultState,
  };
};

export default state;
