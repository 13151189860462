﻿export const randomString = () =>
  Date.now().toString(36) + Math.random().toString(16).substr(2);

export const commaToPunctuation = (val: string) => {
  return val.replace ? val.replace(/,/g, '.') : val;
};

export const trimObject = <Type>(obj: Type): Type => {
  return Object.keys(obj).reduce((prev, current) => {
    const trimValue = obj[current] && typeof obj[current] === 'string';
    return {
      ...prev,
      [current]: trimValue ? obj[current].trim() : obj[current],
    };
  }, {}) as Type;
};

export const isEmptyObject = (obj: Record<string, unknown>): boolean => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const capitalizeFirstLetter = (str: string) => {
  if (typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const safeLowerCase = (stringValue: string | null) => {
  if (stringValue) {
    return stringValue.toLowerCase();
  }
  return null;
};

export const maxLength = (str: string, length: number, suffix: string) => {
  if (str.length > length) {
    return str.substring(0, length) + suffix;
  }
  return str;
};

export const removeEmptyFromObject = (obj: Record<string, unknown>) => {
  return Object.entries(obj)
    .filter(([_, v]) => v !== null && v !== undefined)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
};

export const isString = (value: any) => {
  return typeof value == 'string' || toString.call(value) == '[object String]';
};

export const safeTrim = (str: any) => {
  if (isString(str)) {
    return str.trim();
  }
  return str;
};

export const firstWord = (text: string) => {
  return typeof text === 'string' ? text.split(' ')[0] : text;
};
