import { h } from 'preact';
import { Provider as UnistoreProvider } from 'unistore/preact';
import * as DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@@helpers/material-ui/pickers';
import { ThemeProvider } from '@@helpers/material-ui/core/styles';
import Router from 'preact-router';

import {
  Home,
  Sell,
  // Selling,
  Item,
  SignUp,
  Profile,
  Terms,
  Privacy,
  MaterialListingPolicy,
  ResultList,
  Orders,
  // Sold,
  LinkLogin,
  Messages,
  // Message,
  Logistics,
  Error,
  EditItem,
  Buy,
  // Buying,
  // Match,
  SellingTool, // Should probably be split out to its own file
  Matcher,
  Users,
  // MaterialTest,
  RequestSample,
  OrderSummary,
  OrderResponse,
  Invoices,
  ItemExternal,
  FindExternal,
  Log,
  Icons,
  Styles,
  Store,
  // CompanyMap,
  Inventory,
  Map,
  SirMatchAlot,
  // SimpleSell,
  // Confirm
  // PriceIndex
  AdminInvoices,
  AdminCoupons,
  ShippingTerminal,
  ShippingTerminalResponse,
  LabTerminal,
  LabTerminalRegister,
  LabTerminalDatasheet,
  MaterialQualityDatasheet,
  LabTerminalCompanyList,
  QualityTestTerms,
  ActiveSales,
} from '../containers';
import locale from 'date-fns/locale/en-US';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

const pickerLocale = {
  ...locale,
  options: {
    ...locale.options,
    weekStartsOn: 1,
  },
};

const Main = ({ unistoreStore, theme, handleRoute }) => {
  const gtmParams = { id: 'GTM-N7LSG9C' };
  return (
    <UnistoreProvider store={unistoreStore}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider
          utils={DateFnsUtils.default}
          locale={pickerLocale}
        >
          <GTMProvider state={gtmParams}>
            <Router onChange={handleRoute}>
              <Home key="home" path="/" />
              <Item key="item" path="/item/:itemUrlPath/:matchItemId?" />
              <ItemExternal
                key="itemExternal"
                path="/eitem/:itemUrlPath/:matchItemId?"
              />
              <RequestSample path="/request-sample/:materialTestId" />
              {/* <Match key="item" path="/match/:matchId" /> */}
              <EditItem key="item" path="/item/:itemId/edit" />
              <Sell path="/sell" />
              <Sell path="/sell/:itemId" />
              {/* <SimpleSell path="/sell-new" />
                <SimpleSell path="/sell-new-detailed" /> */}
              {/* <Selling path="/selling" />
                <Buying path="/buying" /> */}
              <Orders path="/orders" />
              {/* <MaterialTest path="/material-test/:materialTestId" /> */}
              {/* <Sold path="/sold" /> */}
              <Profile path="/profile" />
              <SignUp key="signUp" path="/sign-up" />
              <Terms path="/terms-and-conditions" />
              <Privacy path="/privacy-policy" />
              <QualityTestTerms path="/quality-certification-term" />
              <MaterialListingPolicy path="/material-listing-policy" />
              <ResultList key="resultList" path="/findlist" />
              <LinkLogin path="/loginLink/:userId/:linkId/:token" />
              <Messages path="/messages/:companyId?/:tab?" />
              {/* <Message path="/messages/:activityId" /> */}
              <Buy path="/buy" />
              <AdminInvoices path="/admin/invoices" />
              <AdminCoupons path="/admin/coupons" />
              <SellingTool path="/admin/selling" />
              <Matcher path="/admin/matcher" />
              <Users path="/admin/users" />
              <Logistics path="/admin/logistics" />
              <OrderSummary path="/order/:orderId" />
              <OrderResponse path="/order-response/:orderId" />
              <Invoices path="/invoices" />
              <FindExternal path="/efindlist" />
              <Log path="/log" />
              <Icons path="/icons" />
              <Styles path="/styles" />
              {/* <PriceIndex path="/price-index" /> */}
              <Error default />
              <Store path="/company/:companyUrlPath" />
              <Inventory path="/inventory/:pageId?" />
              <ShippingTerminal path="/shipping-terminal/:serviceProviderId/:pageId?" />
              <ShippingTerminalResponse path="/shipping-terminal/:serviceProviderId/response/:messageId" />
              <LabTerminalCompanyList path="/lab-terminal" />
              <LabTerminal path="/lab-terminal/:serviceProviderId/:pageId?" />
              <LabTerminalRegister path="/lab-terminal/:serviceProviderId/register/:trackingId" />
              <LabTerminalDatasheet path="/lab-terminal/:serviceProviderId/datasheet/:trackingId" />
              <MaterialQualityDatasheet path="/material-quality/:trackingId" />
              {/* <CompanyMap path="/companymap" /> */}
              <Map path="/find/:plasticType?" />
              <SirMatchAlot path="/matches/:itemId?" />
              <ActiveSales path="active-sales/:itemId?" />
              {/* <Confirm path="/confirm" /> */}
            </Router>
          </GTMProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </UnistoreProvider>
  );
};

export default Main;
