﻿import { h, FunctionComponent } from 'preact';
import { useTheme } from '@@helpers/material-ui/core/styles';
import { useComponentId } from 'hooks/useComponentId';

interface IProps {
  variant?: 'dark' | 'light' | 'sand' | 'pearl';
  width?: number | string;
  class?: string;
  style?: any;
  componentId?: string;
}

const BrandingSymbol: FunctionComponent<IProps> = ({
  componentId,
  variant = 'dark',
  width = 200,
  class: className,
  style = {},
}) => {
  const theme = useTheme();
  const clipId = `#clip_${componentId || useComponentId()}`;

  const colorCodes = {
    dark: theme.palette.black.main,
    light: theme.palette.white.main,
    sand: theme.palette.sand.main,
    pearl: theme.palette.pearl.main,
  };

  const color = colorCodes[variant];

  const newStyle = {
    'max-width': '100%',
    ...style,
  };

  return (
    <svg
      class={className}
      width={width}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={newStyle}
    >
      <g clip-path={`url(${clipId})`}>
        <path
          d="M119.233 75.7335C126.533 71.6335 132.567 65.2001 136.1 56.8668C144.3 37.4335 135.4 14.7335 116.167 6.06681C96.2667 -2.89985 72.9334 6.16682 64.2667 26.1001C60.5334 34.6668 60.1334 43.8335 62.4667 52.1668V52.2001L66.3334 67.1002C71.3334 86.3001 62.6667 106.467 45.2667 116.033C45.2667 116.033 32.8 122.867 32.6 122.967C24.4 126.867 17.5 133.667 13.5667 142.667C4.93336 162.533 14.1334 185.667 34.1 194.167C53.9 202.6 76.6667 193.533 85.2334 173.8C89.0667 165 89.3667 155.567 86.8334 147.033L83.1667 132.5C78.2667 113.233 87.1 93.1002 104.567 83.6668L119.233 75.7335Z"
          fill={color}
        />
        <path
          d="M178.183 186.549C193.439 171.292 193.439 146.557 178.183 131.301C162.926 116.044 138.191 116.044 122.934 131.301C107.678 146.557 107.678 171.292 122.934 186.549C138.191 201.805 162.926 201.805 178.183 186.549Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <rect width={width} height={width} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BrandingSymbol;
