﻿import { h, FunctionComponent } from 'preact';
import { Modal, ModalBody, ModalHeader } from 'components/modal/Modal';

interface IProps {
  title?: string;
  src: string;
  handleCloseModal: () => void;
  height?: number | string;
  width?: number | string;
}

export const IFrameModal: FunctionComponent<IProps> = ({
  title,
  src,
  handleCloseModal,
  height = 100,
  width = 100,
}) => {
  return (
    <Modal onClose={handleCloseModal}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <iframe src={src} height={height} width={width} frameBorder="0" />
      </ModalBody>
    </Modal>
  );
};

export default IFrameModal;
