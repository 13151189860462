﻿import { Store, BoundAction } from 'unistore';
import { IStore } from 'shared/unistore';
import { IModel, defaultState } from './';
import { getItem, deleteItem, getItemAndMatch } from 'api/item';
import { IDBItem } from '@@types';

export interface IActions {
  reset: BoundAction;
  fetchItem: BoundAction;
  deleteItem: BoundAction;
}

export const reset = () => {
  return {
    itemView: defaultState,
  };
};

const actions = (store: Store<IStore>) => ({
  reset,
  // getMessage: async ({ messages }: { messages: IModel }, activityId: string) => {

  fetchItem: async (
    { itemView }: { itemView: IModel },
    itemId: string,
    matchItemId = ''
  ) => {
    store.setState({
      itemView: {
        ...itemView,
        fetching: true,
      },
    });

    let result: any;
    let item: IDBItem;
    let matchItem: IDBItem;
    try {
      if (matchItemId) {
        result = await getItemAndMatch(itemId, matchItemId);
      } else {
        result = await getItem(itemId, true);
      }
      item =
        result && result.data && result.data.item ? result.data.item : null;
      matchItem =
        result && result.data && result.data.matchItem
          ? result.data.matchItem
          : null;
      store.setState({
        itemView: {
          ...store.getState().itemView,
          data: item,
          matchData: matchItem,
          fetching: false,
          authorized: true,
        },
      });
    } catch (error) {
      console.log('error fetching item', itemId, error.response.status);
      store.setState({
        itemView: {
          ...store.getState().itemView,
          fetching: false,
          authorized: error.response.status == 401 ? false : true,
        },
      });
    }

    store.setState({
      itemView: {
        ...store.getState().itemView,
        fetching: false,
      },
    });
  },
  deleteItem: async ({ itemView }: { itemView: IModel }, itemId: number) => {
    store.setState({
      itemView: {
        ...itemView,
        deleting: true,
      },
    });

    let result: any;
    try {
      result = await deleteItem(itemId);
    } catch (error) {
      console.log('error deleting item', itemId, error);
    }
    store.setState({
      itemView: {
        ...itemView,
        deleting: false,
      },
    });

    const isDeleted = result && result.data === true;
    const newData = { ...itemView.data };
    newData.deleted = true;
    if (isDeleted) {
      store.setState({
        itemView: {
          ...itemView,
          data: newData,
        },
      });
    }
  },
});

export default actions;
