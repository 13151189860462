﻿import { fetcher } from '@@helpers/fetch-helper';
import {
  API_CREATE_ACTIVITY,
  API_GET_ACTIVITIES,
  API_GET_ACTIVITY,
  API_CREATE_ACTIVITY_REPLY,
} from '@@constants/url';

export enum ActivityTypes {
  Message = 'Message',
}

export interface ICreateActivityData {
  activityType: ActivityTypes;
  toUserId: string;
  message: string;
}

export interface ICreateActivityReplyData {
  activityType: ActivityTypes;
  replyToActivityId: string;
  message: string;
}

export const createActivity = async (data: ICreateActivityData) => {
  return await fetcher.post(API_CREATE_ACTIVITY, data, {
    withCredentials: true,
  });
};

export const createActivityReply = async (data: ICreateActivityReplyData) => {
  return await fetcher.post(
    API_CREATE_ACTIVITY_REPLY(data.replyToActivityId),
    data,
    { withCredentials: true }
  );
};

export const getActivities = async () => {
  return await fetcher.get(API_GET_ACTIVITIES, { withCredentials: true });
};

export const getActivity = async (activityId: string) => {
  return await fetcher.get(API_GET_ACTIVITY(activityId), {
    withCredentials: true,
  });
};
