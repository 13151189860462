import { h } from 'preact';

interface IProps extends h.JSX.SVGAttributes<SVGSVGElement> {
  primaryColor?: string;
  secondaryColor?: string;
  size?: number;
}

export const Listmat = (props: IProps) => (
  <svg
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      d="M5.83333 2.16602H14.1667C15.7315 2.16602 17 3.43454 17 4.99935V15.8327C17 17.3975 15.7315 18.666 14.1667 18.666H5.83333C4.26853 18.666 3 17.3975 3 15.8327V4.99935C3 3.43454 4.26853 2.16602 5.83333 2.16602Z"
      stroke="black"
    />
    <path
      d="M6.66667 8.33268C7.12691 8.33268 7.5 7.95959 7.5 7.49935C7.5 7.03911 7.12691 6.66602 6.66667 6.66602C6.20643 6.66602 5.83334 7.03911 5.83334 7.49935C5.83334 7.95959 6.20643 8.33268 6.66667 8.33268Z"
      fill="#787878"
    />
    <path
      d="M10 6.66602C9.53977 6.66602 9.16667 7.03911 9.16667 7.49935C9.16667 7.95959 9.53977 8.33268 10 8.33268H13.3333C13.7936 8.33268 14.1667 7.95959 14.1667 7.49935C14.1667 7.03911 13.7936 6.66602 13.3333 6.66602H10Z"
      fill="#787878"
    />
    <path
      d="M10 9.99935C9.53977 9.99935 9.16667 10.3724 9.16667 10.8327C9.16667 11.2929 9.53977 11.666 10 11.666H13.3333C13.7936 11.666 14.1667 11.2929 14.1667 10.8327C14.1667 10.3724 13.7936 9.99935 13.3333 9.99935H10Z"
      fill="#787878"
    />
    <path
      d="M9.16667 14.166C9.16667 13.7058 9.53977 13.3327 10 13.3327H11.6667C12.1269 13.3327 12.5 13.7058 12.5 14.166C12.5 14.6263 12.1269 14.9993 11.6667 14.9993H10C9.53977 14.9993 9.16667 14.6263 9.16667 14.166Z"
      fill="#787878"
    />
    <path
      d="M7.5 10.8327C7.5 11.2929 7.12691 11.666 6.66667 11.666C6.20643 11.666 5.83334 11.2929 5.83334 10.8327C5.83334 10.3724 6.20643 9.99935 6.66667 9.99935C7.12691 9.99935 7.5 10.3724 7.5 10.8327Z"
      fill="#787878"
    />
    <path
      d="M6.66667 14.9993C7.12691 14.9993 7.5 14.6263 7.5 14.166C7.5 13.7058 7.12691 13.3327 6.66667 13.3327C6.20643 13.3327 5.83334 13.7058 5.83334 14.166C5.83334 14.6263 6.20643 14.9993 6.66667 14.9993Z"
      fill="#787878"
    />
    <path
      d="M6.875 0.833984H13.125C13.7003 0.833984 14.1667 1.20708 14.1667 1.66732C14.1667 2.12755 13.7003 2.50065 13.125 2.50065H6.875C6.29971 2.50065 5.83334 2.12755 5.83334 1.66732C5.83334 1.20708 6.29971 0.833984 6.875 0.833984Z"
      fill="#787878"
    />
  </svg>
);

export default Listmat;
