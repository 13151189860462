import { cachedStore } from '../unistore';
import { open } from '../unistore/Notification/actions';
import { standaloneLogout, showLogin } from '../unistore/Auth/actions';
import { AxiosError } from 'axios';

export const defaultErrorHandling = async (error: AxiosError<any>) => {
  const openNotificationAction = cachedStore ? cachedStore.action(open) : alert;
  const logoutAction = cachedStore
    ? cachedStore.action(standaloneLogout)
    : null;
  const showLoginAction = cachedStore ? cachedStore.action(showLogin) : null;
  if (
    error.response &&
    error.response.status >= 200 &&
    error.response.status < 300
  ) {
    // return error.json ? error.json() : null;
    // return error;
    throw error;
  } else {
    if (openNotificationAction) {
      switch (error.response?.status) {
        case 401: // Not authorized
          if (logoutAction && cachedStore) {
            await logoutAction(cachedStore);
            showLoginAction();
          }
          openNotificationAction(
            'You have been logged out. Please sign in and try again.'
          );
          break;
        default:
          openNotificationAction(error.response?.data?.message);
          break;
      }
      console.log(error.response?.data?.stack);
    }
    throw error;
  }
};
