﻿export { default as actions } from './actions';
export { default as views } from './views';

export interface IModel {
  isContacting: boolean;
  message: string;
  isSent: boolean;
  isSending: boolean;
}

export const defaultState: IModel = {
  isContacting: false,
  message: '',
  isSent: false,
  isSending: false,
};

export const state = (initialStore: IModel = defaultState) => {
  return {
    ...defaultState,
    ...initialStore,
  };
};

export default state;
