export { default as actions } from './actions';
import { IItem, ItemAttributes, IValidationError } from '@@types';
import { ItemTypes } from '@@helpers/item';

import {
  ItemColorValues,
  ItemPackagingValues,
  ItemGradeValues,
  ItemFormValues,
  ItemSourceValues,
  CommercialTermsValues,
  // packagingOptions
} from '@@helpers/item';

import { IUser } from '../Sell';

export enum Steps {
  Confirm = 'confirm',
  Done = 'done',
  Form = 'form',
}

export interface IModel {
  createdItemIds?: string[];
  creating: boolean;
  errors: IValidationError[];
  newItem?: IItem;
  statusMessage?: string;
  step: Steps;
  users?: IUser[];
  userId?: string;
}

export const defaultItem: IItem = {
  title: '',
  type: ItemTypes.Request,
  attr: {
    [ItemAttributes.Packaging]: [ItemPackagingValues.Any],
    [ItemAttributes.Color]: ItemColorValues.Any,
    [ItemAttributes.Grade]: ItemGradeValues.Any,
    [ItemAttributes.Form]: ItemFormValues.Any,
    [ItemAttributes.Source]: ItemSourceValues.Any,
    [ItemAttributes.CommercialTerms]: CommercialTermsValues.EXW,
  },
};

export const defaultState: IModel = {
  creating: false,
  errors: [],
  step: Steps.Form,
  newItem: defaultItem,
};

export const state = () => {
  return {
    ...defaultState,
  };
};

export default state;
