﻿import { h, ComponentType } from 'preact';
import { Typography } from '@@helpers/material-ui/core';
import { withStyles, Theme } from '@@helpers/material-ui/core/styles';
import { TypographyProps } from '@@helpers/material-ui/core';
export { Typography };

enum FontFamily {
  Manrope = 'Manrope',
  Lora = 'Lora',
}

export const ParagraphSea = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.sea.main,
  },
}))((props) => {
  const { classes, ...restProps } = props;
  return <span className={props.classes.root} {...restProps} />;
});

export const ParagraphFire = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.fire.main,
  },
}))((props) => {
  const { classes, ...restProps } = props;
  return <span className={props.classes.root} {...restProps} />;
});

export const ParagraphLink = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.black.main,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))((props) => {
  const { classes, ...restProps } = props;
  return <a className={props.classes.root} {...restProps} />;
});

export const ParagraphLinkButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.black.main,
    textDecoration: 'underline',
    padding: 0,
    background: 'none',
    border: 0,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))((props) => {
  const { classes, ...restProps } = props;
  return <button className={props.classes.root} {...restProps} />;
});

export const Statement = withStyles((theme: Theme) => ({
  root: {
    fontFamily: FontFamily.Lora,
    fontWeight: 400,
    fontSize: '54px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '100px',
    },
  },
}))(Typography);

export const H1 = withStyles((theme: Theme) => ({
  root: {
    fontFamily: FontFamily.Lora,
    fontWeight: 400,
    fontSize: '38px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '56px',
    },
  },
}))((props: TypographyProps) => {
  return <Typography variant="h1" {...props} />;
});

export const H1Modal = withStyles((theme: Theme) => ({
  root: {
    fontFamily: FontFamily.Lora,
    fontWeight: 400,
    fontSize: '38px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '42px',
    },
  },
}))((props: TypographyProps) => {
  return <Typography variant="h1" {...props} />;
});

export const H2 = withStyles((theme: Theme) => ({
  root: {
    fontFamily: FontFamily.Manrope,
    fontWeight: 600,
    fontSize: '28px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '36px',
    },
  },
}))((props: TypographyProps) => {
  return <Typography variant="h2" {...props} />;
});

export const H3 = withStyles((theme: Theme) => ({
  root: {
    fontFamily: FontFamily.Manrope,
    fontWeight: 600,
    fontSize: '20px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
    },
  },
}))((props: TypographyProps) => {
  return <Typography variant="h3" {...props} />;
});

const ParagraphBase = withStyles((_theme: Theme) => ({
  root: {
    fontFamily: FontFamily.Manrope,
    fontWeight: 400,
  },
}))(Typography);

type ManropeWeights = 'regular' | 'semiBold' | 'bold';

interface ITypographyProps extends TypographyProps {
  weight?: ManropeWeights;
}

const fontWeights = {
  regular: 400,
  semiBold: 600,
  bold: 700,
};

const withWeight = (WrappedComponent: ComponentType<any>) => {
  return ({ weight = 'regular', ...restProps }: ITypographyProps) => {
    const fontWeight = fontWeights[weight];
    const style = restProps.style
      ? {
          ...restProps.style,
          fontWeight: fontWeight,
        }
      : {
          fontWeight: fontWeight,
        };

    return <WrappedComponent {...restProps} style={style} />;
  };
};

export const ParagraphLarge = withWeight(
  withStyles((_theme: Theme) => ({
    root: {
      fontSize: '18px',
    },
  }))(ParagraphBase)
);

export const ParagraphMedium = withWeight(
  withStyles((_theme: Theme) => ({
    root: {
      fontSize: '16px',
    },
  }))(ParagraphBase)
);

export const ParagraphSmall = withWeight(
  withStyles((_theme: Theme) => ({
    root: {
      fontSize: '14px',
    },
  }))(ParagraphBase)
);

export const ErrorMessage = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.negative.main,
    fontFamily: FontFamily.Manrope,
    fontWeight: 400,
    fontSize: '14px',
  },
}))(Typography);

export const FooterLinks = withStyles((theme: Theme) => ({
  root: {
    fontFamily: FontFamily.Manrope,
    fontSize: '32px',
    fontWeight: 'inherit',
    color: theme.palette.pearl.main,
    [theme.breakpoints.up('sm')]: {
      fontSize: '40px',
    },
  },
}))(Typography);

// "Statement"
// Mobile: Lora, medium, 54px
// Web: Lora, medium, 100px

// "Page title"
// Mobile: Lora, medium, 40px
// Web: Lora, medium, 64px

// H1
// Mobile: Lora, medium, 38px
// Web: Lora, medium, 56px

// H2
// Mobile: Manrope, SemiBold, 28px
// Web: Manrope, SemiBold, 36px

// H3
// Mobile: Manrope, SemiBold, 20px
// Web: Manrope, SemiBold, 24px

// Large paragraph
// Manrope, regular/bold, 18

// Medium paragraph
// Manrope, regular/bold, 16

// Small paragraph
// Manrope, regular/bold, 14
