﻿import { ActivityTypes } from 'api/activity';
export { default as actions } from './actions';
// export { default as views } from './views';

export interface IUser {
  userId: string;
  name: string;
  company: string;
}

export interface IItem {
  id: string;
  title: string;
}

export interface IMessage {
  activityType: ActivityTypes;
  message: string;
  createDate: string;
  threadId: string;
  sender: IUser;
  item: IItem;
}

export interface IModel {
  isFetching: boolean;
  messages: IMessage[];
}

export const defaultState: IModel = {
  isFetching: false,
  messages: [],
};

// export const state = (initialState: IModel) => {
export const state = () => {
  return {
    ...defaultState,
  };
};

export default state;
