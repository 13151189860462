﻿import { IDBItem } from '@@types';
import { IItemAttributes } from '@@types';
import { Store, BoundAction } from 'unistore';
import { IStore } from 'shared/unistore';
import { IModel, defaultState } from './';
import { validate } from './views';
import { Steps } from './';
import { itemValidationSchema } from './views';
import { createItem, publishItem } from 'api/item';
import { fetchUsers } from 'api/user';
import { ItemTypes } from '@@helpers/item';
const messages = {
  ITEM_CREATE_FAIL: 'Something went wrong. Item has not been created',
  ITEM_CREATE_SUCCESS: 'Great work! Item has been created',
};

// import { IModel, defaultState, Steps } from './';

// ActionMap<K>
export interface IActions {
  reset: BoundAction;
  setUserId: BoundAction;
  backToForm: BoundAction;
  createItem: BoundAction;
  publishItem: BoundAction;
  updateTitle: BoundAction;
  updateAttr: BoundAction;
  updateDataSheetAttribute: BoundAction;
  fetchUsers: BoundAction;
  clone: BoundAction;
}

interface IBuyOnly {
  buy: IModel;
}

const actions = (store: Store<IStore>) => {
  const setCreating = (isCreating: boolean) => {
    store.setState({
      buy: {
        ...store.getState().buy,
        creating: !!isCreating,
      },
    });
  };

  return {
    reset: () => {
      store.setState(
        {
          ...store.getState(),
          buy: defaultState,
        },
        true
      );
    },
    setUserId: ({ buy }: IBuyOnly, userId: string) => {
      store.setState({
        buy: {
          ...buy,
          userId,
        },
      });
    },
    backToForm: ({ buy }: IBuyOnly) => {
      return {
        buy: {
          ...buy,
          step: Steps.Form,
        },
      };
    },
    updateTitle: ({ buy }: { buy: IModel }, title: string) => {
      return {
        buy: {
          ...buy,
          newItem: {
            ...buy.newItem,
            title,
          },
        },
      };
    },
    updateAttr: (
      { buy }: IBuyOnly,
      key: keyof IItemAttributes,
      value: string | number
    ) => {
      const { [key]: removeMe, ...restProps } = buy.newItem.attr;
      const newAttr = value
        ? {
            ...buy.newItem.attr,
            [key]: value,
          }
        : restProps;

      return {
        buy: {
          ...buy,
          newItem: {
            ...buy.newItem,
            attr: {
              ...newAttr,
            },
          },
        },
      };
    },

    createItem: async ({ buy }: IBuyOnly): Promise<IStore> => {
      const errors = validate(buy);
      console.log('errors', errors);
      const newBuy = {
        buy: {
          ...buy,
          errors,
          creating: false,
        },
      };

      if (errors.length > 0) {
        const currentState = store.getState();
        const newState = {
          ...currentState,
          ...newBuy,
        };
        return newState;
      }

      setCreating(true);
      const itemValues = buy.newItem;
      const itemAttrNoEmpties = Object.keys(itemValues.attr).reduce(
        (acc, curr) => {
          if (itemValues.attr[curr]) {
            acc[curr] = itemValues.attr[curr];
          }
          return acc;
        },
        {}
      );

      // Super simple guard to avoid empty objects created while development
      if (!itemValues.title && Object.keys(itemAttrNoEmpties).length === 0) {
        throw new Error('You can not create empty objects');
        return;
      }

      itemValues.attr = itemAttrNoEmpties;
      let createdItem: any; // IDbItem?
      const castItemValues = itemValidationSchema.cast(itemValues);
      const postData = {
        ...castItemValues,
        type: ItemTypes.Request,
        userId: buy.userId,
      };

      try {
        createdItem = await createItem(postData);
      } catch (error) {
        console.log('error creating item', error);
        store.setState({
          buy: {
            ...store.getState().buy,
            statusMessage: messages.ITEM_CREATE_FAIL,
            creating: false,
          },
        });
        // TODO Handle this plz. Maybe add a step?
      }

      const createdId = createdItem.data?.item?._key;
      const currentState = store.getState();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      return {
        ...currentState,
        buy: {
          ...currentState.buy,
          errors: errors,
          step: Steps.Confirm,
          creating: false,
          createdItemIds: Array.isArray(currentState.buy.createdItemIds)
            ? currentState.buy.createdItemIds.concat(createdId)
            : [createdId],
        },
      };
    },
    publishItem: async ({ buy }: IBuyOnly) => {
      const { createdItemIds } = buy;
      const latestCreatedId = createdItemIds[createdItemIds.length - 1];
      const response = await publishItem(latestCreatedId);
      console.log('response.data', response.data);
      // if (response.data === true) ....
      const currentState = store.getState();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      return {
        ...currentState,
        buy: {
          ...currentState.buy,
          statusMessage: messages.ITEM_CREATE_SUCCESS,
          step: Steps.Done,
          creating: false,
        },
      };
    },
    updateDataSheetAttribute: async (
      { buy }: IBuyOnly,
      name: string,
      prop: string,
      value: string
    ) => {
      const { newItem } = buy;
      const hasDataSheetAttributes =
        newItem.attr &&
        newItem.attr.dataSheetAttributes &&
        newItem.attr.dataSheetAttributes[name];
      const curr = hasDataSheetAttributes
        ? Object.assign(newItem.attr.dataSheetAttributes[name])
        : {};
      const newValues = {
        ...curr,
        [prop]: value,
      };

      const newStore = {
        buy: {
          ...buy,
          newItem: {
            ...buy.newItem,
            attr: {
              ...buy.newItem.attr,
              dataSheetAttributes: {
                ...buy.newItem.attr.dataSheetAttributes,
                [name]: {
                  ...newValues,
                },
              },
            },
          },
        },
      };

      if (!value) {
        delete newStore.buy.newItem.attr.dataSheetAttributes[name];
        if (
          Object.keys(newStore.buy.newItem.attr.dataSheetAttributes).length ===
          0
        ) {
          delete newStore.buy.newItem.attr.dataSheetAttributes;
        }
      }

      return newStore;
    },
    fetchUsers: async ({ buy }: IBuyOnly) => {
      store.setState({
        buy: {
          ...buy,
        },
      });

      try {
        const reply = await fetchUsers();
        const { data } = reply;
        store.setState({
          buy: {
            ...store.getState().buy,
            users: data.users,
          },
        });
      } catch (error) {
        console.log('error fetching users', error);
        // TODO Handle this plz. Maybe add a step?
      }
    },
    clone: async ({ buy }: { buy: IModel }, item: IDBItem) => {
      console.log('item', item);
      const { userId, user, createDate, _key, ...restItem } = item;
      store.setState({
        buy: {
          ...buy,
          newItem: restItem,
          userId,
        },
      });
    },
  };
};

export default actions;
