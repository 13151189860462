﻿import { ValidationError } from 'yup';

export { ValidationError };
export interface IValidatorResult {
  isValid: boolean;
  errors: ValidationError[];
}

export interface IServerError {
  errorCode: number;
  message: string;
}
