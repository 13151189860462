import { Store, BoundAction } from 'unistore';
import { IStore } from 'shared/unistore';
import { IModel, defaultState, Steps } from './';
import { ItemAttributes } from '@@types';
import {
  ItemColorValues,
  ItemGradeValues,
  ItemPlasticTypeValues,
} from '@@helpers/item';
import { validate } from './views';
// import { getItem, deleteItem } from 'api/item';

export interface IActions {
  reset: BoundAction;
  updateAttr: BoundAction;
  getGraph: BoundAction;
  updateArray: BoundAction;
}

export interface IItemAttributes {
  [ItemAttributes.Color]?: ItemColorValues;
  [ItemAttributes.Grade]?: ItemGradeValues;
  [ItemAttributes.PlasticType]?: ItemPlasticTypeValues;
}

const attrChangeHelper = (
  priceIndex: IModel,
  key: keyof IItemAttributes,
  value: string | number
) => {
  const { [key]: removeMe, ...restProps } = priceIndex.newItem;
  const newAttr = value
    ? {
        ...priceIndex.newItem,
        [key]: value,
      }
    : restProps;

  return {
    priceIndex: {
      ...priceIndex,
      newItem: {
        ...newAttr,
      },
    },
  };
};

const actions = (store: Store<IStore>) => {
  return {
    reset: () => {
      store.setState(
        {
          ...store.getState(),
          priceIndex: defaultState,
        },
        true
      );
    },
    updateAttr: (
      { priceIndex }: { priceIndex: IModel },
      key: keyof IItemAttributes,
      value: string | number
    ) => {
      return attrChangeHelper(priceIndex, key, value);
    },
    getGraph: ({ priceIndex }: { priceIndex: IModel }) => {
      const errors = validate(priceIndex);
      const newPriceIndex = {
        priceIndex: {
          ...priceIndex,
          errors,
        },
      };

      if (errors.length > 0) {
        return newPriceIndex;
      }
      newPriceIndex.priceIndex.step = Steps.Graph;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      return newPriceIndex;
    },
    updateArray: (
      { priceIndex }: { priceIndex: IModel },
      gradeValues: string[],
      colorValues: string[]
    ) => {
      const newPriceIndex = {
        priceIndex: {
          ...priceIndex,
          grades: gradeValues,
          colors: colorValues,
        },
      };
      return newPriceIndex;
    },
  };
};

export default actions;
