﻿import { ItemAttributes, IFile, FileVariants } from '@@types';
import {
  API_GET_IMAGE,
  API_GET_EXTERNAL_IMAGE,
  API_GET_INVOICE,
} from '../../constants/url';
import { ImageSize } from '../../constants/image';
export * from './colorOptions';
export * from './commercialTerms';
export * from './formOptions';
export * from './frequencyOptions';
export * from './imageRequired';
export * from './hidePrice';
export * from './packagingOptions';
export * from './packagingDimensionsOptions';
export * from './paymentOptions';
export * from './plasticTypeOptions';
export * from './purityOptions';
export * from './qualityControlled';
export * from './samplesAvailable';
export * from './shippingOptions';
export * from './sourceOptions';
export * from './gradeOptions';
export * from './timeToDeliveryOptions';
export * from './freeOnPickup';
export * from './statusOptions';

interface IOption {
  label: string;
  value: string;
}

export interface IStringDict {
  [key: string]: any;
}

export interface ICheckedOption extends IOption {
  checked: boolean;
}

export interface IReadonlyMap<TKey, TValue> {
  get(key: TKey): TValue;
}

export enum ItemTypes {
  Offer = 'offer',
  Request = 'request',
}

export const ANY_VALUE = 'any';

export const getOptionsLabelFromValue = (options: IOption[], value: string) => {
  if (!value) {
    return null;
  }

  const option = options.find((item) => item.value === value);
  return option ? option.label : null;
};

export const anyAttribExists = (attrs) => {
  return (lookForTheseAttrs: ItemAttributes[]) => {
    if (attrs) {
      const found = !!lookForTheseAttrs.find((item) => {
        return attrs[item];
      });
      return found;
    }
    return false;
  };
};

export const stripAny = (options: ICheckedOption[] | IOption[]) => {
  return options.filter((option) => option.value !== ANY_VALUE);
};

export const getFileVariant = (files: IFile[], variant: FileVariants) => {
  return files
    ? files.find((file) => {
        return file.variant === variant;
      })
    : null;
};

export const getImages = (files: IFile[]) => {
  if (Array.isArray(files)) {
    return files.filter((file) => file.variant === FileVariants.Image);
  }
  return [];
};

export const getImage = (files: IFile[]) => {
  return getFileVariant(files, FileVariants.Image);
};

export const getImageUrl = (
  file: IFile,
  imageSize: ImageSize = ImageSize.Small
) => {
  if (file.link_to_photo) {
    return API_GET_EXTERNAL_IMAGE(file.link_to_photo, imageSize);
  }
  return API_GET_IMAGE(file.fileId, file.name, imageSize);
};

export const getInvoiceUrl = (invoiceNumber: string) => {
  return API_GET_INVOICE(invoiceNumber);
};

export const getProductImageUrlFromFiles = (
  files: IFile[],
  imageSize: ImageSize = ImageSize.Small,
  isExternal: boolean
) => {
  if (files && files.length > 0) {
    const image = getFileVariant(files, FileVariants.Image);

    if (image) {
      if (isExternal) {
        return API_GET_EXTERNAL_IMAGE(image.link_to_photo, imageSize);
      } else {
        return getImageUrl(image, imageSize);
      }
    }
  }
  return null;
};
