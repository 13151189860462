﻿import { API_MATCH, API_GET_MATCH } from '@@constants/url';
import { fetcher } from '@@helpers/fetch-helper';

interface IMatchPayload {
  offerItemId: string;
  requestItemId: string;
  offerUserId: string;
  requestUserId: string;
}

export const getMatch = async (matchId: string) => {
  return fetcher.get(API_GET_MATCH(matchId), { withCredentials: true });
};

export const createMatch = async (payload: IMatchPayload) => {
  return fetcher.post(API_MATCH, payload, { withCredentials: true });
};
