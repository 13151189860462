﻿import {
  IDBItem,
  FileVariants,
  IAddress,
  materialQualitySample,
  IMaterialQualitySampleCreatePayload,
  IItem,
  IMaterialOfferServiceFee,
} from '@@types';
import { Store, BoundAction } from 'unistore';
import { IStore } from 'shared/unistore';
import { IModel, defaultState, Steps } from './';
import { IItemAttributes, ItemAttributes } from '@@types';
import { validate } from './views';
import { createItem, publishItem, updateItem } from 'api/item';
import {
  completeMaterialQualitySample,
  createMaterialQualitySample,
  updateMaterialQualitySample,
} from 'api/materialQualitySample';
import { getPrice } from 'api/price';
import { fetchUsers, fetchUserPaymentPreferences } from 'api/user';
import { itemValidationSchema } from './views';
import { ItemTypes } from '@@helpers/item';
import { roundTwoDecimals } from '@@helpers/number-helper';
import {
  parse as parseQueryString,
  stringify as stringifyQueryString,
} from 'query-string';
import { route } from 'preact-router';
// import { getItem, deleteItem } from 'api/item';

export interface IActions {
  reset: BoundAction;
  setUserId: BoundAction;
  startUploading: BoundAction;
  endUploading: BoundAction;
  updateTitle: BoundAction;
  backToForm: BoundAction;
  updateAttr: BoundAction;
  updateImage: BoundAction;
  addImage: BoundAction;
  updateDataSheet: BoundAction;
  removeFile: BoundAction;
  createItem: BoundAction;
  updateItem: BoundAction;
  publishItem: BoundAction;
  updateDataSheetAttribute: BoundAction;
  fetchUsers: BoundAction;
  clone: BoundAction;
  fetchUserPaymentPreferences: BoundAction;
  fetchPriceEstimation: BoundAction;
  updateShippingPackageDimension: BoundAction;
  setIsDetailed: BoundAction;
  toggleDetailedForm: BoundAction;
  setHasExtraVisibility: BoundAction;
  updateCouponCode: BoundAction;
  updateIsPrivate: BoundAction;
  backToConfirm: BoundAction;
  materialQualitySampleSummaryForm: BoundAction;
  completeMaterialQualitySample: BoundAction;
  addmaterialQualitySampleData: BoundAction;
  setToDone: BoundAction;
  setDraftItemToState: BoundAction;
}

const messages = {
  ITEM_CREATE_FAIL: 'Something went wrong. Item has not been created',
  ITEM_CREATE_SUCCESS: 'Great work! Item has been created',
  MATERIALQUALITY_SAMPLE_CREATE_SUCCESS: 'Great work! Item has been updated',
};

const removeFileHelper = (sell: IModel, fileId: string) => {
  const currentFiles = sell.newItem.attr[ItemAttributes.Files] || [];
  const currentFilesNew = currentFiles.filter((item) => item.fileId !== fileId);
  return currentFilesNew.length > 0 ? currentFilesNew : null;
};

export const updateFileHelper = (
  sell: IModel,
  variant: FileVariants,
  fileId: string,
  name: string,
  type: string,
  size: number
) => {
  const currentFiles = sell.newItem.attr[ItemAttributes.Files] || [];
  const currentFilesNew = currentFiles.filter(
    (item) => item.variant !== variant
  );
  const newFile = {
    variant,
    fileId,
    name,
    type,
    size,
  };

  return currentFilesNew.concat([newFile]);
};

export const addFileHelper = (
  sell: IModel,
  variant: FileVariants,
  fileId: string,
  name: string,
  type: string,
  size: number
) => {
  const currentFiles = sell.newItem.attr[ItemAttributes.Files] || [];
  const newFile = {
    variant,
    fileId,
    name,
    type,
    size,
  };

  return currentFiles.concat([newFile]);
};

const attrChangeHelper = (
  sell: IModel,
  key: keyof IItemAttributes,
  value: string | number
) => {
  const { [key]: removeMe, ...restProps } = sell.newItem.attr;
  const newAttr = value
    ? {
        ...sell.newItem.attr,
        [key]: value,
      }
    : restProps;

  const result = {
    sell: {
      ...sell,
      newItem: {
        ...sell.newItem,
        attr: newAttr,
      },
    },
  };
  return result;
};

const actions = (store: Store<IStore>) => {
  const setCreating = (isCreating: boolean) => {
    store.setState({
      sell: {
        ...store.getState().sell,
        creating: !!isCreating,
      },
    });
  };

  const setUploading = (isUploading: boolean) => {
    store.setState({
      sell: {
        ...store.getState().sell,
        uploading: !!isUploading,
      },
    });
  };

  return {
    reset: () => {
      store.setState(
        {
          ...store.getState(),
          sell: defaultState,
        },
        true
      );
    },
    setUserId: ({ sell }: { sell: IModel }, userId: string) => {
      store.setState({
        sell: {
          ...sell,
          userId,
        },
      });
    },
    startUploading: () => {
      setUploading(true);
    },
    endUploading: () => {
      setUploading(false);
    },
    updateTitle: ({ sell }: { sell: IModel }, title: string) => {
      return {
        sell: {
          ...sell,
          newItem: {
            ...sell.newItem,
            title,
          },
        },
      };
    },
    updateCouponCode: ({ sell }: { sell: IModel }, coupon: string) => {
      return {
        sell: {
          ...sell,
          newItem: {
            ...sell.newItem,
            coupon,
          },
        },
      };
    },
    backToForm: ({ sell }: { sell: IModel }) => {
      return {
        sell: {
          ...sell,
          step: Steps.Form,
        },
      };
    },
    backToConfirm: ({ sell }: { sell: IModel }) => {
      return {
        sell: {
          ...sell,
          step: Steps.Confirm,
        },
      };
    },
    setToDone: ({ sell }: { sell: IModel }) => {
      return {
        sell: {
          ...sell,
          step: Steps.Done,
        },
      };
    },
    updateAttr: (
      { sell }: { sell: IModel },
      key: keyof IItemAttributes,
      value: string | number
    ) => {
      return attrChangeHelper(sell, key, value);
    },
    updateIsPrivate: ({ sell }: { sell: IModel }, value: boolean) => {
      return {
        sell: {
          ...sell,
          newItem: {
            ...sell.newItem,
            isPrivate: value,
          },
        },
      };
    },
    updateImage: (
      { sell }: { sell: IModel },
      fileId: string,
      name: string,
      type: string,
      size: number
    ) => {
      const newFiles = updateFileHelper(
        sell,
        FileVariants.Image,
        fileId,
        name,
        type,
        size
      );

      return {
        sell: {
          ...sell,
          statusMessage: null,
          newItem: {
            ...sell.newItem,
            attr: {
              ...sell.newItem.attr,
              [ItemAttributes.Files]: newFiles,
            },
          },
        },
      };
    },
    addImage: (
      { sell }: { sell: IModel },
      fileId: string,
      name: string,
      type: string,
      size: number
    ) => {
      const newFiles = addFileHelper(
        sell,
        FileVariants.Image,
        fileId,
        name,
        type,
        size
      );
      console.log('newFiles', newFiles);
      return {
        sell: {
          ...sell,
          statusMessage: null,
          newItem: {
            ...sell.newItem,
            attr: {
              ...sell.newItem.attr,
              [ItemAttributes.Files]: newFiles,
            },
          },
        },
      };
    },
    updateDataSheet: (
      { sell }: { sell: IModel },
      fileId: string,
      name: string,
      type: string,
      size: number
    ) => {
      const newFiles = updateFileHelper(
        sell,
        FileVariants.DataSheet,
        fileId,
        name,
        type,
        size
      );

      return {
        sell: {
          ...sell,
          statusMessage: null,
          newItem: {
            ...sell.newItem,
            attr: {
              ...sell.newItem.attr,
              [ItemAttributes.Files]: newFiles,
            },
          },
        },
      };
    },

    removeFile: ({ sell }: { sell: IModel }, fileId: string) => {
      console.log('fileId', fileId);
      const newFiles = removeFileHelper(sell, fileId);
      console.log('newFiles', newFiles);
      return {
        sell: {
          ...sell,
          statusMessage: null,
          newItem: {
            ...sell.newItem,
            attr: {
              ...sell.newItem.attr,
              [ItemAttributes.Files]: newFiles,
            },
          },
        },
      };
    },
    createItem: async ({ sell }: { sell: IModel }): Promise<IStore> => {
      const errors = validate(sell);
      console.log('errors', errors);
      const newSell = {
        sell: {
          ...sell,
          errors,
          creating: false,
        },
      };

      if (errors.length > 0) {
        const currentState = store.getState();
        const newState = {
          ...currentState,
          ...newSell,
        };
        return newState;
      }

      setCreating(true);
      const itemValues = sell.newItem;
      const itemAttrNoEmpties = Object.keys(itemValues.attr).reduce(
        (acc, curr) => {
          if (itemValues.attr[curr]) {
            acc[curr] = itemValues.attr[curr];
          }
          return acc;
        },
        {}
      );

      // Super simple guard to avoid empty objects created while development
      if (!itemValues.title && Object.keys(itemAttrNoEmpties).length === 0) {
        throw new Error('You can not create empty objects');
        return;
      }

      itemValues.attr = itemAttrNoEmpties;
      let createdItem: any; // IDbItem?
      const castItemValues = itemValidationSchema.cast(itemValues);
      const postData = {
        ...castItemValues,
        type: ItemTypes.Offer,
        userId: sell.userId,
      };
      try {
        createdItem = await createItem(postData);
      } catch (error) {
        console.log('error creating item', error);
        store.setState({
          sell: {
            ...store.getState().sell,
            statusMessage: messages.ITEM_CREATE_FAIL,
            creating: false,
          },
        });
        // TODO Handle this plz. Maybe add a step?
      }

      const createdId = createdItem.data.item._key;
      const currentState = store.getState();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      return {
        ...currentState,
        sell: {
          ...currentState.sell,
          createdItem: createdItem.data.item,
          errors: errors,
          step: Steps.Confirm,
          creating: false,
          createdItemIds: Array.isArray(currentState.sell.createdItemIds)
            ? currentState.sell.createdItemIds.concat(createdId)
            : [createdId],
          serviceFee: createdItem.data.serviceFee,
        },
      };
    },
    updateItem: async ({ sell }: { sell: IModel }) => {
      const errors = validate(sell);
      console.log('errors', errors);
      const newSell = {
        sell: {
          ...sell,
          errors,
          creating: false,
        },
      };

      if (errors.length > 0) {
        const currentState = store.getState();
        const newState = {
          ...currentState,
          ...newSell,
        };
        return newState;
      }

      const { createdItemIds } = sell;
      const latestCreatedId = createdItemIds[createdItemIds.length - 1];

      const itemValues = sell.newItem;
      const itemAttrNoEmpties = Object.keys(itemValues.attr).reduce(
        (acc, curr) => {
          if (itemValues.attr[curr]) {
            acc[curr] = itemValues.attr[curr];
          }
          return acc;
        },
        {}
      );

      // Super simple guard to avoid empty objects created while development
      if (!itemValues.title && Object.keys(itemAttrNoEmpties).length === 0) {
        throw new Error('You can not create empty objects');
        return;
      }

      itemValues.attr = itemAttrNoEmpties;
      let updatedItemResponse: any; // IDbItem?
      const castItemValues = itemValidationSchema.cast(itemValues);
      const postData = {
        ...castItemValues,
        type: ItemTypes.Offer,
        userId: sell.userId,
      };

      try {
        updatedItemResponse = await updateItem(latestCreatedId, postData);
        console.log('updatedItemResponse **', updatedItemResponse);
      } catch (error) {
        console.log('error updating item', error);
        store.setState({
          sell: {
            ...store.getState().sell,
            statusMessage: messages.ITEM_CREATE_FAIL,
            creating: false,
          },
        });
        // TODO Handle this plz. Maybe add a step?
      }
      const currentState = store.getState();

      return {
        ...currentState,
        sell: {
          ...currentState.sell,
          statusMessage: messages.ITEM_CREATE_SUCCESS,
          step: Steps.Confirm,
          creating: false,
          serviceFee: updatedItemResponse?.data?.serviceFee,
          createdItem: updatedItemResponse?.data?.item,
        },
      };
    },
    publishItem: async (
      { sell }: { sell: IModel },
      billingAddress: IAddress
    ) => {
      const { createdItemIds } = sell;
      const latestCreatedId = createdItemIds[createdItemIds.length - 1];
      await publishItem(latestCreatedId, billingAddress);
      const currentState = store.getState();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      return {
        ...currentState,
        sell: {
          ...currentState.sell,
          statusMessage: messages.ITEM_CREATE_SUCCESS,
          ...defaultState,
          step: Steps.Done,
          creating: false,
        },
      };
    },
    materialQualitySampleSummaryForm: async (
      { sell }: { sell: IModel },
      materialQualitySampleInfo: materialQualitySample,
      billingAddress: IAddress
    ) => {
      const currentState = store.getState();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      setCreating(true);
      const { createdItemIds } = sell;
      let materialQualityResponse: any; // IDbItem?
      const latestCreatedId = createdItemIds[createdItemIds.length - 1];
      const postData: IMaterialQualitySampleCreatePayload = {
        itemId: latestCreatedId,
        previousUsage: materialQualitySampleInfo.previousUsage,
        reach: materialQualitySampleInfo.reach,
        packages: materialQualitySampleInfo.packages,
      };
      try {
        if (currentState.sell.materialQualitySampleInfo?._key) {
          await updateMaterialQualitySample({
            ...postData,
            _key: currentState.sell.materialQualitySampleInfo._key,
          });
        } else {
          materialQualityResponse = await createMaterialQualitySample(postData);
        }
      } catch (error) {
        store.setState({
          sell: {
            ...store.getState().sell,
            statusMessage: messages.ITEM_CREATE_FAIL,
            creating: false,
            materialQualitySubmit: false,
            trackingDetails: null,
            materialQualitySampleInfo: null,
          },
        });
      }
      return {
        ...currentState,
        sell: {
          ...currentState.sell,
          step: Steps.MaterialSummary,
          creating: false,
          materialQualitySampleInfo: {
            ...materialQualitySampleInfo,
            _key: materialQualityResponse
              ? materialQualityResponse.data.result.data
              : currentState.sell.materialQualitySampleInfo._key,
          },
          billingAddress,
          statusMessage: messages.MATERIALQUALITY_SAMPLE_CREATE_SUCCESS,
        },
      };
    },
    completeMaterialQualitySample: async (
      { sell }: { sell: IModel },
      batchNo: string,
      weight: string
    ) => {
      setCreating(true);
      const { createdItemIds } = sell;
      let materialQualityResponse: any; // IDbItem?
      const latestCreatedId = createdItemIds[createdItemIds.length - 1];
      const currentState = store.getState();
      const postData: IMaterialQualitySampleCreatePayload = {
        itemId: latestCreatedId,
        weight,
        batchNo,
        previousUsage:
          currentState.sell.materialQualitySampleInfo.previousUsage,
        reach: currentState.sell.materialQualitySampleInfo.reach,
        packages: currentState.sell.materialQualitySampleInfo.packages,
        billingAddress: currentState.sell.billingAddress,
        _key: currentState.sell.materialQualitySampleInfo._key,
      };

      try {
        materialQualityResponse = await completeMaterialQualitySample(postData);
        await publishItem(latestCreatedId, currentState.sell.billingAddress);
      } catch (error) {
        store.setState({
          sell: {
            ...store.getState().sell,
            statusMessage: messages.ITEM_CREATE_FAIL,
            creating: false,
            materialQualitySubmit: false,
            trackingDetails: null,
          },
        });
      }
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      return {
        ...currentState,
        sell: {
          ...currentState.sell,
          statusMessage: messages.MATERIALQUALITY_SAMPLE_CREATE_SUCCESS,
          creating: false,
          materialQualitySubmit: true,
          trackingDetails: materialQualityResponse.data.result,
        },
      };
    },
    updateDataSheetAttribute: async (
      { sell }: { sell: IModel },
      name: string,
      prop: string,
      value: string
    ) => {
      const { newItem } = sell;
      const hasDataSheetAttributes =
        newItem.attr &&
        newItem.attr.dataSheetAttributes &&
        newItem.attr.dataSheetAttributes[name];
      const curr = hasDataSheetAttributes
        ? Object.assign(newItem.attr.dataSheetAttributes[name])
        : {};
      const newValues = {
        ...curr,
        [prop]: value,
      };

      const newStore = {
        sell: {
          ...sell,
          newItem: {
            ...sell.newItem,
            attr: {
              ...sell.newItem.attr,
              dataSheetAttributes: {
                ...sell.newItem.attr.dataSheetAttributes,
                [name]: {
                  ...newValues,
                },
              },
            },
          },
        },
      };

      if (!value) {
        delete newStore.sell.newItem.attr.dataSheetAttributes[name];
        if (
          Object.keys(newStore.sell.newItem.attr.dataSheetAttributes).length ===
          0
        ) {
          delete newStore.sell.newItem.attr.dataSheetAttributes;
        }
      }
      return newStore;
    },
    updateShippingPackageDimension: async (
      { sell }: { sell: IModel },
      name: string,
      value: string
    ) => {
      const { newItem } = sell;
      const hasShippingPackageDimensions =
        newItem.attr.shippingPackageDimensions;
      const curr = hasShippingPackageDimensions
        ? Object.assign(newItem.attr.shippingPackageDimensions)
        : {};
      const newValues = {
        ...curr,
        [name]: value,
      };

      const newStore = {
        sell: {
          ...sell,
          newItem: {
            ...sell.newItem,
            attr: {
              ...sell.newItem.attr,
              shippingPackageDimensions: newValues,
            },
          },
        },
      };

      if (!value) {
        delete newStore.sell.newItem.attr.shippingPackageDimensions[name];
        if (
          Object.keys(newStore.sell.newItem.attr.shippingPackageDimensions)
            .length === 0
        ) {
          delete newStore.sell.newItem.attr.shippingPackageDimensions;
        }
      }

      console.log(newValues, newStore);
      return newStore;
    },
    fetchUsers: async () => {
      store.setState({
        sell: {
          ...store.getState().sell,
        },
      });

      try {
        const reply = await fetchUsers();
        const { data } = reply;
        store.setState({
          sell: {
            ...store.getState().sell,
            users: data.users,
          },
        });
      } catch (error) {
        console.log('error fetching users', error);
        // TODO Handle this plz. Maybe add a step?
      }
    },
    fetchUserPaymentPreferences: async ({ sell }: { sell: IModel }) => {
      store.setState({
        sell: {
          ...sell,
          fetching: true,
        },
      });

      try {
        const result = await fetchUserPaymentPreferences();
        const { data } = result;
        const { paymentPreferences: defaultPaymentPreferences } = data;
        const updatedModel = attrChangeHelper(
          sell,
          ItemAttributes.PaymentPreferences,
          defaultPaymentPreferences
        );
        store.setState({
          sell: {
            ...updatedModel.sell,
            fetching: false,
            defaultPaymentPreferences,
          },
        });
      } catch (error) {
        console.log('Error getting default payment preferences', error);
        store.setState({
          sell: {
            ...store.getState().sell,
            fetching: false,
          },
        });
      }
    },
    clone: async ({ sell }: { sell: IModel }, item: IDBItem) => {
      const { userId, user, createDate, _key, ...restItem } = item;
      store.setState({
        sell: {
          ...sell,
          newItem: restItem,
          userId,
        },
      });
    },
    setHasExtraVisibility: (
      { sell }: { sell: IModel },
      hasExtraVisibility: boolean
    ) => {
      return {
        sell: {
          ...sell,
          newItem: {
            ...sell.newItem,
            hasExtraVisibility,
          },
        },
      };
    },
    setIsDetailed: ({ sell }: { sell: IModel }, isDetailed: boolean) => {
      return {
        sell: {
          ...sell,
          isDetailed,
        },
      };
    },
    fetchPriceEstimation: async ({ sell }: { sell: IModel }) => {
      store.setState({
        sell: {
          ...sell,
          fetchingPriceEstimate: true,
        },
      });

      try {
        const response = await getPrice({
          plasticType: sell.newItem.attr.plasticType,
          grade: sell.newItem.attr.grade,
          color: sell.newItem.attr.color,
          recycled: true,
          user_price: sell.newItem.attr.kgPrice,
        });
        if (response?.data) {
          const priceEstimate = response.data?.priceEstimate
            ? {
                ...response.data.priceEstimate,
                priceDiff: roundTwoDecimals(
                  response.data.priceEstimate.priceDiff
                ),
              }
            : {};

          return {
            sell: {
              ...store.getState().sell,
              priceEstimate,
              fetchingPriceEstimate: false,
            },
          };
        }
        return {
          sell: {
            ...store.getState().sell,
            priceEstimate: null,
            fetchingPriceEstimate: false,
          },
        };
      } catch (error) {
        store.setState({
          sell: {
            ...store.getState().sell,
            fetchingPriceEstimate: false,
          },
        });

        console.log('Price estimation error', error);
        // throw new Error('Price estimation error' + error);
      }
    },
    toggleDetailedForm: ({ sell }: { sell: IModel }, isDetailed?: boolean) => {
      const parsed = parseQueryString(location.search);
      if (isDetailed) {
        parsed.detailed = 'true';
      } else {
        delete parsed.detailed;
      }
      const newQs = stringifyQueryString(parsed);
      const newUrl = window.location.pathname + (newQs ? '?' + newQs : '');
      route(newUrl, true);
    },

    setDraftItemToState: (
      { sell }: { sell: IModel },
      item: IDBItem,
      serviceFee: IMaterialOfferServiceFee,
      billingAddress: IAddress
    ) => {
      const materialQualitySampleData =
        item.materialQualitySampleData.length > 0
          ? item.materialQualitySampleData[0]
          : null;
      return {
        sell: {
          ...sell,
          newItem: {
            ...sell.newItem,
            ...item,
          },
          serviceFee,
          createdItemIds: [item._key],
          userId: item.userId,
          creating: false,

          createdItem: { ...item },
          // step: false ? Steps.MaterialSummary : Steps.Confirm,
          step: Steps.MaterialSummary,
          materialQualitySampleInfo: {
            ...materialQualitySampleData,
          },
          billingAddress,
          statusMessage: materialQualitySampleData
            ? messages.MATERIALQUALITY_SAMPLE_CREATE_SUCCESS
            : messages.ITEM_CREATE_SUCCESS,
        },
      };
    },
  };
};

export default actions;
