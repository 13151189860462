import { IFile, IItemAttributes } from '@@types';
import { CommercialTermsValues, ItemFrequencyValues } from '@@helpers/item';
export enum MessageType {
  Text = 'TEXT',
  File = 'FILE',
  SampleRequest = 'SAMPLE_REQUEST',
  CustomOffer = 'CUSTOM_OFFER',
  OrderRequest = 'ORDER_REQUEST',
  ShippingRequestResponse = 'SHIPPING_REQUEST_RESPONSE',
  ActiveSales = 'Active_Sales',
}

export enum MessageResponseType {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Withdrawn = 'WITHDRAWN',
}

export interface IMessageResponse {
  responseDate: string;
  responseType: MessageResponseType;
  userId: string;
}

export interface IMessageContentShippingRequestResponse {
  shippingRequestResponseId: string;
}

export interface IMessageContentText {
  message: string;
}

export interface IMessageContentOrderRequest {
  kgPrice: number;
  quantity: number;
  commercialTerms: CommercialTermsValues;
  frequency: ItemFrequencyValues;
  message: string;
  shippingPriceRequest: boolean;
  shippingAddressId: string;
  deliveryDate: string;
}

export interface IMessageContentSampleRequest {
  itemId: string;
  shippingAddressId: string;
  requestedWeight: number;
  response?: IMessageResponse;
}

export interface IMessageContentCustomOffer {
  expireDate: string;
  itemId: string;
  attr?: IItemAttributes;
  response?: IMessageResponse;
}

export interface IGenMessage<T extends MessageContent> {
  _key?: string;
  itemId?: string;
  messageType: MessageType | AdditionalMessageTypes;
  createDate: string;
  notifyDate?: string;
  readDate?: string;
  updateDate: string;
  recipientCompanyId: string;
  senderCompanyId: string;
  senderUserId: string;
  content: T;
}

export type MessageContent =
  | IMessageContentText
  | IFile
  | IMessageContentSampleRequest
  | IMessageContentCustomOffer
  | IMessageContentOrderRequest
  | IMessageContentShippingRequestResponse;

export type IMessage = IGenMessage<MessageContent>;

export interface IMessageList {
  companyId: string;
  lastUpdate: string;
  unreadCount: number;
}

export interface IMessageNotificationList {
  companyId: string;
  unreadCount: number;
  userEmails: string[];
  messageIds: string[];
  senderCompanies: string[];
}

// export interface IMessageOrderRequest{
//   message: string;
//   shippingAddressId: string;
//   shippingPriceRequest:boolean;
// }

export enum AdditionalMessageTypes {
  SampleRequestResponse = 'SAMPLE_REQUEST_RESPONSE',
  ShippingRequest = 'SHIPPING_REQUEST',
  CustomOfferResponse = 'CUSTOM_OFFER_RESPONSE',
  CustomOfferExpired = 'CUSTOM_OFFER_EXPIRED',
  CustomOfferItemDeleted = 'CUSTOM_OFFER_ITEM_DELETED',
}
