﻿export const roundTwoDecimals = (decimalNumber: number) => {
  return Math.round((decimalNumber + Number.EPSILON) * 100) / 100;
};

export const prettyFormatNumber = (price) => {
  return String(price || 0)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
};

export const isNumber = (value: any) => {
  return !isNaN(value);
};

export const isNumberOrFalsy = (value: any) => {
  return !value || isNumber(value);
};
