﻿import { getOptionsLabelFromValue, IReadonlyMap } from './index';
import { mapValueWithLabel } from './mappers';

export enum PackagingDimensionsValues {
  Width = 'w',
  Height = 'h',
  Length = 'l',
}

const packagingDimensionsLabels: IReadonlyMap<
  PackagingDimensionsValues,
  string
> = new Map([
  [PackagingDimensionsValues.Width, 'Width'],
  [PackagingDimensionsValues.Height, 'Height'],
  [PackagingDimensionsValues.Length, 'Length'],
]);

export const packagingDimensionsOptions = mapValueWithLabel(
  PackagingDimensionsValues,
  packagingDimensionsLabels
);

export const getPackagingDimensionsLabel = (
  value: PackagingDimensionsValues
) => {
  return getOptionsLabelFromValue(packagingDimensionsOptions, value);
};

export const getCompletePackagingDimensionsLabel = (packageDimensions) => {
  if (!packageDimensions) {
    return null;
  }
  const dimensionsAsString = Object.keys(packageDimensions)
    .map((pd: PackagingDimensionsValues) => {
      return `${getPackagingDimensionsLabel(pd)}: ${packageDimensions[pd]}cm`;
    })
    .join(', ');

  return dimensionsAsString;
};
