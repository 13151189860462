﻿export { default as actions } from './actions';
import { IDBItem, IDBUser, IValidationError } from '@@types';
import { IMatch } from './actions';
// export enum Steps {
//   Confirm = 'confirm',
//   Done = 'done',
//   Form = 'form',
// }

export interface IModel {
  fetchingItems: boolean;
  fetchingMatches: boolean;
  saving: boolean;
  items: IDBItem[];
  users: IDBUser[];
  offerUserId: string;
  offerItemId: string;
  requestUserId: string;
  requestItemId: string;
  statusMessage?: string;
  // step: Steps;
  errors: IValidationError[];
  displayMatch: IMatch;
}

const dummyMatch = {
  createDate: '2019-12-09T10:56:47.096Z',
  offerItemId: '11796430',
  offerUserId: '11669812',
  remoteAddress: '127.0.0.1',
  requestItemId: '12728314',
  requestUserId: '11669812',
  userId: '11669812',
  _id: 'matches/14238706',
  _key: '14238706',
  _rev: '_ZsnL6fS--_',
};

export const defaultState: IModel = {
  fetchingItems: false,
  fetchingMatches: false,
  saving: false,
  items: [],
  users: [],
  offerUserId: null,
  offerItemId: null,
  requestUserId: null,
  requestItemId: null,
  statusMessage: null,
  errors: [],
  // createdMatch: null,
  displayMatch: dummyMatch,
};

// {"_id":"matches/14234478","_key":"14234478","_rev":"_Zsmvam6--_","new":{"_key":"14234478","_id":"matches/14234478","_rev":"_Zsmvam6--_","offerItemId":"12722477","offerUserId":"11669812","requestItemId":"12728314","requestUserId":"11669812"}}

export const state = () => {
  return {
    ...defaultState,
  };
};

export default state;
