﻿import { TestMethods, IPriceAndDiscount } from '@@helpers/test-methods-helper';
import { IAddress } from './';
import { IDbMeta } from './db';
import { IDateRange } from './';

export enum MaterialTestTypes {
  AtomlerTest = 'ATOMLER_TEST',
  RequestSample = 'REQUEST_SAMPLE',
}

export enum MaterialTestStatus {
  Draft = 'DRAFT',
  Confirmed = 'CONFIRMED',
  Paid = 'PAID',
}

export interface IAtomlerTestData {
  testMethods: TestMethods[];
  priceAndDiscount: IPriceAndDiscount;
  billingAddressId: string;
  dateOfTestResult: IDateRange;
}

export interface IRequestSampleData {
  sampleSize: string;
  shippingAddressId?: string;
  dateOfDelivery?: IDateRange;
}

export interface IMaterialTestStatus extends IDbMeta {
  materialTestId: string;
  status: MaterialTestStatus;
}

export interface IMaterialTestCreatePayload {
  itemId: string;
  type: MaterialTestTypes;
  sampleSize?: string;
}

export interface IMaterialTestModelPayload extends IMaterialTestCreatePayload {
  userId: string;
}

export interface IMaterialTestDataGeneric<TestType, DataInterface> {
  itemId: string;
  userId: string;
  type: TestType;
  data?: DataInterface;
}

export interface IMaterialTestGeneric<TestType, DataInterface>
  extends IDbMeta,
    IMaterialTestDataGeneric<TestType, DataInterface> {}

export interface IDBMaterialTestGeneric<TestType, DataInterface>
  extends IMaterialTestGeneric<TestType, DataInterface> {
  _key: string;
  currentStatus: IMaterialTestStatus;
}

// Helpers
export type IMaterialTest =
  | IMaterialTestGeneric<MaterialTestTypes.AtomlerTest, IAtomlerTestData>
  | IMaterialTestGeneric<MaterialTestTypes.RequestSample, IRequestSampleData>;
export type IDBMaterialTest =
  | IDBMaterialTestGeneric<MaterialTestTypes.AtomlerTest, IAtomlerTestData>
  | IDBMaterialTestGeneric<MaterialTestTypes.RequestSample, IRequestSampleData>;

// Updates from client
export interface IAtomlerTestUpdatePayload {
  materialTestId: string;
  billingAddress: IAddress;
  dateOfTestResult: IDateRange;
  testMethods: TestMethods[];
  priceAndDiscount: IPriceAndDiscount;
}

export interface IRequestSampleUpdatePayload {
  materialTestId: string;
  shippingAddress: IAddress;
  dateOfDelivery: IDateRange;
}

export interface IRequestSampleModelConfirmPayload {
  shippingAddressId: string;
  dateOfDelivery: IDateRange;
}

export interface IAtomlerTestModelConfirmPayload {
  billingAddressId: string;
  dateOfTestResult: IDateRange;
  priceAndDiscount: IPriceAndDiscount;
  testMethods: TestMethods[];
}

// export interface IMaterialTestUpdatePayload {
//   materialTestId: string;
//   dateOfDelivery: IDateRange;
//   address: IAddress;
// }

// export interface IAtomlerTestUpdatePayload {
//   materialTestId: string;
//   dateOfTestResult: IDateRange;
//   address: IAddress;
// }

// export interface IMaterialTest2UpdatePayload extends IMaterialTestUpdatePayload {
//   testProperties: TestMethods[];
// }

// export interface IMaterialTestConfirmPayload {
//   dateOfDelivery: IDateRange;
//   shippingAddressId: string;
// }
