import { IAddress, IDateRange, IDbMeta } from '@@types';
import { ItemFrequencyValues, CommercialTermsValues } from '@@helpers/item';
import { OrderDeclineReasonValues } from '@@helpers/order-helper';
export enum OrderStatus {
  Draft = 'DRAFT',
  Confirmed = 'CONFIRMED',
  Cancelled = 'CANCELLED',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
}

export enum OrderTypes {
  FixedQuotation = 'FIXED_QUOTATION',
  Quotation = 'QUOTATION',
}

export interface ICreateFixedQuotationPayload {
  type: OrderTypes.FixedQuotation;
  itemId: string;
  quantity: string;
  frequency: ItemFrequencyValues;
  matchItemId?: string;
}

export interface ICreateQuotationPayload {
  type: OrderTypes.Quotation;
  itemId: string;
  quantity: string;
  frequency: ItemFrequencyValues;
  matchItemId?: string;
  kgPrice: string;
  commercialTerms: CommercialTermsValues;
  paymentPreferences: string;
  agreePaymentPreferences: boolean;
}

export type IOrderCreatePayload =
  | ICreateQuotationPayload
  | ICreateFixedQuotationPayload;

export interface IOrderUpdatePayload {
  orderId: string;
  shippingAddress: IAddress;
  billingAddress?: IAddress;
  dateOfDelivery: IDateRange;
}

export interface IOrderConfirmPayload {
  shippingAddressId: string;
  billingAddressId?: string;
  dateOfDelivery: IDateRange;
}

// userId:
//   shippingAddressId?: string;
//   billingAddressId?: string;
//   dateOfDelivery?: IDateRange;

// interface ICreateOrderPayloadGeneric<T, D> {
//   type: T;
//   userId: string;
//   itemId: string;
//   data: D;
//   shippingAddressId?: string;
//   billingAddressId?: string;
//   dateOfDelivery?: IDateRange;
// }

export interface IDBOrderStatus extends IDbMeta {
  orderId: string;
  status: OrderStatus;
}

// Decline
export interface IOrderDeclinePayload {
  reason: OrderDeclineReasonValues;
  otherReason: string;
}

// cancel order should have a reason too, why it was cancelled.
export interface IOrderCancelPayload {
  reason: string;
  otherReason: string;
}
export interface IOrderAcceptPayload {
  billingAddressId: string;
}

export interface IExternalNegotiationFormData {
  kgPrice: string;
  quantity: string;
  commercialTerms: CommercialTermsValues;
  frequency: ItemFrequencyValues;
  message: string;
  shippingPriceRequest?: boolean;
}
