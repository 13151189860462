import { getOptionsLabelFromValue, IReadonlyMap } from '../item/index';
import { mapValueWithLabel } from '../item/mappers';

export enum FormOptions {
  scrap = 'scrap',
  filmRolls = 'film_rolls',
  baledHardPlastic = 'baled_hard_plastic',
  baledFilm = 'baled_film',
  regrind = 'regrind',
  regranulate = 'regranulate',
  noClue = 'no_clue',
  other = 'other',
}

const FormOptionsLabels: IReadonlyMap<FormOptions, string> = new Map([
  [FormOptions.scrap, 'Scrap'],
  [FormOptions.filmRolls, 'Film rolls'],
  [FormOptions.baledHardPlastic, 'Baled (hard plastic)'],
  [FormOptions.baledFilm, 'Baled (film)'],
  [FormOptions.regrind, 'Regrind'],
  [FormOptions.regranulate, 'Regranulate'],
  [FormOptions.noClue, 'I have no clue'],
  [FormOptions.other, 'Other'],
]);

export const formOptions = mapValueWithLabel(FormOptions, FormOptionsLabels);

export const customFormOptions = (formArray) => {
  const customFormOption = mapValueWithLabel(formArray, FormOptionsLabels);
  var filtered = customFormOption.filter(function (x) {
    return x.label !== undefined;
  });
  return filtered;
};

export const getFormOptions = (newItem) => {
  return formOptions.map((item) => {
    return {
      ...item,
      checked: newItem.preferredForm === item.value,
    };
  });
};

export const getFormOptionsLabels = (value: FormOptions | FormOptions[]) => {
  if (Array.isArray(value)) {
    return value
      .map((v) => {
        return getOptionsLabelFromValue(formOptions, v);
      })
      .join(', ');
  }
  return getOptionsLabelFromValue(formOptions, value);
};
