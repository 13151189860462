import {
  ItemFrequencyValues,
  CommercialTermsValues,
  ItemPackagingValues,
} from '@@helpers/item';
import { IPackagingDimensions } from './item';

export interface ICustomOfferFormData {
  kgPrice: string;
  supply: string;
  commercialTerms: CommercialTermsValues;
  frequency: ItemFrequencyValues;
  packaging: ItemPackagingValues | ItemPackagingValues[];
  shippingPackageWeight: string;
  shippingPackageDimensions: IPackagingDimensions;
}

export interface ICustomOffer {
  formData: ICustomOfferFormData;
  itemId: string;
  recipientCompanyId: string;
}

export enum CustomOfferStatus {
  // Accepted = 'ACCEPTED', // For later
  Pending = 'PENDING',
  Declined = 'DECLINED',
  Withdrawn = 'WITHDRAWN',
  Expired = 'EXPIRED',
}

export interface ICustomOfferStatus {
  status: CustomOfferStatus;
  hoursLeft: number;
}
