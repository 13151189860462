﻿import { h, FunctionComponent } from 'preact';
import { Box } from '@@helpers/material-ui/core';
// `flex flex-column flex-grow-1`;
export const Container: FunctionComponent = ({ children }) => {
  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      {children}
    </Box>
  );
};

export default Container;
