﻿import { ItemAttributes, IItem } from '@@types';
import { IReadonlyMap } from './index';
import { mapValueWithLabel } from './mappers';
// import { getOptionsLabelFromValue, IReadonlyMap, IStringDict } from './index';
export enum HidePriceValues {
  Yes = 'yes',
}

const hidePriceLabels: IReadonlyMap<HidePriceValues, string> = new Map([
  [HidePriceValues.Yes, 'Hide price'],
]);

export const hidePriceOptions = mapValueWithLabel(
  HidePriceValues,
  hidePriceLabels
);

export const hidePriceValueArray = hidePriceOptions.map((item) => item.value);

export const getHidePriceOptions = (newItem: IItem) => {
  return hidePriceOptions.map((item) => {
    return {
      ...item,
      checked: newItem.attr[ItemAttributes.HidePrice] === item.value,
    };
  });
};

// export const getImageRequiredOptionsLabel = (value: ImageRequiredValues) =>
//   getOptionsLabelFromValue(imageRequiredOptions, value);
