import {
  GET_SERVICE_PROVIDERs_LIST,
  SERVICE_PROVIDER_LOGIN,
} from '@@constants/url';
import { fetcher } from '@@helpers/fetch-helper';
import {
  IServiceProviderPayload,
  ServiceProvidersType,
} from '@@types/serviceProvider';

export const login = (loginData: IServiceProviderPayload) => {
  return fetcher.post(SERVICE_PROVIDER_LOGIN, loginData, {
    withCredentials: true,
  });
};

export const getSericeProvidersList = (type: ServiceProvidersType) => {
  return fetcher.get(GET_SERVICE_PROVIDERs_LIST(type));
};
