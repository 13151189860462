﻿import { IDbMeta } from './db';

export enum AddressTypes {
  Shipping = 'SHIPPING',
  Billing = 'BILLING',
}

export interface IAddress {
  recipient: string;
  street: string;
  address2?: string;
  postalCode: string;
  city: string;
  state?: string;
  country: string;
  email?: string;
}

export interface IShippingAddress extends IAddress {
  contactPerson: string;
  phoneNumber: string;
  email: string;
}

export interface IConfirmAddress extends IShippingAddress {
  deliveryDate: string;
}

export interface ICompanyAddress extends IAddress {
  companyId: string;
  type: AddressTypes;
  contactPerson?: string;
  phoneNumber?: string;
}

export interface IDbAddressPayload extends ICompanyAddress, IDbMeta {
  remoteAddress: string;
}

export interface IDbAddress extends IDbAddressPayload {
  _key: string;
}

export interface IReference {
  name: string;
  email: string;
  vatno: string;
}

export interface IBillingAddress extends IAddress {
  email: string;
}
