﻿import { IFile } from '@@types';

export { default as actions } from './actions';

export interface IModel {
  fetching: boolean;
  itemId: string;
  title: string;
  updating: boolean;
  newSupply?: string;
  newSupplyIsValid?: boolean;
  message?: string | JSX.Element | JSX.Element[];
}

export const defaultState: IModel = {
  fetching: false,
  itemId: null,
  title: null,
  updating: false,
  newSupply: '',
  newSupplyIsValid: true,
  message: null,
};

enum YesNoUnknown {
  Yes = 'yes',
  No = 'no',
  Unknown = 'unknown',
}

enum YesNoOther {
  Yes = 'yes',
  No = 'no',
  Other = 'other',
}

enum YesNo {
  Yes = 'yes',
  No = 'no',
}

enum SingleMixedOther {
  Single = 'single',
  Mixed = 'mixed',
  Other = 'other',
}

export interface IPlasticAttrs {
  color?: string;
  colorCustom?: string;
  description?: string;
  kgPrice?: number;
  materialIsPure?: YesNoUnknown;
  packaging?: string;
  paymentOptions?: any[];
  plasticType?: string;
  previousUsage?: string;
  purity?: SingleMixedOther;
  qualityControlled?: YesNoOther;
  samplesAvailable?: YesNo;
  shippingOptions?: any[];
  supply?: number;
  title: string;
  files?: IFile[];
}

export interface IItemModel extends IPlasticAttrs {
  title: string;
}

// export const state = (initialState: IModel) => {
// export const state = (initialState: IModel) => {
export const state = () => {
  return {
    ...defaultState,
    // ...initialState,
  };
};

export default state;
// export { default as views } from './views';

// const Item = types.model('Item', {
//     // productImages: types.array(Image),
//     color: types.maybeNull(types.enumeration('Color', colorOptionsValueArray)),
//     // color: types.maybeNull(types.enumeration('Color', colorOptions.map(opt => opt.value))),
//     colorCustom: types.maybeNull(types.string),
//     description: types.maybeNull(types.string),
//     kgPrice: types.maybeNull(types.number),
//     materialIsPure: maybeYesNoUnknown,
//     packaging: types.maybeNull(types.string),
//     paymentOptions: types.maybeNull(types.array(paymentOption)),
//     plasticType: types.maybeNull(types.string),
//     previousUsage: types.maybeNull(types.string),
//     purity: types.maybeNull(types.enumeration('Purity', ['single', 'mixed', 'other'])),
//     // purity: types.maybeNull(types.enumeration('Purity', purityOptions.map(opt => opt.value))),
//     qualityControlled: maybeYesNoOther,
//     samplesAvailable: maybeYesNo,
//     shippingOptions: types.maybeNull(types.array(shippingOption)),
//     supply: types.maybeNull(types.number),
//     title: types.maybeNull(types.string),
//     files: types.maybeNull(types.array(file)),
//     // commercialTerms: types.maybeNull(types.enumeration('CommercialTerms', plasticTypeOptions.map(i => i.value))),
//     commercialTerms: types.maybeNull(types.string),
