﻿import 'core-js/features/set';
import 'core-js/features/map';
import 'core-js/features/number/epsilon';
import 'core-js/features/promise';
import 'core-js/features/weak-map';
import 'core-js/features/array'; // or should we only patch /es6/array/from?
import 'core-js/features/object'; // Object.entries and IE
import 'core-js/web/immediate'; // mobx state tree's flow use this
import 'core-js/web/url';
import 'core-js/web/url-search-params';
import 'core-js/features/date';
import 'formdata-polyfill'; // requires weak-map polyfill...
import ResizeObserver from 'resize-observer-polyfill';

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

// from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
(function (arr) {
  arr.forEach(function (item) {
    // eslint-disable-next-line no-prototype-builtins
    if (item.hasOwnProperty('remove')) {
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode === null) {
          return;
        }
        this.parentNode.removeChild(this);
      },
    });
  });
  // eslint-disable-next-line no-undef
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
