import { IPriceRange, ItemAttributes } from '@@types';
import {
  ItemPlasticTypeValues,
  ItemGradeValues,
  ItemColorValues,
} from '@@helpers/item';
import { stringify } from 'query-string';
import { fetcher } from '@@helpers/fetch-helper';
import { API_GET_PRICE } from '@@constants/url';
import { AxiosResponse, CancelTokenSource } from 'axios';

interface IProps {
  plasticType: ItemPlasticTypeValues;
  grade: ItemGradeValues;
  color: ItemColorValues;
  recycled: boolean;
  user_price?: number;
}

// These are the props that will trigger an api-call in the sell form
export const priceEstimationProps = [
  ItemAttributes.Color,
  ItemAttributes.Grade,
  ItemAttributes.PlasticType,
  ItemAttributes.KgPrice,
];

interface IReturnData {
  auth: any;
  priceEstimate: IPriceRange;
  aggregatedData: any;
}

let cancelRequest: CancelTokenSource;

export const getPrice = async ({
  plasticType,
  grade,
  color,
  recycled,
  user_price,
}: IProps): Promise<AxiosResponse<IReturnData>> => {
  const payload = {
    plasticType: plasticType,
    grade: grade,
    color: color,
    recycled: recycled,
    user_price,
  };
  const queryString = stringify(payload);

  if (typeof cancelRequest != typeof undefined) {
    cancelRequest.cancel('New request.');
  }

  cancelRequest = fetcher.CancelToken.source();

  return fetcher
    .get(API_GET_PRICE + '?' + queryString, {
      withCredentials: true,
      cancelToken: cancelRequest.token,
    })
    .catch((thrown) => {
      if (fetcher.isCancel(thrown)) {
        console.log('Canceled: ', thrown.message);
        return null;
      } else {
        throw thrown;
      }
    });
};
